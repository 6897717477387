<template>
  <div v-if="projectConfigurationManager" class="flex flex-column h100p">
    <el-row type="flex" justify="space-between">
      <el-col>
        <h3 class="n-profile-title" style="margin-left: 0px">Projektberichte</h3>
      </el-col>
    </el-row>
    <el-row type="flex" align="bottom">
      <el-col :span="10">
        <div style="position: relative">
          <profile-date-picker
            v-if="computedEditMode && project.dateRange"
            :min-date="project.dateRange[0]"
            :max-date="project.dateRange[1]"
            v-model="dateSelection"
            style="max-width: 350px"
            is-range
            :attributes="datePickerAttributes"
            :title="$t('src.components.project.pickpack.pickpacklist.zeitraum')"
            :placeholder="$t('src.components.project.pickpack.pickpacklist.zeitraum')"
          />
          <div v-else>{{ dateSelection | formatDateRange }}</div>
          <div v-if="!dateSelection" class="n-error-message">
            {{ $t("src.components.project.pickpack.pickpacklist.bitteWhlenSie") }}
          </div>
        </div>
      </el-col>
      <el-col class="text-right" :span="14">
        <div style="margin-bottom: 24px">
          <cancel-button class="mr-2" @click="archvedVisible = true"
            ><archive-icon decorative />{{
              $t("src.components.project.pickpack.pickpacklist.archivAnsehen")
            }}</cancel-button
          >
          <el-dropdown trigger="click" @command="handleCommand" v-if="computedEditMode">
            <cancel-button class="mr-2" :loading="loadingPickPack" :disabled="!dateSelection">
              <file-icon />{{ $t("src.components.project.pickpack.pickpacklist.pickAmpPackBericht")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </cancel-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="pickpack_save"
                ><check-bold-icon />{{ $t("src.components.project.pickpack.pickpacklist.speichern") }}</el-dropdown-item
              >
              <el-dropdown-item command="pickpack_print"
                ><printer-check-icon />{{
                  $t("src.components.project.pickpack.pickpacklist.speichernUndDrcken")
                }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
          <el-dropdown trigger="click" @command="handleCommand" v-if="computedEditMode">
            <cancel-button class="mr-3" :loading="loadingProjectList" :disabled="!dateSelection">
              <file-star-icon />{{ $t("src.components.project.pickpack.pickpacklist.projectListBericht")
              }}<i class="el-icon-arrow-down el-icon--right"></i>
            </cancel-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="projectlist_save"
                ><check-bold-icon />{{ $t("src.components.project.pickpack.pickpacklist.speichern") }}</el-dropdown-item
              >
              <el-dropdown-item command="projectlist_print"
                ><printer-check-icon />{{
                  $t("src.components.project.pickpack.pickpacklist.speichernUndDrcken")
                }}</el-dropdown-item
              >
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-col>
    </el-row>
    <hr style="margin-right: 10px; margin-bottom: 0px" />
    <perfect-scrollbar class="pickpack-scrollbar" :options="{ suppressScrollX: false }">
      <el-row type="flex">
        <el-col style="border-right: 1px solid #ebebeb; padding-top: 10px" :span="14">
          <pickpack-resource-table
            :data="employeeModels"
            :title="$t('src.components.project.pickpack.pickpacklist.mitarbeiter')"
          />
          <pickpack-resource-table
            :data="machineModels"
            :title="$t('src.components.project.pickpack.pickpacklist.maschinen')"
          />
          <pickpack-resource-table
            :data="rhbModels"
            :title="$t('src.components.project.pickpack.pickpacklist.rohHilfsUndBetriebsstoffe')"
          />
          <pickpack-resource-table
            :data="supplyModels"
            :title="$t('src.components.project.pickpack.pickpacklist.verbrauchsmaterialien')"
            resourceType="supply"
          />
          <pickpack-resource-table
            :data="vehicleModels"
            :title="$t('src.components.project.pickpack.pickpacklist.kfz')"
          />
        </el-col>
        <el-col :span="10">
          <div v-if="project.id">
            <pick-pack-image-drop
              :editMode="computedEditMode"
              :projectId="project.id"
              pictureType="constructionSite"
              :pictures="pictures.constructionSite"
              title="Bebilderung Baustelle"
            />
            <pick-pack-image-drop
              :editMode="computedEditMode"
              :projectId="project.id"
              pictureType="approach"
              :pictures="pictures.approach"
              title="Bebilderung Anfahrt"
            />
            <pick-pack-image-drop
              :editMode="computedEditMode"
              :projectId="project.id"
              pictureType="aerialView"
              :pictures="pictures.aerialView"
              title="Luftbild Baustelle"
            />
          </div>
        </el-col>
      </el-row>
    </perfect-scrollbar>
    <archived-reports
      :visible="archvedVisible"
      :projectId="project.id"
      :projectName="project.bvName"
      :isEditMode="computedEditMode"
      @close="closeDrawer"
    />
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Message, Input, DatePicker, ButtonGroup, Button, Dropdown, DropdownMenu, DropdownItem } from "element-ui";
import { moment } from "src/config/moment";
import printJs from "print-js";
import PickPackImageDrop from "./PickPackImageDrop.vue";
import PickPackResourceTable from "./PickPackResourceTable.vue";
import ArchivedReports from "./ArchivedReports.vue";
import ArchiveIcon from "vue-material-design-icons/Archive";
import FileIcon from "vue-material-design-icons/File";
import FileStarIcon from "vue-material-design-icons/FileStar";
import CheckBoldIcon from "vue-material-design-icons/CheckBold";
import PrinterCheckIcon from "vue-material-design-icons/PrinterCheck";
import { unlink } from "src/utils/unlink";

export default {
  name: "pickpack-list",
  components: {
    PerfectScrollbar,
    Message,
    ArchiveIcon,
    FileIcon,
    FileStarIcon,
    CheckBoldIcon,
    PrinterCheckIcon,
    [Input.name]: Input,
    [ButtonGroup.name]: ButtonGroup,
    PickPackImageDrop,
    ArchivedReports,
    [PickPackResourceTable.name]: PickPackResourceTable,
    [Button.name]: Button,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
    [DropdownItem.name]: DropdownItem,
    [DatePicker.name]: DatePicker,
  },
  props: {
    accessRights: String,
    project: Object,
    projectConfigurationManager: Object,
    refreshPickPack: Boolean,
    isEditMode: {
      type: Boolean,
      default: true,
    },
  },
  watch: {
    refreshPickPack: function (newVal, oldVal) {
      this.computeModels();
      if (!this.dateSelection || !this.dateSelection.length) {
        this.dateSelection = this.project.dateRange.slice();
      }
    },
    dateSelection: function (newVal, oldVal) {
      if (newVal && newVal.length) {
        this.computeModels();
      }
    },
  },
  data() {
    return {
      dateSelection: [],
      archvedVisible: false,
      loadingPickPack: false,
      loadingProjectList: false,
      pickAndPackList: {},
      machineModels: [],
      rhbModels: [],
      supplyModels: [],
      vehicleModels: [],
      employeeModels: [],
      pictures: {
        constructionSite: [],
        approach: [],
        aerialView: [],
      },
    };
  },
  computed: {
    datePickerOptions: function () {
      const self = this;
      return {
        firstDayOfWeek: 1,
        disabledDate: function (date) {
          const { dateRange } = self.project;
          // disable all dates beyond project range
          // NOTE: snapTo is required to extend date range from 00:00 of start till 23:59 of end date
          return !moment(date).hours(12).within(moment.range(dateRange).snapTo("day"));
        },
      };
    },
    datePickerAttributes() {
      // add interruptions
      const interruptions = this.project.interruptions || [];
      return interruptions.map((item) => ({
        bar: { color: "gray" },
        key: item._id,
        dates: {
          start: moment(item.dateRange[0]).toDate(),
          end: moment(item.dateRange[1]).toDate(),
        },
        popover: { label: `Unterbrechung${item.notes ? ": " + item.notes : ""} `, hideIndicator: true },
      }));
    },
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
  mounted() {
    this.fetchPictures();
  },
  methods: {
    async fetchPictures() {
      try {
        const response = await this.axios.get(`/api/projects/${this.project.id}/pictures`);
        this.pictures = response.data;
      } catch (error) {
        throw error;
      }
    },
    closeDrawer() {
      this.archvedVisible = false;
    },
    handleCommand(command) {
      switch (command) {
        case "pickpack_save":
          this.saveReport("pickPack");
          break;
        case "pickpack_print":
          this.printReport("pickPack");
          break;
        case "projectlist_save":
          this.saveReport("projectList");
          break;
        case "projectlist_print":
          this.printReport("projectList");
          break;
        default:
          break;
      }
    },
    async saveReport(reportType) {
      if (!this.dateSelection || !this.dateSelection.length) {
        Message.error("Bitte wählen Sie einen Zeitraum aus");
        return;
      }
      const { start, end } = moment.range(this.dateSelection).snapTo("day");
      const reportTypeToEnum = {
        pickPack: "pick_pack",
        projectList: "project_list",
      };
      const reportTypeLoader = {
        pickPack: "loadingPickPack",
        projectList: "loadingProjectList",
      };

      const formData = {
        dateRange: [start, end],
        projectId: this.project.id,
        reportType: reportTypeToEnum[reportType],
      };
      try {
        this[reportTypeLoader[reportType]] = true;
        const response = await this.axios.request({
          method: "POST",
          url: "/api/reports/create",
          data: formData,
          responseType: "blob",
        });
        const blob = response.data;
        let filename;
        const contentDisposition = response.headers["Content-Disposition"];
        if (contentDisposition) {
          filename = contentDisposition.match('filename="([^"]*)"')[1];
        } else {
          filename = `${reportType}.pdf`;
        }
        const file = new File([blob], filename, { type: "application/octet-stream" });
        Message({
          message: "PDF erstellt",
          type: "success",
        });
        return window.URL.createObjectURL(file);
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this[reportTypeLoader[reportType]] = false;
      }
    },
    async printReport(reportType) {
      try {
        const objectUrl = await this.saveReport(reportType);
        printJs({
          printable: objectUrl,
          type: "pdf",
          showModal: true,
          modalMessage: "Vorbereitung zum Drucken",
          onError: (err) => {
            throw err;
          },
        });
      } catch (error) {
        throw error;
      }
    },
    saveSupplyQuantity(scope) {
      const supply = scope.row;
      const dateSelection = this.dateSelection.slice();
      if (supply.quantity) {
        if (!supply.projectData) {
          supply.projectData = {};
        }
        if (supply.projectData[this.project.id]) {
          supply.projectData[this.project.id].quantity = supply.quantity;
        } else {
          supply.projectData[this.project.id] = {
            quantity: +supply.quantity,
            dateRange: [{ start: dateSelection[0], end: dateSelection[1] }],
          };
        }
        this.$emit("updateSuppliesProjectData", supply.id, supply.projectData);
        this.$emit("updateProject");
      } else {
        Message({
          message: "Feld ist leer",
          type: "warning",
        });
      }
    },
    computeModels() {
      console.log("computeModels");
      this.machineModels = this.getFilteredModels(this.projectConfigurationManager.dataStore.machineModels);

      this.rhbModels = this.getFilteredModels(this.projectConfigurationManager.dataStore.rhbModels);

      this.supplyModels = this.getFilteredModels(this.projectConfigurationManager.computedDataByModelType("supply"));

      this.vehicleModels = this.getFilteredModels(this.projectConfigurationManager.dataStore.vehicleModels);

      this.employeeModels = this.getFilteredModels(this.projectConfigurationManager.dataStore.employeeModels);
    },
    // returns models that are assigned on project within selected date range
    getFilteredModels(models) {
      if (!models) {
        return [];
      }
      const currentProjectId = this.project.id;
      const selectedRange = moment.range(this.dateSelection.slice()).snapTo("day");
      // returns models list with actual to selected date range project events
      const selectedModels = unlink(models).reduce((modelsList, currentModel) => {
        // check if model is assigned and available within selected daterange
        if (
          this.isAssignedToProject(currentModel, currentProjectId, selectedRange)
          // isFreeFromStatusEvents - hidden according to comment https://www.goodday.work/t/iGFG42/PYyVyl
          // && this.isFreeFromStatusEvents(currentModel, selectedRange)
        ) {
          // leave only project events within selected date range
          const actualProjectEvents = currentModel.projectEvents.filter((event) => {
            if (event.projectId !== currentProjectId) {
              return false;
            }
            console.log(currentModel.name, "event.start", event.start, moment(event.start).within(selectedRange));
            if (moment(event.start).within(selectedRange)) {
              return true;
            }
            // const eventDateRange = moment.range(event.start, event.end);
            // if (selectedRange.overlaps(eventDateRange)) {
            //   return true;
            // }
            return false;
          });
          currentModel.projectEvents = actualProjectEvents;
          modelsList.push(currentModel);
        }
        return modelsList;
      }, []);
      return selectedModels;
    },
    // checks if model is assigned to current project within current "dateSelection"
    isAssignedToProject(model, currentProjectId, selectedDateRange) {
      let isAssigned = false;
      for (const projectEvent of model.projectEvents) {
        const projectID = projectEvent.projectId;
        //if this is not the current project - continue
        if (currentProjectId !== projectID) {
          continue;
        }
        //it is the same project set as selected
        else {
          if (moment(projectEvent.start).within(selectedDateRange)) {
            isAssigned = true;
            break;
          }
          // const eventDateRange = moment.range(projectEvent.start, projectEvent.end).snapTo("day");
          // if (selectedDateRange.overlaps(eventDateRange, { adjacent: true })) {
          //   isAssigned = true;
          //   break;
          // }
        }
      }
      return isAssigned;
    },
    // checks if model is free from status events within current "dateSelection"
    isFreeFromStatusEvents(model, selectedDateRange) {
      let isFree = true;
      if (model.statusEvents && model.statusEvents.length) {
        for (const event of model.statusEvents) {
          const eventDateRange = moment.range(event.start, event.end).snapTo("day");
          if (selectedDateRange.overlaps(eventDateRange, { adjacent: true })) {
            isFree = false;
            return isFree;
          }
        }
      }
      return isFree;
    },

    // highlightStatusRows({ row, rowIndex }) {
    //   if (!row.available) {
    //     return "warning-row";
    //   }

    //   return "";
    // }
  },
  filters: {
    formatDateRange(dateRange = []) {
      return dateRange.map((date) => moment(date).format("L")).join(" - ");
    },
  },
};
</script>

<style lang="scss">
.el-table .warning-row {
  background: oldlace;
}
@import "~vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
</style>
<style scoped>
.ps {
  height: calc(100vh - 255px);
}
</style>
