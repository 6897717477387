<template>
  <div>
    <form>
      <span class="n-profile-title">{{ tabName }}</span>

      <div class="card-body">
        <el-row :gutter="20">
          <template v-if="computedEditMode">
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-edit-avatar"
                :isEditMode="computedEditMode"
                :actionURI="actionURI"
                :avatar="machine.picture"
                @avatar-changed="setPicture"
                :name="'machine_' + machine.id + '_picture_0'"
              />
              <div style="width: 140px">
                <profile-switch
                  :title="$t('src.components.machine.profile.aktiv')"
                  :disabled="!isEditMode"
                  v-model="machine.active"
                  :active-text="$t('src.components.machine.profile.ja')"
                  :inactive-text="$t('src.components.machine.profile.nein')"
                  colorizeActive
                />
              </div>
            </el-col>
            <el-col :lg="6" :md="8">
              <el-row>
                <el-col>
                  <!-- NAME -->
                  <profile-input
                    required
                    v-model="machine.name"
                    :label="$t('src.components.machine.profile.name')"
                    rules="required"
                    name="Maschinen-Name"
                    :editMode="computedEditMode"
                  />
                </el-col>

                <el-col style="width: 50px">
                  <!-- COLOR PICKER -->
                  <span class="n-profile-label-title">{{ $t("src.components.machine.profile.farbe") }}</span
                  ><br />
                  <el-color-picker
                    size="medium"
                    :disabled="!isEditMode"
                    v-model="machine.color"
                    :predefine="predefinedColors"
                  ></el-color-picker>
                </el-col>
              </el-row>
            </el-col>
          </template>
          <template v-else>
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-view-avatar n-grid-container-view"
                :isEditMode="computedEditMode"
                :actionURI="actionURI"
                :avatar="machine.picture"
                @avatar-changed="setPicture"
                :name="'machine_' + machine.id + '_picture_0'"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <div class="n-profile-name">
                <span class="n-profile-label-title">{{ $t("src.components.machine.profile.name") }}</span
                ><br />
                {{ machine.name }}
              </div>
              <div style="display: flex">
                <!-- AKTIV/INAKTIV -->
                <div style="display: flex; align-items: center; width: 80px" class="n-profile-name-light">
                  <el-badge class="profile" :type="machine.active ? 'success' : 'danger'" is-dot />
                  {{ machine.active ? "Aktiv" : "Inaktiv" }}
                </div>
                <!-- COLOR PICKER -->
                <div style="width: 60px; display: inline-block">
                  <span class="n-profile-label-title">{{ $t("src.components.machine.profile.farbe") }}</span
                  ><br />
                  <div
                    v-bind:style="{
                      border: '1px solid black',
                      width: '30px',
                      height: '30px',
                      backgroundColor: machine.color,
                    }"
                  />
                </div>
              </div>
            </el-col>
          </template>

          <el-col :lg="5" :md="8">
            <!-- Warengruppe 1 DROPDOWN -->
            <product-group-select
              :editMode="computedEditMode"
              productGroupType="pg1"
              v-model="machine.selectedProductGroup1"
            />
          </el-col>

          <el-col :lg="5" :md="8">
            <!--  Warengruppe 2 DROPDOWN -->
            <product-group-select
              :editMode="computedEditMode"
              productGroupType="pg2"
              v-model="machine.selectedProductGroup2"
            />
            <!-- Position -->
            <profile-input
              type="number"
              v-model="machine.order"
              :label="$t('Order')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :lg="5" :md="8">
            <profile-select
              :title="$t('src.components.machine.profile.leistungsspektrum')"
              :items="options['serviceSpectrum']"
              v-model="machine.selectedServiceSpectrumList"
              valueIdentifier="_id"
              :editMode="computedEditMode"
              :multiple="true"
              class="mb-2"
            />

            <!-- TEAM -->
            <profile-input v-model="machine.team" :label="$t('team')" :editMode="computedEditMode" />
          </el-col>
        </el-row>

        <hr />

        <!-- MASCHINENDATEN -->
        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.machine.profile.maschinendaten") }}
        </div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col :span="4">
            <profile-switch
              :title="$t('src.components.machine.profile.dispoRelevant')"
              :disabled="!isEditMode"
              v-model="machine.projectRelevant"
              :active-text="$t('src.components.machine.profile.ja')"
              :inactive-text="$t('src.components.machine.profile.nein')"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.description"
              :label="$t('src.components.machine.profile.beschreibung')"
              :name="$t('src.components.machine.profile.beschreibung')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.serialNumber"
              :label="$t('src.components.machine.profile.seriennummer')"
              :name="$t('src.components.machine.profile.seriennummer')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.machineNumber"
              :label="$t('src.components.machine.profile.maschinennummer')"
              :name="$t('src.components.machine.profile.maschinennummer')"
              :editMode="computedEditMode"
            />
          </el-col>

          <el-col :span="4">
            <profile-switch
              :title="$t('src.components.machine.profile.operatingHourRecording')"
              :name="$t('src.components.machine.profile.operatingHourRecording')"
              :disabled="!isEditMode"
              v-model="machine.operatingHoursRecording"
              :active-text="$t('src.components.machine.profile.ja')"
              :inactive-text="$t('src.components.machine.profile.nein')"
            />
          </el-col>
        </el-row>

        <hr />

        <!-- GEWICHT -->
        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.machine.profile.gewicht") }}</div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col :span="4">
            <profile-input
              v-model="machine.transportWeight"
              :label="$t('src.components.machine.profile.transportgewicht')"
              :name="$t('src.components.machine.profile.transportgewicht')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.operatingWeight"
              :label="$t('src.components.machine.profile.betriebsgewicht')"
              :name="$t('src.components.machine.profile.betriebsgewicht')"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>

        <hr />

        <!-- MAßE -->
        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.machine.profile.mae") }}</div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col :span="4">
            <profile-input
              v-model="machine.length"
              :label="$t('src.components.machine.profile.lnge')"
              :name="$t('src.components.machine.profile.lnge')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.minWidth"
              :label="$t('src.components.machine.profile.breiteMin')"
              :name="$t('src.components.machine.profile.breiteMin')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.maxWidth"
              :label="$t('src.components.machine.profile.breiteMax')"
              :name="$t('src.components.machine.profile.breiteMax')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.minHeight"
              :label="$t('src.components.machine.profile.hheMin')"
              :name="$t('src.components.machine.profile.hheMin')"
              :editMode="computedEditMode"
            />
          </el-col>
        </el-row>

        <hr />

        <!-- Sonstiges -->
        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.machine.profile.sonstiges") }}</div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col :span="4">
            <profile-input
              v-model="machine.yearOfConstruction"
              :label="$t('src.components.machine.profile.baujahr')"
              :name="$t('src.components.machine.profile.baujahr')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <profile-input
              v-model="machine.kilowatts"
              :label="$t('src.components.machine.profile.kw')"
              :name="$t('src.components.machine.profile.kw')"
              :editMode="computedEditMode"
            />
          </el-col>
          <el-col :span="4">
            <span class="n-profile-label-title">{{ $t("src.components.machine.profile.reportsBeginnenAb") }}</span>
            <br />
            <span class="n-profile-label-value">{{ machine.startDate | formatDate }}</span>
          </el-col>
        </el-row>

        <hr />
        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.machine.profile.verbrauchsmaterial") }}
        </div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col :span="4">
            <profile-select
              :title="$t('src.components.machine.profile.verbrauchsmaterialListe')"
              :name="$t('src.components.machine.profile.verbrauchsmaterialListe')"
              :items="filteredSupplyOptions"
              v-model="pickedSupply"
              valueIdentifier="id"
              :editMode="computedEditMode"
              :multiple="false"
            />
          </el-col>
          <el-col :span="1" style="margin-top: 25px; padding-left: 10px">
            <el-button class="btn btn-sm btn-ghost" @click.prevent="addSupply" data-testid="button_addRHBSupply">
              <plus-icon />
            </el-button>
          </el-col>
          <el-col :span="18">
            <selection-table
              :tableData="selectedSupplyRecords"
              resourceType="supply"
              v-on:deleteResource="handleDeleteResource"
            />
          </el-col>
        </el-row>

        <hr />
        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.machine.profile.rhb") }}</div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col :span="4">
            <profile-select
              :title="$t('src.components.machine.profile.verbrauchsmaterialListe')"
              :name="$t('src.components.machine.profile.verbrauchsmaterialListe')"
              :items="filteredRHBOptions"
              v-model="pickedRHB"
              valueIdentifier="id"
              :editMode="computedEditMode"
              :multiple="false"
            />
          </el-col>
          <el-col :span="1" style="margin-top: 25px; padding-left: 10px">
            <el-button class="btn btn-sm btn-ghost" @click.prevent="addRHB" data-testid="button_addRHB">
              <plus-icon />
            </el-button>
          </el-col>
          <el-col :span="18">
            <selection-table
              :tableData="selectedRHBRecords"
              resourceType="rhb"
              v-on:deleteResource="handleDeleteResource"
            />
          </el-col>
        </el-row>
        <hr />
        <date-history
          :isEditMode="computedEditMode"
          :dateHistory="machine.dateHistory"
          :dateOfEntering="machine.dateOfEntering"
          :dateOfLeaving="machine.dateOfLeaving"
          resourceType="machine"
          :resourceId="machine.id"
          @setProfileData="setProfileData"
          @saveDateHistory="saveDateHistory"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { Message, RadioGroup, RadioButton, Switch, DatePicker, ColorPicker, Image } from "element-ui";
import { mapActions } from "vuex";
import { Avatar, Collapse, CollapseItem } from "src/components/UIComponents";
import { sortBy } from "lodash";
import { moment } from "src/config/moment";

import ResourceSelectionTableVue from "./ResourceSelectionTable";
import DateHistory from "../Generic/DateHistory.vue";

export default {
  name: "machine-profile",
  props: {
    machine: Object,
    accessRights: String,
    tabName: String,
    tabName: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    updateProfile: {
      type: Function,
      required: true,
    },
  },
  components: {
    Message,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [Switch.name]: Switch,
    Collapse,
    CollapseItem,
    DateHistory,
    [DatePicker.name]: DatePicker,
    [ColorPicker.name]: ColorPicker,
    [Image.name]: Image,
    [ResourceSelectionTableVue.name]: ResourceSelectionTableVue,
  },
  data() {
    return {
      predefinedColors: [
        "#581713",
        "#a94b43",
        "#fbd283",
        "#62733f",
        "#034f73",
        "#96e3ff",
        "#255385",
        "#09111e",
        "#181238",
        "#d9d6cd",
        "#3d3936",
        "#837e7a",
        "#8d8d95",
        "#b5c4d7",
        "#dcba9f",
        "#d2cdca",
        "#fee0a0",
        "#84a0b5",
        "#bddc79",
        "#0ed145",
      ],
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      suppliers: [],
      options: {
        serviceSpectrum: [],
      },
      rhbOptions: [],
      supplyOptions: [],
      ignoreDirty: false,
      daterangeOptions: {
        firstDayOfWeek: 1,
      },
      pickedRHB: "",
      pickedSupply: "",
      selectedRHBRecords: [], // data that is used to display list of selected RHB's
      selectedSupplyRecords: [], // data that is used to display list of selected Supplies
    };
  },
  watch: {
    "$props.machine.selectedRHBList": function (newVal = [], oldVal = []) {
      this.selectedRHBRecords = this.rhbOptions.filter((item) => newVal.includes(item.id));
    },
    "$props.machine.selectedSupplyList": function (newVal = [], oldVal = []) {
      this.selectedSupplyRecords = this.supplyOptions.filter((item) => newVal.includes(item.id));
    },
    machine: function (newVal, oldVal) {
      this.ignoreDirty = true;
    },
    "$props.machine": {
      handler: function (val, oldVal) {
        if (!val.inital) {
          const { startDate, ...dataToCheck } = val;
          this.checkDirty(dataToCheck);
        }
      },
      deep: true,
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    filteredSupplyOptions: function () {
      const { selectedSupplyList = [] } = this.$props.machine;
      return this.getResourcesForProjectGroups(this.supplyOptions, selectedSupplyList).map((item) => ({
        id: item.id,
        label: item.name,
      }));
    },
    filteredRHBOptions: function () {
      const { selectedRHBList = [] } = this.$props.machine;
      return this.getResourcesForProjectGroups(this.rhbOptions, selectedRHBList).map((item) => ({
        id: item.id,
        label: item.name,
      }));
    },
  },
  mounted() {
    //settins changed
    this.$root.$on("settingsChanged", (data) => {
      if (data.modelType === "global") {
        //operation = "deleted" may remove the assignement
        if (data.operation === "deleted") {
          switch (data.modelID) {
            case "serviceSpectrum":
              this.deleteSettings(data.options);
              break;
          }
        }
        //operation = "loaded", "updated" and "created" just updates the options select
        this.options[data.modelID] = data.options;
      }
    });

    this.axios
      .all([this.axios.get("/api/rhb"), this.axios.get("/api/supply")])
      .then(
        this.axios.spread((rhb, supply) => {
          this.supplyOptions = sortBy(supply.data, (o) => o.name && o.name.toLowerCase());
          this.rhbOptions = sortBy(rhb.data, (o) => o.name && o.name.toLowerCase());
        })
      )
      .catch(function (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
  },
  methods: {
    ...mapActions("dirtyFlag", { checkDirty: "checkDirty" }),
    setProfileData(data) {
      this.$emit("setProfileData", data);
    },
    saveDateHistory(data) {
      this.$emit("savePartialProfileData", data);
    },
    handleDeleteResource(resourceType, resource) {
      if (resourceType === "rhb") {
        this.$props.machine.selectedRHBList = this.$props.machine.selectedRHBList.filter((id) => id !== resource.id);
      } else if (resourceType === "supply") {
        this.$props.machine.selectedSupplyList = this.$props.machine.selectedSupplyList.filter(
          (id) => id !== resource.id
        );
      }
      this.updateProfile(this.machine);
    },
    // filters resources that match one of product groups of machine
    // and filters all that is already in selected list (selectedSupplyList or selectedRHBList)
    getResourcesForProjectGroups(resources, selectedResources) {
      const productGroup1 = this.machine.selectedProductGroup1;
      const productGroup2 = this.machine.selectedProductGroup2;
      if (!productGroup1 && !productGroup2) {
        return resources;
      }
      return resources.reduce((array, current) => {
        if (
          // matches one of product group
          (current.selectedProductGroup1 === productGroup1 || current.selectedProductGroup2 === productGroup2) &&
          // is not included in selected list
          !selectedResources.includes(current.id)
        ) {
          array.push(current);
        }
        return array;
      }, []);
    },
    addRHB() {
      if (!this.pickedRHB) {
        Message.error("Bitte RHB auswählen");
        return;
      }
      this.$props.machine.selectedRHBList.push(this.pickedRHB);
      this.pickedRHB = "";
      this.updateProfile(this.machine);
    },
    addSupply() {
      if (!this.pickedSupply) {
        Message.error("Bitte Verbrauchsmaterial auswählen");
        return;
      }
      this.$props.machine.selectedSupplyList.push(this.pickedSupply);
      this.pickedSupply = "";
      this.updateProfile(this.machine);
    },
    setPicture(pictureSettings) {
      this.machine.picture = pictureSettings;
    },
    deleteSettings(options) {
      if (this.machine.selectedServiceSpectrumList) {
        let updateFlag = false;
        for (let index = 0; index < this.machine.selectedServiceSpectrumList.length; index++) {
          let test = options.filter((option) => option.id === this.machine.selectedServiceSpectrumList[index]);

          if (test.length == 0) {
            this.machine.selectedServiceSpectrumList.splice(index); // remove this ID
            index -= 1; //correct index of for loop
            updateFlag = true;
          }
        }

        if (updateFlag) {
          this.updateProfile(this.machine);
        }
      }
    },
  },
  filters: {
    formatDate: function (date) {
      if (!date) {
        return "N/A";
      }
      return moment(date).format("L");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile .el-badge__content.is-dot {
  padding: 0px 0px 0px 0px; /* padding: top right bottom left */
}

.n-grid-container-view {
  display: grid;
  grid-template-columns: 0px 100px 200px 100px;
  grid-template-areas:
    "avatar avatar content content"
    "avatar avatar content content";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
  text-align: left;
}

.n-grid-container-view-avatar {
  grid-area: avatar;
}
.n-grid-container-view-content {
  grid-area: content;
}

.n-grid-container-edit {
  display: grid;
  grid-template-columns: 0px 100px 100px 100px;
  grid-template-areas:
    "avatar avatar firstname firstname"
    "avatar avatar state empty";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
}

.n-grid-container-edit > div {
  text-align: left;
}

.n-grid-container-edit-avatar {
  grid-area: avatar;
}
.n-grid-container-edit-firstname {
  grid-area: firstname;
}

.n-grid-container-edit-state {
  margin-top: 20px;
  grid-area: state;
}
</style>
