<template>
  <div>
    <div class="form-group row">
      <div class="col-md-12">
        <el-form :disabled="!computedEditMode" :inline="true" :model="costsRecord" :rules="rules" ref="costsForm">
          <el-form-item prop="position">
            <el-select
              :disabled="!computedEditMode"
              :filterable="true"
              v-model="costsRecord.position"
              :placeholder="$t('src.components.project.projectcontrolling.costs.position')"
            >
              <el-option-group
                :label="$t('src.components.project.projectcontrolling.costs.dienstleiser')"
                key="serviceProviders"
              >
                <el-option
                  v-for="option in providersOptions"
                  :key="option.label"
                  :label="option.label"
                  :value="option.label"
                ></el-option>
              </el-option-group>
              <el-option-group :label="$t('src.components.project.projectcontrolling.costs.positions')" key="Positions">
                <el-option
                  v-for="option in positionsOptions"
                  :key="option.label"
                  :label="option.label"
                  :value="option.label"
                ></el-option>
              </el-option-group>
            </el-select>
          </el-form-item>
          <el-form-item prop="name">
            <el-input
              v-model="costsRecord.name"
              :placeholder="$t('src.components.project.projectcontrolling.costs.name')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="description">
            <el-input
              v-model="costsRecord.description"
              :placeholder="$t('src.components.project.projectcontrolling.costs.description')"
            >
            </el-input>
          </el-form-item>
          <el-form-item prop="amount"> </el-form-item>
          <el-form-item prop="cost">
            <price-input
              style="max-width: 200px"
              v-model="costsRecord.cost"
              :placeholder="$t('src.components.project.projectcontrolling.costs.cost')"
              :disabled="!computedEditMode"
              clearable
            >
              <template v-slot:append>€</template>
            </price-input>
            <!-- <el-input
              type="number"
              style="max-width: 200px"
              v-model="costsRecord.cost"
              :placeholder="$t('src.components.project.projectcontrolling.costs.cost')"
              min="0"
              step="0.01"
              :disabled="!computedEditMode"
            >
              <template v-slot:append>€</template>
            </el-input> -->
          </el-form-item>
          <el-form-item prop="isConstructionSite">
            <el-checkbox v-model="costsRecord.isConstructionSite" :disabled="!computedEditMode">{{
              $t("src.components.project.projectcontrolling.costs.baustellengemeinkosten")
            }}</el-checkbox>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit" :loading="loading">{{
              $t("src.components.project.projectcontrolling.costs.hinzufgen")
            }}</el-button>
          </el-form-item>
        </el-form>
        <div style="margin-bottom: 10px">
          <el-switch
            v-model="hideRelatedCosts"
            :active-text="$t('src.components.project.projectcontrolling.costs.hideRelatedCosts')"
          ></el-switch>
        </div>
        <el-table :data="computedCosts" style="width: 100%" :default-sort="{ prop: 'position', order: 'ascending' }">
          <el-table-column
            type="index"
            :label="$t('src.components.project.projectcontrolling.costs.nr')"
            sortable
            width="60"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.projekt')"
            min-width="180"
            prop="parentProjectName"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.position')"
            min-width="180"
            sortable
            prop="position"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.name')"
            min-width="180"
            sortable
            prop="name"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.description')"
            min-width="180"
            sortable
            prop="description"
          ></el-table-column>
          <el-table-column label="€" sortable prop="cost" :formatter="costFormatter" width="150"></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.baustellengemeinkosten')"
            sortable
            prop="isConstructionSite"
            :formatter="formatBoolean"
            width="200"
          ></el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.dokument')"
            sortable
            prop="document"
            width="150"
          >
            <template v-slot="scope">
              <a v-if="scope.row.document" :href="getUrlForDocument(scope.row)" target="_blank">{{
                scope.row.document.name
              }}</a>
            </template>
          </el-table-column>
          <el-table-column
            :label="$t('src.components.project.projectcontrolling.costs.aktion')"
            fixed="right"
            width="200"
          >
            <template slot-scope="scope" v-if="computedEditMode">
              <el-button class="btn btn-sm btn-ghost" @click="handleEditRecord(scope.row, scope.$index)">
                <pencil-icon />
              </el-button>
              <el-button class="btn btn-sm btn-ghost" @click="handleDeleteRecord(scope.$index)">
                <trash-can-outline-icon />
              </el-button>

              <el-upload
                v-if="!scope.row.document"
                :headers="authHeader"
                :disabled="!scope.row._id"
                ref="upload"
                style="display: inline-block"
                :action="actionURI"
                :data="{ metadata: getMetadata(scope.row) }"
                :show-file-list="false"
                :on-success="fileUploadedInDialog"
                :on-error="handleUploadError"
                :limit="1"
                :multiple="false"
              >
                <el-button class="btn btn-sm btn-ghost" slot="trigger"><file-document-plus-outline-icon /></el-button>
              </el-upload>
            </template>
          </el-table-column>
        </el-table>
        <div class="text-right" style="font-weight: bold; margin: 20px 20px 0 0">
          {{ $t("src.components.project.projectcontrolling.costs.summe", { "1": getTotalCostsText }) }}
        </div>
      </div>
    </div>

    <el-dialog
      :title="$t('src.components.project.projectcontrolling.costs.bearbeiten')"
      :visible.sync="isEditVisible"
      width="100%"
      style="max-width: 1200px; margin: 0 auto"
      :close-on-click-modal="false"
      @close="discard"
    >
      <el-form :rules="editingRules" ref="editFormRef" :model="editingCostsRecord" label-position="top">
        <div class="row">
          <div class="col-md-4">
            <el-form-item prop="position" :label="$t('src.components.project.projectcontrolling.costs.position')">
              <el-select
                :disabled="!computedEditMode"
                :filterable="true"
                v-model="editingCostsRecord.position"
                :placeholder="$t('src.components.project.projectcontrolling.costs.position')"
                style="width: 100%"
              >
                <el-option-group
                  :label="$t('src.components.project.projectcontrolling.costs.dienstleiser')"
                  key="serviceProviders"
                >
                  <el-option
                    v-for="option in providersOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                  ></el-option>
                </el-option-group>
                <el-option-group
                  :label="$t('src.components.project.projectcontrolling.costs.positions')"
                  key="Positions"
                >
                  <el-option
                    v-for="option in positionsOptions"
                    :key="option.label"
                    :label="option.label"
                    :value="option.label"
                  ></el-option>
                </el-option-group>
              </el-select>
            </el-form-item>
          </div>
          <div class="col-md-8">
            <el-form-item prop="description" :label="$t('src.components.project.projectcontrolling.costs.description')">
              <el-input
                style="width: 100%"
                v-model="editingCostsRecord.description"
                :placeholder="$t('src.components.project.projectcontrolling.costs.description')"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="col-md-4">
            <el-form-item prop="name" label="Name">
              <el-input
                style="width: 100%"
                v-model="editingCostsRecord.name"
                :placeholder="$t('src.components.project.projectcontrolling.costs.name')"
              >
              </el-input>
            </el-form-item>
          </div>
          <div class="col-md-4">
            <el-form-item prop="cost" :label="$t('src.components.project.projectcontrolling.costs.cost')">
              <price-input
                style="width: 100%"
                v-model="editingCostsRecord.cost"
                :placeholder="$t('src.components.project.projectcontrolling.costs.cost')"
                :disabled="!computedEditMode"
                clearable
              >
                <template v-slot:append>€</template>
              </price-input>
            </el-form-item>
          </div>
          <div class="col-md-4">
            <el-form-item prop="isConstructionSite">
              <el-checkbox
                style="margin-top: 50px"
                v-model="editingCostsRecord.isConstructionSite"
                :disabled="!computedEditMode"
                >{{ $t("src.components.project.projectcontrolling.costs.baustellengemeinkosten") }}</el-checkbox
              >
            </el-form-item>
          </div>
          <div class="col-md-12">
            <el-upload
              :headers="authHeader"
              :disabled="!editingCostsRecord._id"
              ref="upload"
              :action="actionURI"
              :data="{ metadata: getMetadata(editingCostsRecord) }"
              :on-success="fileUploadedInDialog"
              :on-remove="markFileToRemove"
              :on-error="handleUploadError"
              data-testid="upload"
              class="drawer-upload"
              :limit="1"
              :multiple="false"
              drag
              :file-list="editingCostsRecord.document ? [editingCostsRecord.document] : []"
            >
              <div>
                <div class="upload-icon"><file-upload-outline-icon /></div>
                <div class="el-upload__text">
                  <b>{{ $t("src.components.project.invoices.drawerupload.whlenSieEineDatei") }}</b
                  >&nbsp;
                  <em style="color: #46a19c">{{
                    $t("src.components.project.invoices.drawerupload.vonIhremComputerAus")
                  }}</em>
                </div>
              </div>
            </el-upload>
          </div>
        </div>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <el-button @click="isEditVisible = false">{{
            $t("src.components.project.projectcontrolling.costs.abbrechen")
          }}</el-button>
          <el-button type="primary" @click.prevent="saveChanges">{{
            $t("src.components.project.projectcontrolling.costs.speichern")
          }}</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  Table,
  Input,
  Checkbox,
  Form,
  FormItem,
  Select,
  OptionGroup,
  Dialog,
  MessageBox,
  Upload,
  Tooltip,
  Message,
  Switch,
} from "element-ui";
import PriceInput from "src/components/UIComponents/Inputs/PriceInput";
import { mapGetters } from "vuex";
import FileDocumentPlusOutlineIcon from "vue-material-design-icons/FileDocumentPlusOutline.vue";
import FileUploadOutlineIcon from "vue-material-design-icons/FileUploadOutline";

export default {
  name: "costs",
  props: {
    accessRights: { type: String },
    projectId: { type: String, required: true },
    isEditMode: { type: Boolean, required: true },
    costs: { type: Array },
    projectsMeta: { type: Array },
    providersOptions: { type: Array, default: () => [] },
    positionsOptions: { type: Array, default: () => [] },
  },
  components: {
    FileDocumentPlusOutlineIcon: FileDocumentPlusOutlineIcon,
    FileUploadOutlineIcon: FileUploadOutlineIcon,
    PriceInput: PriceInput,
    [Table.name]: Table,
    [Input.name]: Input,
    [Checkbox.name]: Checkbox,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [OptionGroup.name]: OptionGroup,
    [Dialog.name]: Dialog,
    [MessageBox.name]: MessageBox,
    [Upload.name]: Upload,
    [Tooltip.name]: Tooltip,
    [Message.name]: Message,
    [Switch.name]: Switch,
  },
  data: function () {
    return {
      actionURI: this.axios.defaults.baseURL + "/api/fileupload",
      hideRelatedCosts: false,
      isEditVisible: false,
      editingIdx: null,
      costsRecord: {
        position: "",
        description: "",
        cost: null,
        isConstructionSite: false,
      },
      editingCostsRecord: {
        _id: null,
        position: "",
        description: "",
        cost: null,
        isConstructionSite: false,
        document: null,
      },
      rules: {
        name: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        position: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        cost: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        isConstructionSite: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      editingRules: {
        name: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        position: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "blur" }],
        cost: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        isConstructionSite: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      loading: false,
      filenameToRemove: "",
    };
  },
  methods: {
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    onSubmit() {
      this.$refs.costsForm.validate(async (valid) => {
        try {
          if (!valid) {
            return false;
          }
          const { position, description, cost, isConstructionSite, name } = this.costsRecord;
          const record = {
            position: position,
            name: name,
            description: description,
            cost: parseFloat(cost),
            isConstructionSite: isConstructionSite,
          };
          if (!this.costs) {
            this.costs = [];
          }
          this.loading = true;
          const response = await this.axios.post(`/api/project-costs/${this.projectId}`, record);
          this.costs.push(response.data);
          this.$nextTick(() => {
            this.$emit("totalCostsChanged", this.costs.slice());
          });
          this.$refs.costsForm.resetFields();
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    handleEditRecord(storedRecord, index) {
      this.isEditVisible = true;
      this.editingIdx = index;
      this.editingCostsRecord = {
        _id: storedRecord._id,
        position: storedRecord.position,
        name: storedRecord.name,
        description: storedRecord.description,
        cost: storedRecord.cost,
        isConstructionSite: storedRecord.isConstructionSite,
        document: storedRecord.document,
      };
    },
    saveChanges() {
      this.$refs.editFormRef.validate(async (valid) => {
        if (!valid) {
          return;
        }
        try {
          this.loading = true;
          const formData = { ...this.editingCostsRecord };
          formData.cost = parseFloat(formData.cost);
          let response;
          if (this.editingCostsRecord._id) {
            if (this.filenameToRemove) {
              this.removeFile({ name: this.filenameToRemove });
              formData.document = null;
            }
            response = await this.axios.put(`/api/project-costs/${this.editingCostsRecord._id}`, formData);
          } else {
            response = await this.axios.post(`/api/project-costs/${this.projectId}`, formData);
          }
          this.$set(this.costs, this.editingIdx, response.data);
          this.isEditVisible = false;
          this.$nextTick(() => {
            this.$emit("totalCostsChanged", this.costs.slice());
          });
        } catch (error) {
          Message.error(error.message);
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
    handleDeleteRecord(idx) {
      MessageBox.confirm("Sind Sie sicher?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(async () => {
        if (this.costs[idx].document) {
          await this.removeFile(this.costs[idx].document);
        }
        await this.axios.delete(`/api/project-costs/${this.costs[idx]._id}`);
        this.costs.splice(idx, 1);
        this.$nextTick(() => {
          this.$emit("totalCostsChanged", this.costs.slice());
        });
      });
    },
    async fileUploaded(response) {
      const costId = this.getCostIdFromUrl(response[0].url);
      const costIdx = this.costs.findIndex((item) => item._id === costId);
      if (costIdx === -1) {
        throw new Error("Cost item not found with given ID");
      }
      const newRecord = Object.assign({}, this.costs[costIdx], { document: response[0] });
      this.$set(this.costs, costIdx, newRecord);
      await this.axios.put(`/api/project-costs/${costId}`, newRecord);
    },
    async fileUploadedInDialog(response) {
      await this.fileUploaded(response);
      this.editingCostsRecord.document = response[0];
    },
    async markFileToRemove(file) {
      this.filenameToRemove = file.name;
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata(this.editingCostsRecord);
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        // this.editingCostsRecord.document = null;
        // await this.axios.put(`/api/project-costs/${this.editingCostsRecord._id}`, {
        //   ...this.editingCostsRecord,
        //   document: null,
        // });
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    formatBoolean(record) {
      return record.isConstructionSite ? "Ja" : "Nein";
    },
    costFormatter(record) {
      return this.formatCost(record.cost);
    },
    getUrlForDocument(record) {
      return record.document ? record.document.url : undefined;
    },
    formatCost(cost) {
      return parseFloat(cost).toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    resetRecord() {
      this.editingCostsRecord = {
        _id: null,
        position: "",
        name: "",
        description: "",
        cost: "",
        isConstructionSite: false,
        document: null,
      };
      this.editingIdx = null;
    },
    getMetadata(record) {
      const metadata = "project_" + this.projectId + "_costs_" + record._id;
      return metadata;
    },
    getCostIdFromUrl(url) {
      const urlArray = url.split("/");
      const indexOfCosts = urlArray.indexOf("costs");
      if (indexOfCosts === -1 || !urlArray[indexOfCosts + 2]) {
        // indexOfCosts + 2 - should be the last item in array and represent filename
        throw new Error("url is invalid:" + url);
      }
      return urlArray[indexOfCosts + 1]; // next item after "costs" should be an id
    },
    discard() {
      this.resetRecord();
      this.$refs.editFormRef.resetFields();
    },
  },
  computed: {
    ...mapGetters("account", ["authHeader"]),
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    totalCosts() {
      return this.costs.reduce(
        (obj, current) => {
          if (current.isConstructionSite) {
            obj.checked += parseFloat(current.cost);
          } else {
            obj.unchecked += parseFloat(current.cost);
          }
          obj.total += parseFloat(current.cost);
          return obj;
        },
        { checked: 0, unchecked: 0, total: 0 }
      );
    },
    getTotalCostsText() {
      const { checked, unchecked, total } = this.totalCosts;
      if (!total) {
        return "0";
      }
      return `${this.formatCost(total)} (${this.formatCost(checked)}/${this.formatCost(unchecked)})`;
    },
    computedCosts() {
      const costsWithParentProjectName = this.costs.map((cost) => {
        const project = this.projectsMeta.find((project) => project.id === cost.parentProjectId) || {};
        let parentProjectName = project.bvName;
        if (project.constructionPhase) {
          parentProjectName += ` | BA ${project.constructionPhase}`;
        }
        return { ...cost, parentProjectName: parentProjectName };
      });
      if (this.hideRelatedCosts) {
        return costsWithParentProjectName.filter((cost) => cost.parentProjectId === this.projectId);
      }
      return costsWithParentProjectName;
    },
  },
  watch: {
    isEditVisible: function (newVal, oldVal) {
      if (newVal === false && oldVal === true) {
        this.resetRecord();
        this.$refs.editFormRef.resetFields();
      }
    },
  },
};
</script>

<style></style>
