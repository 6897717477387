<template>
  <div>
    <div class="top-actions">
      <div style="flex-grow: 1">
        <div class="n-profile-title">
          {{ $t("src.components.generic.supplierarticles.supplierarticles.sourcing") }}
        </div>
      </div>
      <div style="display: flex; flex-wrap: nowrap; align-items: center">
        <!-- AKTIV/INAKTIV FILTER -->
        <span style="padding-right: 10px; flex-shrink: 0">
          <!-- DESCRIPTION -->
          <span class="n-profile-label-title" style="padding-right: 5px">{{
            $t("src.components.generic.supplierarticles.supplierarticles.filternNach")
          }}</span>
          <!-- DROPDOWN -->
          <el-dropdown trigger="click" @command="changeActive">
            <span class="n-profile-dropdown-value">
              {{ activeLabel }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in filterOptions" :key="item.label" :command="item.value">{{
                item.label
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
        <template v-if="isCopyMode">
          <copy-supplier-dialog
            @discard="discardCopy"
            :currentResourceId="resourceId"
            :resourceType="resourceType"
            :selectedArticles="selectedArticles"
          />
        </template>
        <template v-else>
          <add-supplier-article
            v-if="computedEditMode"
            :resourceId="resourceId"
            :resourceType="resourceType"
            @supplierAssigned="fetchSupplierArticles"
          />
          <el-button class="ml-2" @click="fetchSupplierArticles" :loading="loading">
            <sync-icon />
            {{ $t("Refresh") }}
          </el-button>
          <cancel-button v-if="computedEditMode" class="ml-2" @click.prevent="isCopyMode = true">{{
            $t("src.components.machine.edit.kopieren")
          }}</cancel-button>
        </template>
      </div>
    </div>
    <div class="card-body">
      <el-row>
        <!-- SEARCH -->
        <el-col :span="5">
          <el-input
            class="n-search mr-2"
            v-model="searchQuery"
            :placeholder="$t('src.components.project.projectconfiguration.suche')"
            clearable
            suffix-icon="el-icon-search"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-table
        :data="visibleAssignedSuppliers"
        style="width: 100%"
        :default-sort="{ prop: 'supplier.name', order: 'ascending' }"
        v-loading="loading"
        @selection-change="handleSelectionChange"
        ref="table"
      >
        <el-table-column v-if="isCopyMode" type="selection" width="55"> </el-table-column>
        <el-table-column
          min-width="200"
          sortable
          prop="supplier.name"
          :label="$t('src.components.generic.supplierarticles.supplierarticles.name')"
        >
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{
              $t("src.components.generic.supplierarticles.supplierarticles.name")
            }}</span>
          </template>
          <template v-slot="props">
            <div>
              <el-row type="flex" align="middle">
                <el-col>
                  {{ props.row.supplier ? props.row.supplier.name : "" }}
                  <el-badge
                    :type="props.row.active ? 'success' : 'danger'"
                    style="padding-top: 9px; padding-left: 6px"
                    is-dot
                  />
                </el-col>
              </el-row>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          min-width="100"
          sortable
          :sort-method="(a, b) => hrDataSort('city', a.supplier, b.supplier)"
          :formatter="(r) => getHrDataValue(r.supplier, 'city')"
        >
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{
              $t("src.components.generic.supplierarticles.supplierarticles.stadt")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" sortable prop="articleNum">
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{
              $t("src.components.generic.supplierarticles.supplierarticledetails.lieferantenArtikelNummer")
            }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="100" sortable prop="articleDescr">
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{
              $t("src.components.generic.supplierarticles.supplierarticledetails.lieferantenArtikelBezeichnung")
            }}</span>
          </template>
        </el-table-column>
        <!-- <el-table-column
          min-width="100"
          sortable
          :sort-method="(a, b) => lastPriceSort(a.price, b.price)"
          :formatter="getPrice"
        >
          <template v-slot:header>
            <span class="n-table-header">{{
              $t("src.components.generic.supplierarticles.supplierarticles.preis")
            }}</span>
          </template>
        </el-table-column> -->
        <el-table-column fixed="right" width="130">
          <template v-slot="props">
            <button
              v-if="computedEditMode"
              class="btn btn-sm btn-ghost"
              @click="editSupplier(props.row)"
              data-testid="edit_supplier_article"
            >
              <pencil-icon />
            </button>
            <button class="btn btn-sm btn-ghost" @click="viewSupplier(props.row)" data-testid="view_supplier_article">
              <eye-outline-icon />
            </button>
            <button
              class="btn btn-sm btn-ghost"
              v-if="computedEditMode"
              @click="deleteSupplier(props.row)"
              data-testid="remove_supplier_article"
            >
              <trash-can-outline-icon />
            </button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <supplier-article-details
      ref="details"
      @closed="discardDetails"
      @onSupplierSave="saveSupplier"
      :articleId="selectedSupplierId"
      :resourceType="resourceType"
      :units="units"
      :isEditMode="supplierEditMode"
      :deliveryConditionOptions="deliveryConditionOptions"
    />
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Message, Table, TableColumn, Dropdown, DropdownItem, DropdownMenu } from "element-ui";
import { get, last, debounce, filter } from "lodash";
import SaveProfileButton from "../../UIComponents/Buttons/SaveProfileButton.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import AddSupplierArticle from "./AddSupplierArticle.vue";
import SupplierArticleDetails from "./SupplierArticleDetails.vue";
import CopySupplierDialog from "./CopySupplierDialog.vue";
import SyncIcon from "vue-material-design-icons/Sync.vue";

export default {
  name: "supplier-articles",
  components: {
    PerfectScrollbar,
    SaveProfileButton,
    Message,
    AddSupplierArticle,
    SupplierArticleDetails,
    CopySupplierDialog,
    SyncIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [EyeOutline.name]: EyeOutline,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
  },
  props: {
    isEditMode: Boolean,
    accessRights: String,
    resourceId: String,
    resourceType: String,
    units: { type: Array, default: () => [] },
  },
  data() {
    return {
      searchQuery: "",
      activeLabels: new Map([
        [true, "Aktiv"],
        [false, "Inaktiv"],
        [null, "Alle"],
      ]),
      activeFilter: true,
      filterOptions: [
        {
          value: true,
          label: "Aktiv",
        },
        {
          value: false,
          label: "Inaktiv",
        },
        {
          value: null,
          label: "Alle",
        },
      ],
      loading: false,
      assignedSuppliers: [],
      deliveryConditionOptions: [],
      visibleAssignedSuppliers: [],
      selectedSupplierId: null, // data passed to view/edit drawer
      supplierEditMode: false,
      selectedArticles: [],
      isCopyMode: false,
    };
  },
  created() {
    this.debounceFilterData = debounce(this.filterData, 300);
  },
  mounted() {
    this.fetchSupplierArticles();
    this.fetchDeliveryConditions();
  },
  methods: {
    handleSelectionChange(val) {
      this.selectedArticles = val;
    },
    discardCopy() {
      this.isCopyMode = false;
      this.selectedArticles = [];
      this.$refs.table.clearSelection();
    },
    changeActive(command) {
      this.activeFilter = command;
    },
    async fetchSupplierArticles() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/supplier-articles", {
          params: { resourceType: this.resourceType, resourceId: this.resourceId },
        });
        this.assignedSuppliers = response.data;
        this.$nextTick(() => {
          this.filterData();
        });
        // event is intercepted at BestSuppliers.vue
        this.$root.$emit("supplierArticlesFetched", response.data.slice());
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async fetchDeliveryConditions() {
      try {
        const response = await this.axios.get("/api/model-type", {
          params: {
            modelType: "global",
            modelId: "deliveryConditions",
          },
        });
        this.deliveryConditionOptions = response.data.map((item) => ({
          label: item.label,
          value: item._id,
        }));
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
      }
    },
    viewSupplier(supplierData) {
      this.selectedSupplierId = supplierData._id;
      this.supplierEditMode = false;
      this.$refs.details.showArticleDetails(supplierData._id);
    },
    editSupplier(supplierData) {
      this.selectedSupplierId = supplierData._id;
      this.supplierEditMode = true;
      this.$refs.details.showArticleDetails(supplierData._id);
    },
    async deleteSupplier(supplierData) {
      await this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/supplier-articles/${supplierData._id}`);
          this.fetchSupplierArticles();
          this.$nextTick(() => {
            this.$root.$emit("supplierArticlesFetched", this.assignedSuppliers.slice());
          });
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    async saveSupplier(supplierData) {
      await this.fetchSupplierArticles();
      this.$nextTick(() => {
        this.$root.$emit("supplierArticlesFetched", this.assignedSuppliers.slice());
      });
    },
    hrDataSort(namespace, a, b) {
      const aData = a.hrSupplier && a.hrSupplier[namespace];
      const bData = b.hrSupplier && b.hrSupplier[namespace];
      if (aData > bData) {
        return 1;
      } else if (aData < bData) {
        return -1;
      } else {
        return 0;
      }
    },
    getHrDataValue(record, namespace) {
      if (!record) {
        return {};
      }
      return get(record.hrSupplier, namespace);
    },
    discardDetails() {
      this.selectedSupplierId = null;
      this.supplierEditMode = false;
    },
    lastPriceSort(aPrice, bPrice) {
      const aLastPrice = last(aPrice).purchasingPrice;
      const bLastPrice = last(bPrice).purchasingPrice;
      if (aLastPrice > bLastPrice) {
        return 1;
      } else if (aLastPrice < bLastPrice) {
        return -1;
      } else {
        return 0;
      }
    },
    formatToPrice(number) {
      return Number(number).toLocaleString("de-DE", {
        style: "currency",
        currency: "EUR",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
    },
    getPrice(record) {
      const lastPrice = last(record.price);
      if (!lastPrice) {
        return "";
      }
      const price = lastPrice.purchasingPrice;
      if (this.resourceType === "supply") {
        const unit = this.units.find((u) => u._id === record.accountingUnit);
        const unitStr = unit ? unit.label : "?";
        const accountingAmount = record.accountingAmount || 1;
        return `${this.formatToPrice(price / accountingAmount)}/${unitStr}`;
      } else {
        return this.formatToPrice(price);
      }
    },
    filterData() {
      let activeFilterPredicate;
      switch (this.activeFilter) {
        // active
        case true:
          activeFilterPredicate = (item) => item.active;
          break;
        // inactive
        case false:
          activeFilterPredicate = (item) => !item.active;
          break;
        // both
        default:
          activeFilterPredicate = () => true;
          break;
      }
      const predicates = [activeFilterPredicate];
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        predicates.push((record) => {
          return (
            get(record, "supplier.name", "").toLowerCase().indexOf(query) !== -1 ||
            get(record, "supplier.hrSupplier.city", "").toLowerCase().indexOf(query) !== -1 ||
            get(record, "supplier.hrSupplier.zip", "").toLowerCase().indexOf(query) !== -1 ||
            get(record, "articleNum", "").toLowerCase().indexOf(query) !== -1 ||
            get(record, "articleDescr", "").toLowerCase().indexOf(query) !== -1
          );
        });
      }
      this.visibleAssignedSuppliers = this.assignedSuppliers.filter((item) => predicates.every((fn) => fn(item)));
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    activeLabel() {
      return this.activeLabels.get(this.activeFilter);
    },
    suppliersAvailableForCreate() {
      return "";
    },
  },
  watch: {
    searchQuery(newVal) {
      this.debounceFilterData();
    },
    activeFilter(newVal) {
      this.debounceFilterData();
    },
  },
};
</script>

<style></style>
