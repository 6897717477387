<template>
  <el-dialog :visible="visible" @close="discard" title="Verkürzung/Verlängerung von Ressourcen" width="700px">
    <ValidationObserver ref="validateObserver">
      <div class="flex align-items-center">
        <profile-select
          title="Ressourcen"
          name="Ressourcen"
          :reserve-keyword="true"
          style="flex-grow: 1"
          v-model="selectedRecords"
          isGrouped
          filterable
          :items="availableResources"
          editMode
          required
          rules="required"
        />
        <el-button style="flex-shrink: 0; margin-left: 10px" @click="selectAll">
          {{ $t("selectAll") }}
        </el-button>
      </div>
      <profile-date-picker
        v-model="newEndDate"
        :isEditMode="true"
        :clearable="false"
        required
        rules="required"
        title="neues Enddatum"
      />
      <el-alert class="mb-2" v-if="alertVisible" :title="$t('Resources that have conflicts:')" type="error" show-icon>
        <ul class="mb-1">
          <li v-for="(name, idx) in alertItems" :key="idx">
            {{ name }}
          </li>
        </ul>
      </el-alert>

      <div class="text-right">
        <save-button
          :text="$t('src.components.employees.employeepositions.speichern')"
          @click="submit"
          :loading="loading"
        ></save-button>
      </div>
    </ValidationObserver>
  </el-dialog>
</template>

<script>
import { Dialog, Message, Alert } from "element-ui";
import { groupBy, get } from "lodash";
import { mapGetters, mapState } from "vuex";
import { ValidationObserver } from "vee-validate";

const resourceLabelMap = {
  employee: "Mitarbeiter",
  machine: "Maschinen",
  vehicle: "KFZ",
  rhb: "RHB",
  supply: "Verbrauchsm.",
  subcontractor: "Nachunternehmer",
  project: "Projekt",
};

export default {
  name: "bulk-prolong-dialog",
  components: {
    ValidationObserver,
    [Alert.name]: Alert,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      loading: false,
      visible: false,
      selectedRecords: [],
      availableResources: [],
      projectId: null,
      newEndDate: null,
      alertVisible: false,
      alertContent: "",
    };
  },
  methods: {
    init({ resources, currentEndDate, projectId }) {
      const grouped = groupBy(resources, "resourceType");
      console.log("grouped", grouped);
      this.availableResources = Object.entries(grouped)
        .filter(([resourceType]) => this.hasAccessToResourceType(resourceType))
        .map(([resourceType, list]) => ({
          isGroup: true,
          label: resourceLabelMap[resourceType],
          children: list.map((item) => ({
            value: item.eventId,
            label: item.name,
          })),
        }));
      console.log("this.availableResources", this.availableResources);
      this.newEndDate = currentEndDate;
      this.projectId = projectId;
      this.visible = true;
    },
    selectAll() {
      this.selectedRecords = this.availableResources.reduce((list, group) => {
        list.push(...group.children.map((i) => i.value));
        return list;
      }, []);
    },
    async submit() {
      try {
        const isValid = await this.$refs.validateObserver.validate();
        if (!isValid) {
          return;
        }
        this.loading = true;
        const formBody = {
          projectId: this.projectId,
          eventIds: this.selectedRecords.slice(),
          newEndDate: this.newEndDate,
          granularity: this.granularitySettings,
        };
        await this.axios.post("/api/project-events/bulk-prolong", formBody);
      } catch (error) {
        if (error.response && error.response.data && error.response.data.code === 102) {
          this.handleConflictsAlert(error.response.data.data);
        } else {
          Message({
            message: error.message,
            type: "error",
          });
        }
        throw error;
      } finally {
        this.loading = false;
      }
    },
    discard() {
      Object.assign(this, {
        visible: false,
        projectId: null,
        availableResources: [],
        selectedRecords: [],
        newEndDate: null,
        alertVisible: false,
        alertItems: [],
      });
    },
    handleConflictsAlert(records) {
      const names = records.map((item) => item.title);
      this.alertVisible = true;
      this.alertItems = names;
    },
    hasAccessToResourceType(resourceType) {
      const SUFFIX = "CalendarAccess";
      const access = get(this.calendarAccess, `specificAccess.${resourceType}${SUFFIX}`);
      return access === "full" || access === "manage";
    },
  },
  computed: {
    ...mapGetters("granularitySettings", { granularitySettings: "data" }),
    ...mapState("account", { accessRights: "accessRights", accessRightsLoaded: "accessRightsLoaded" }),
    calendarAccess() {
      return get(this.accessRights, "calendar", { specificAccess: {}, generalAccess: null });
    },
  },
};
</script>
