<template>
  <div>
    <div class="child-todo" v-for="(todo, idx) in data" :key="idx">
      <div class="todo-editor-header">
        <div class="todo-avatar">
          <lazy-image :src="`/api/employees/${todo.creatorId}/picture`" />
        </div>
        <div>
          <div class="todo-name">{{ getAuthorName(todo) }}</div>
          <div class="todo-date">{{ todo.updatedAt | formatDate }}</div>
        </div>
        <div class="todo-header-actions">
          <span @click="handleEditTodo(todo)" v-if="todo.isOwner"><pencil-icon /></span>
          <span v-if="isPrivilegedUser || todo.isOwner" @click="handleDeleteTodo(todo)"
            ><trash-can-outline-icon
          /></span>
        </div>
      </div>
      <div v-html="todo.body" class="mb-2" />
      <drawer-upload
        location="TODOS"
        :file-list="todo.attachments"
        :data="{ metadata: getMetadata() }"
        :pictureList="true"
        :on-remove="removeFile"
        :on-success="fileUploaded"
        :disabled="true"
      />
    </div>
  </div>
</template>

<script>
import DrawerUpload from "../Invoices/DrawerUpload.vue";
import { Message } from "element-ui";
import { moment } from "src/config/moment";
import LazyImage from "../../UIComponents/Image/LazyImage.vue";

export default {
  name: "todo-content",
  components: {
    LazyImage,
    DrawerUpload: DrawerUpload,
    [Message.name]: Message,
  },
  props: {
    isPrivilegedUser: { type: Boolean },
    data: { type: Array, default: () => [] },
    projectId: { type: String, required: true },
  },
  methods: {
    getMetadata() {
      return `project_${this.data.projectId}_todos_0`;
    },
    handleEditTodo(todo) {
      this.$emit("editTodo", todo);
    },
    handleDeleteTodo(todo) {
      this.$emit("deleteTodo", todo._id);
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.data.attachments = this.data.attachments.filter((item) => item._id !== file._id);
        // this.updateFiles();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    fileUploaded(response, file, fileList) {
      this.data.attachments = this.data.attachments.concat(response);
      // this.updateFiles();
    },
    getAuthorName(todo) {
      let authorName = todo.creatorName;
      if (todo.isOwner) {
        authorName += ` (Sie)`;
      }
      return authorName;
    },
  },
  filters: {
    formatDate(dateStr) {
      return moment(dateStr).format("lll");
    },
  },
};
</script>

<style></style>
