<template>
  <el-dialog :visible="visible" :title="$t('Add new todo')" @close="$emit('cancel')" class="todo-dialog">
    <todo-editor
      :isEditMode="true"
      v-bind="$attrs"
      @cancel="$emit('cancel')"
      @onTodoSubmit="$emit('onTodoSubmit', $event)"
      @updateContents="$emit('updateContents', $event)"
      @removeTodo="$emit('removeTodo', $event)"
      @exportPdf="$emit('exportPdf', $event)"
    />
  </el-dialog>
</template>

<script>
import { Dialog } from "element-ui";
import TodoEditor from "./TodoEditor.vue";

export default {
  name: "todo-dialog",
  components: {
    TodoEditor,
    [Dialog.name]: Dialog,
  },
  props: {
    visible: { type: Boolean, required: true },
  },
};
</script>

<style lang="scss" scoped></style>
