<template>
  <div>
    <div>
      <form>
        <span class="n-profile-title">{{ tabName }}</span>

        <div class="card-body">
          <el-row :gutter="20">
            <template v-if="computedEditMode">
              <el-col style="flex-shrink: 0; width: 140px">
                <!-- AVATAR -->
                <avatar-uploader
                  class="n-grid-container-edit-avatar"
                  :isEditMode="computedEditMode"
                  :actionURI="actionURI"
                  :avatar="rhb.picture"
                  @avatar-changed="setPicture"
                  :name="'rhb_' + rhb.id + '_picture_0'"
                />
              </el-col>
              <el-col :lg="6" :md="8">
                <el-row>
                  <el-col>
                    <!-- NAME -->
                    <profile-input
                      required
                      v-model="rhb.name"
                      rules="required"
                      :name="$t('src.components.rhb.profile.name')"
                      :label="$t('src.components.rhb.profile.name')"
                      :editMode="computedEditMode"
                    />
                  </el-col>
                  <el-col style="width: 50px">
                    <!-- COLOR PICKER -->
                    <span class="n-profile-label-title">{{ $t("src.components.rhb.profile.farbe") }}</span
                    ><br />
                    <el-color-picker
                      size="medium"
                      :disabled="!computedEditMode"
                      v-model="rhb.color"
                      :predefine="predefinedColors"
                    ></el-color-picker>
                  </el-col>
                </el-row>
              </el-col>
            </template>
            <template v-else>
              <el-col style="flex-shrink: 0; width: 140px">
                <!-- AVATAR -->
                <avatar-uploader
                  class="n-grid-container-view-avatar n-grid-container-view"
                  :isEditMode="computedEditMode"
                  :actionURI="actionURI"
                  :avatar="rhb.picture"
                  @avatar-changed="setPicture"
                  :name="'rhb_' + rhb.id + '_picture_0'"
                />
              </el-col>
              <el-col :lg="6" :md="8">
                <div class="n-profile-name">
                  <span class="n-profile-label-title">{{ $t("src.components.rhb.profile.name") }}</span
                  ><br />
                  {{ rhb.name }}
                </div>
                <div style="display: flex">
                  <!-- AKTIV/INAKTIV -->
                  <div style="display: flex; align-items: center; width: 80px" class="n-profile-name-light">
                    <el-badge class="profile" :type="rhb.active ? 'success' : 'danger'" is-dot />
                    {{ rhb.active ? "Aktiv" : "Inaktiv" }}
                  </div>
                  <!-- COLOR PICKER -->
                  <div style="width: 60px; display: inline-block">
                    <span class="n-profile-label-title">{{ $t("src.components.rhb.profile.farbe") }}</span
                    ><br />
                    <div
                      v-bind:style="{
                        border: '1px solid black',
                        width: '30px',
                        height: '30px',
                        backgroundColor: rhb.color,
                      }"
                    />
                  </div>
                </div>
              </el-col>
            </template>

            <el-col :lg="5" :md="8">
              <!-- Warengruppe 1 DROPDOWN -->
              <product-group-select
                :editMode="computedEditMode"
                productGroupType="pg1"
                v-model="rhb.selectedProductGroup1"
              />
            </el-col>

            <el-col :lg="5" :md="8">
              <!--  Warengruppe 2 DROPDOWN -->
              <product-group-select
                :editMode="computedEditMode"
                productGroupType="pg2"
                v-model="rhb.selectedProductGroup2"
              />
              <!-- Position -->
              <profile-input
                type="number"
                v-model="rhb.order"
                :label="$t('Order')"
                :editMode="computedEditMode"
              />
            </el-col>

            <el-col :lg="5" :md="8">
              <profile-select
                :title="$t('src.components.rhb.profile.leistungsspektrum')"
                :name="$t('src.components.rhb.profile.leistungsspektrum')"
                :items="options['serviceSpectrum']"
                v-model="rhb.selectedServiceSpectrumList"
                valueIdentifier="_id"
                :editMode="computedEditMode"
                :multiple="true"
                class="mb-2"
              />

              <!-- TEAM -->
              <profile-input v-model="rhb.team" :label="$t('team')" :editMode="computedEditMode" />
            </el-col>
          </el-row>
          <el-row :gutter="20" v-if="computedEditMode">
            <el-col style="width: 140px">
              <!-- AKTIV/INAKTIV -->
              <profile-switch
                :title="$t('src.components.rhb.profile.aktiv')"
                :name="$t('src.components.rhb.profile.aktiv')"
                v-model="rhb.active"
                :active-text="$t('src.components.rhb.profile.ja')"
                :inactive-text="$t('src.components.rhb.profile.nein')"
                colorizeActive
              />
            </el-col>
          </el-row>
          <hr />

          <!-- ALLGEMEIN -->
          <div class="n-profile-section-font n-profile-section">{{ $t("src.components.rhb.profile.allgemein") }}</div>
          <el-row class="n-profile-spacer" :gutter="20">
            <el-col style="width: 140px">
              <profile-switch
                :title="$t('src.components.rhb.profile.dispoRelevant')"
                :name="$t('src.components.rhb.profile.dispoRelevant')"
                :disabled="!computedEditMode"
                v-model="rhb.projectRelevant"
                :active-text="$t('src.components.rhb.profile.ja')"
                :inactive-text="$t('src.components.rhb.profile.nein')"
              />
            </el-col>
            <el-col :span="4">
              <profile-input
                v-model="rhb.description"
                :label="$t('src.components.rhb.profile.beschreibung')"
                :name="$t('src.components.rhb.profile.beschreibung')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :span="4">
              <profile-input
                v-model="rhb.partNumber"
                :label="$t('src.components.rhb.profile.teilenummer')"
                :name="$t('src.components.rhb.profile.teilenummer')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :span="4">
              <profile-input
                v-model="rhb.serialNumber"
                :label="$t('src.components.rhb.profile.seriennummer')"
                :editMode="computedEditMode"
              />
            </el-col>
          </el-row>

          <hr />

          <!-- MAßE -->
          <div class="n-profile-section-font n-profile-section">{{ $t("src.components.rhb.profile.mae") }}</div>
          <el-row class="n-profile-spacer" :gutter="20">
            <el-col :span="4">
              <profile-input
                v-model="rhb.length"
                :label="$t('src.components.rhb.profile.lnge')"
                :name="$t('src.components.rhb.profile.lnge')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :span="4">
              <profile-input
                v-model="rhb.width"
                :label="$t('src.components.rhb.profile.breite')"
                :name="$t('src.components.rhb.profile.breite')"
                :editMode="computedEditMode"
              />
            </el-col>
            <el-col :span="4">
              <profile-input
                v-model="rhb.height"
                :label="$t('src.components.rhb.profile.hhe')"
                :editMode="computedEditMode"
              />
            </el-col>
          </el-row>

          <hr />

          <!-- GEWICHT -->
          <div class="n-profile-section-font n-profile-section">{{ $t("src.components.rhb.profile.gewicht") }}</div>
          <el-row class="n-profile-spacer">
            <el-col :span="4">
              <profile-input
                v-model="rhb.weight"
                :label="$t('src.components.rhb.profile.gewicht')"
                :editMode="computedEditMode"
              />
            </el-col>
          </el-row>
          <hr />
          <date-history
            :isEditMode="computedEditMode"
            :dateHistory="rhb.dateHistory"
            :dateOfEntering="rhb.dateOfEntering"
            :dateOfLeaving="rhb.dateOfLeaving"
            resourceType="rhb"
            :resourceId="rhb.id"
            @setProfileData="setProfileData"
            @saveDateHistory="saveDateHistory"
          />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapActions } from "vuex";
import { Message, RadioGroup, RadioButton, Switch, ColorPicker } from "element-ui";
import { Avatar, Collapse, CollapseItem, Keyfigures } from "src/components/UIComponents";
import DateHistory from "../Generic/DateHistory.vue";

export default {
  name: "rhb-profile",
  props: {
    rhb: Object,
    tabName: String,
    accessRights: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    updateProfile: {
      type: Function,
      required: true,
    },
  },
  components: {
    Message,
    DateHistory,
    Collapse,
    CollapseItem,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [Switch.name]: Switch,
    [Keyfigures.name]: Keyfigures,
    [ColorPicker.name]: ColorPicker,
  },
  data() {
    return {
      predefinedColors: [
        "#581713",
        "#a94b43",
        "#fbd283",
        "#62733f",
        "#034f73",
        "#96e3ff",
        "#255385",
        "#09111e",
        "#181238",
        "#d9d6cd",
        "#3d3936",
        "#837e7a",
        "#8d8d95",
        "#b5c4d7",
        "#dcba9f",
        "#d2cdca",
        "#fee0a0",
        "#84a0b5",
        "#bddc79",
        "#0ed145",
      ],
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      selectedServiceSpectrum: "",
      options: {
        serviceSpectrum: [],
      },
    };
  },
  watch: {
    "$props.rhb": {
      handler: function (val, oldVal) {
        this.checkDirty(val);
      },
      deep: true,
    },
  },
  mounted() {
    //settins changed
    this.$root.$on("settingsChanged", (data) => {
      if (data.modelType === "global") {
        //operation = "deleted" may remove the assignement
        if (data.operation === "deleted") {
          switch (data.modelID) {
            case "serviceSpectrum":
              this.deleteSettings(data.options);
              break;
          }
        }
        //operation = "loaded", "updated" and "created" just updates the options select
        this.options[data.modelID] = data.options;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
  },
  methods: {
    ...mapActions("dirtyFlag", { checkDirty: "checkDirty" }),
    setProfileData(data) {
      this.$emit("setProfileData", data);
    },
    saveDateHistory(data) {
      this.$emit("savePartialProfileData", data);
    },
    copy() {
      this.$emit("copyProfileCicked", this.rhb);
    },
    setPicture(pictureSettings) {
      this.rhb.picture = pictureSettings;
    },
    deleteSettings(options) {
      //synchonize allready assigned ids
      if (this.rhb.selectedServiceSpectrumList) {
        let updateFlag = false;

        for (let index = 0; index < this.rhb.selectedServiceSpectrumList.length; index++) {
          let test = options.filter((option) => option.id === this.rhb.selectedServiceSpectrumList[index]);

          if (test.length == 0) {
            this.rhb.selectedServiceSpectrumList.splice(index); // remove this ID
            index -= 1; //correct index of for loop
            updateFlag = true;
          }
        }

        if (updateFlag) {
          this.updateProfile(this.rhb);
        }
      }
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile .el-badge__content.is-dot {
  padding: 0px 0px 0px 0px; /* padding: top right bottom left */
}

.n-grid-container-view {
  display: grid;
  grid-template-columns: 0px 100px 200px 100px;
  grid-template-areas:
    "avatar avatar content content"
    "avatar avatar content content";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
  text-align: left;
}

.n-grid-container-view-avatar {
  grid-area: avatar;
}
.n-grid-container-view-content {
  grid-area: content;
}

.n-grid-container-edit {
  display: grid;
  grid-template-columns: 0px 100px 100px 100px;
  grid-template-areas:
    "avatar avatar firstname firstname"
    "avatar avatar state empty";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
}

.n-grid-container-edit > div {
  text-align: left;
}

.n-grid-container-edit-avatar {
  grid-area: avatar;
}
.n-grid-container-edit-firstname {
  grid-area: firstname;
}

.n-grid-container-edit-state {
  margin-top: 20px;
  grid-area: state;
}
</style>
