import {
  Scheduler,
  ResourceModel,
  EventModel,
  LocaleManager,
  DatePicker,
  DateHelper,
  StringHelper,
} from "@bryntum/scheduler";
import de from "./custom.locale.De";
import { moment } from "src/config/moment";
import { GranularityModeWidget } from "./GranularityModeWidget";

export const PROJECT_REORDER_EVENT = "projectreorder";

new GranularityModeWidget();

LocaleManager.locale = de;
const today = moment();

const checklistWidget = {
  type: "checklist_widget",
  id: `checklist`,
  onSelect: (value) => {
    window.postMessage({ type: "CHECKLIST_ITEM_CHANGED", payload: value });
  },
};

export const timeControls = {
  type: "buttongroup",
  id: "time-controls",
  items: [
    {
      type: "button",
      icon: "b-fa-angle-left",
      tooltip: { html: "Vorherige Woche anzeigen", newInstance: true },
      onAction() {
        if (window.resourceScheduler) {
          window.resourceScheduler.shift(-1, "week");
        } else if (window.projectScheduler) {
          window.projectScheduler.shift(-1, "week");
        }
      },
    },
    {
      type: "button",
      ref: "todayButton",
      text: "Heute",
      tooltip: { html: "Heute ansehen", newInstance: true },
      onAction() {
        const today = moment();
        let source;
        if (window.resourceScheduler) {
          source = window.resourceScheduler;
        } else if (window.projectScheduler) {
          source = window.projectScheduler;
        }
        const viewPreset = source.viewPreset;
        const addWeeks = viewPreset.data.shiftIncrement - 1;
        const startDate = moment(today).startOf("week");
        const end = startDate.clone().add(addWeeks, "weeks").endOf("week").toDate();
        const start = startDate.toDate();
        source.zoomToLevel(viewPreset.id, {
          startDate: start,
          endDate: end,
        });
        source.setTimeSpan(start, end, { visibleDate: today.toDate() });
      },
    },
    {
      type: "button",
      ref: "dayPicker",
      icon: "b-fa-calendar-alt",
      tooltip: { html: "Navigieren nach", newInstance: true },
      onAction() {
        let source;
        if (window.resourceScheduler) {
          source = window.resourceScheduler;
        } else if (window.projectScheduler) {
          source = window.projectScheduler;
        }
        const datePicker = new DatePicker({
          id: "navigate-date-picker",
          // appendTo: "time-controls",
          appendTo: "scheduler-container",
          editMonth: false,
          date: source.startDate,
          style: "position: fixed; top: 50px; left: 0; z-index: 9999; box-shadow: 0 2px 5px 2px rgb(0 0 0 / 25%);",
          showWeekColumn: true,
          onSelectionChange: ({ selection, source: datePickerComponent }) => {
            const selectedStartDate = DateHelper.floor(selection[0], { unit: "week", magnitude: 1 });
            source.setStartDate(selectedStartDate);
            datePickerComponent.destroy();
          },
        });
        const clickOutsideListener = (event) => {
          event.stopPropagation();
          if (!event.target.closest("#navigate-date-picker")) {
            datePicker.destroy();
            document.removeEventListener("click", clickOutsideListener);
          }
        };
        document.addEventListener("click", clickOutsideListener);
      },
    },
    {
      type: "button",
      icon: "b-fa-angle-right",
      tooltip: { html: "Nächste Woche ansehen", newInstance: true },
      onAction() {
        if (window.resourceScheduler) {
          window.resourceScheduler.shiftNext(1, "week");
        } else if (window.projectScheduler) {
          window.projectScheduler.shift(1, "week");
        }
      },
    },
  ],
};
export const zoomControls = {
  type: "buttongroup",
  items: [
    {
      type: "button",
      ref: "zoomInButton",
      icon: "b-icon-search-plus",
      tooltip: { html: "Hineinzoomen", newInstance: true },
      disabled: true, // initially
      onClick() {
        let source;
        if (window.resourceScheduler) {
          source = window.resourceScheduler;
        } else if (window.projectScheduler) {
          source = window.projectScheduler;
        }
        const viewPreset = source.viewPreset;
        const nextPreset = source.presets.records[viewPreset.parentIndex - 1];
        if (!nextPreset.data) {
          console.warn("Could not zoom in. Check details below!");
          console.log("viewPreset", viewPreset);
          console.log("viewPreset.parentIndex", viewPreset.parentIndex);
          return;
        }
        const addWeeks = nextPreset.data.shiftIncrement - 1;
        const startDate = moment(source.startDate).startOf("week");
        const endDate = startDate.clone().add(addWeeks, "weeks").endOf("week").toDate();
        const projectCalendar = document.getElementById("project-calendar");
        const classList = projectCalendar && projectCalendar.classList;
        if (classList) {
          if (nextPreset.data.shiftIncrement < 2) {
            classList.remove("status-cells-hide");
            classList.remove("status-cells-small");
          } else if (nextPreset.data.shiftIncrement === 2) {
            classList.remove("status-cells-hide");
            classList.add("status-cells-small");
          } else {
            classList.add("status-cells-hide");
            classList.remove("status-cells-small");
          }
        }
        source.zoomToLevel(nextPreset.id, {
          startDate: startDate.toDate(),
          endDate: endDate.toString(),
        });
      },
    },
    {
      type: "button",
      ref: "zoomOutButton",
      icon: "b-icon-search-minus",
      tooltip: { html: "Rauszoomen", newInstance: true },
      onClick() {
        let source;
        if (window.resourceScheduler) {
          source = window.resourceScheduler;
        } else if (window.projectScheduler) {
          source = window.projectScheduler;
        }
        const viewPreset = source.viewPreset;
        const nextPreset = source.presets.records[viewPreset.parentIndex + 1];
        if (!nextPreset.data) {
          console.warn("Could not zoom out. Check details below!");
          console.log("viewPreset", viewPreset);
          console.log("viewPreset.parentIndex", viewPreset.parentIndex);
          return;
        }
        const addWeeks = nextPreset.data.shiftIncrement - 1;
        const startDate = moment(source.startDate).startOf("week");
        const endDate = startDate.clone().add(addWeeks, "weeks").endOf("week").toDate();
        const projectCalendar = document.getElementById("project-calendar");
        const classList = projectCalendar && projectCalendar.classList;
        if (classList) {
          if (nextPreset.data.shiftIncrement > 2) {
            classList.add("status-cells-hide");
            classList.remove("status-cells-small");
          } else {
            classList.remove("status-cells-hide");
            classList.add("status-cells-small");
          }
        }
        source.zoomToLevel(nextPreset.id, {
          startDate: startDate.toDate(),
          endDate: endDate.toString(),
        });
      },
    },
  ],
};
const busyDaysFilter = {
  type: "buttonGroup",
  id: "busyDaysFilter",
  hidden: true,
  items: [
    {
      type: "button",
      text: "Mo - Fr",
      onClick(e) {
        const button = e.source.currentElement;
        if (button.classList.contains("b-pressed")) {
          window.filterUpperCalendarResourcesDays = undefined;
          window.resourceScheduler.resourceStore.removeFilter("available_days_filter");
          button.classList.remove("b-pressed");
        } else {
          button.classList.add("b-pressed");
          window.resourceScheduler.trigger("filter_busy_resources", { days: 5 });
          const siblings = Array.prototype.filter.call(button.parentNode.children, (child) => child !== button);
          siblings.forEach((node) => node.classList.remove("b-pressed"));
        }
      },
    },
    {
      type: "button",
      text: "Mo - Sa",
      onClick(e) {
        const button = e.source.currentElement;
        if (button.classList.contains("b-pressed")) {
          window.filterUpperCalendarResourcesDays = undefined;
          window.resourceScheduler.resourceStore.removeFilter("available_days_filter");
          button.classList.remove("b-pressed");
        } else {
          button.classList.add("b-pressed");
          window.resourceScheduler.trigger("filter_busy_resources", { days: 6 });
          const siblings = Array.prototype.filter.call(button.parentNode.children, (child) => child !== button);
          siblings.forEach((node) => node.classList.remove("b-pressed"));
        }
      },
    },
    {
      type: "button",
      text: "Mo - So",
      onClick(e) {
        const button = e.source.currentElement;
        if (button.classList.contains("b-pressed")) {
          window.filterUpperCalendarResourcesDays = undefined;
          window.resourceScheduler.resourceStore.removeFilter("available_days_filter");
          button.classList.remove("b-pressed");
        } else {
          button.classList.add("b-pressed");
          window.resourceScheduler.trigger("filter_busy_resources", { days: 7 });
          const siblings = Array.prototype.filter.call(button.parentNode.children, (child) => child !== button);
          siblings.forEach((node) => node.classList.remove("b-pressed"));
        }
      },
    },
  ],
};

const employeeSearchBar = {
  type: "textfield",
  icon: "b-fa b-fa-plus",
  id: "searchbar",
  keyStrokeChangeDelay: 300,
  placeholder: "Suche nach name und position",
  width: 220,
  clearable: true,
  onAction({ value, oldValue }) {
    if (value) {
      const andComparator = value.split("+").map((item) => item.trim());
      if (andComparator.length > 1) {
        window.resourceScheduler.resourceStore.filter({
          id: "search_bar",
          filterBy: (item) => {
            const name = item.name.toLowerCase();
            // const team = String(item.team).toLowerCase();
            return andComparator.every((term) => name.indexOf(term) !== -1);
          },
        });
      } else {
        const values = value
          .toLowerCase()
          .split(" ")
          .filter((item) => item !== "");
        window.resourceScheduler.resourceStore.filter({
          id: "search_bar",
          filterBy: (item) => {
            return values.some(
              (q) =>
                item.name.toLowerCase().indexOf(q) !== -1 ||
                String(item.position).toLowerCase().indexOf(q) !== -1 ||
                String(item.team).toLowerCase().indexOf(q) !== -1
            );
          },
        });
      }
    } else {
      window.resourceScheduler.resourceStore.removeFilter("search_bar");
    }
  },
};
const resourcesSearchBar = {
  type: "textfield",
  id: "searchbar",
  keyStrokeChangeDelay: 300,
  placeholder: "Suche nach name",
  width: 190,
  clearable: true,
  onAction({ value, oldValue }) {
    if (value) {
      const andComparator = value.split("+").map((item) => item.trim());
      if (andComparator.length > 1) {
        window.resourceScheduler.resourceStore.filter({
          id: "search_bar",
          filterBy: (item) => {
            const name = item.name.toLowerCase();
            return andComparator.every((term) => name.indexOf(term) !== -1);
          },
        });
      } else {
        const values = value
          .toLowerCase()
          .split(" ")
          .filter((item) => item !== "");

        window.resourceScheduler.resourceStore.filter({
          id: "search_bar",
          filterBy: (item) => {
            return values.some(
              (q) => item.name.toLowerCase().indexOf(q) !== -1 || String(item.team).toLowerCase().indexOf(q) !== -1
            );
          },
        });
      }
    } else {
      window.resourceScheduler.resourceStore.removeFilter("search_bar");
    }
  },
};
const availableResourceToggler = {
  type: "slidetoggle",
  text: "Nicht verfügbar ausblenden",
  inline: true,
  value: true,
  id: "unavailableToggle",
  onAction({ value }) {
    window.postMessage({ type: "UNAVAILABLE_FILTER", payload: value });
  },
};

const makeTeamSwitcher = (resourceType) => ({
  type: "slidetoggle",
  text: "Show teams",
  inline: true,
  value: Boolean(localStorage.getItem(`SHOW_TEAMS_${resourceType}`)),
  id: `showTeams_${resourceType}`,
  onAction({ value }) {
    window.postMessage({ type: "SHOW_TEAMS", payload: { value, resourceType } });
  },
});

const productGroupSelectors = [
  {
    type: "combo",
    ref: "pg1",
    label: "Warengruppe 1",
    style: "margin-top: 0",
    editable: false,
    clearable: true,
    items: [], // injectable for each resource individually
    onSelect({ record }) {
      if (record) {
        window.resourceScheduler.resourceStore.filter({
          id: "pg1_filter",
          filterBy: (item) => item.productGroup1 === record.id,
        });
        window.postMessage({ type: "PG1_SELECTED", payload: { pg1: record.id } });
      } else {
        window.resourceScheduler.resourceStore.removeFilter("pg1_filter");
        window.resourceScheduler.resourceStore.removeFilter("pg2_filter");
        window.postMessage({ type: "PG1_DESELECTED" });
      }
    },
  },
  {
    type: "combo",
    ref: "pg2",
    label: "Warengruppe 2",
    style: "margin-top: 0",
    editable: false,
    clearable: true,
    items: [], // injectable for each resource individually
    onSelect({ record }) {
      if (record) {
        window.resourceScheduler.resourceStore.filter({
          id: "pg2_filter",
          filterBy: (item) => item.productGroup2 === record.id,
        });
      } else {
        window.resourceScheduler.resourceStore.removeFilter("pg2_filter");
      }
    },
  },
];

/** Detaches project calendar from current view: destroy on current window and open in in new window */
function detachProjectCalendar() {
  if (window.projectCalendarRef == null || window.projectCalendarRef.closed) {
    window.postMessage({ type: "DETACH_PROJECT_CALENDAR" }, window.location.origin);
  } else {
    window.projectCalendarRef.focus();
  }
}

function collapseProjectsInCalendar() {
  window.postMessage({ type: "COLLAPSE_PROJECT_CALENDAR" }, window.location.origin);
}

function expandProjectsInCalendar() {
  window.postMessage({ type: "EXPAND_PROJECT_CALENDAR" }, window.location.origin);
}

export const baseConfig = {
  snap: true,
  transitionDuration: 300,
  weekStartDay: 1,
  startDate: today.clone().startOf("week").toJSON(),
  endDate: today.clone().endOf("week").toJSON(),
  minHeight: "20em",
  flex: "1 1 50%",
  rowHeight: 25,
  barMargin: 2,
  eventStyle: "plain",
  eventColor: "green",
  zoomOnMouseWheel: false,
  overlappingEventSorter(a, b) {
    const startA = a.startDate,
      endA = a.endDate;
    const startB = b.startDate,
      endB = b.endDate;
    const eventsOverlap = startA < endB && startB < endA;
    if (!eventsOverlap) {
      return 0;
    }
    if (a.order && b.order) {
      return a.order < b.order ? -1 : 1;
    }
    return a.name < b.name ? -1 : 1;
  },
  features: {
    // regionResize: false,
    // stickyEvents: true,
    columnAutoWidth: true,
    rowCopyPaste: false,
    eventCopyPaste: false,
    timeAxisHeaderMenu: false,
    scheduleTooltip: false,
    eventTooltip: false,
    timeRanges: {
      showCurrentTimeLine: true,
      showHeaderElements: true,
    },
    resourceTimeRanges: true,
    filter: true,
    cellEdit: false,
    cellMenu: {
      items: {
        removeRow: false,
      },
    },
    eventMenu: {},
    eventDrag: {
      // constrainDragToTimeline: false
    },
    eventDragSelect: true,
    eventDragCreate: true,
  },
  presets: [
    {
      id: "week_1",
      name: "Woche_1",
      forceFit: true,
      shiftIncrement: 1,
      base: "weekAndDay",
      shiftUnit: "week",
      displayDateFormat: "ddd ll",
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          dateFormat: "MMMM YYYY {[KW}WW{]}",
        },
        {
          unit: "day",
          dateFormat: "ddd D MMM",
        },
      ],
    },
    {
      id: "week_2",
      name: "Woche_2",
      base: "weekAndDay",
      forceFit: true,
      shiftIncrement: 2,
      shiftUnit: "week",
      displayDateFormat: "ddd ll",
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          dateFormat: "MMMM YYYY {[KW}WW{]}",
        },
        {
          unit: "day",
          dateFormat: "ddd D MMM",
        },
      ],
    },
    {
      id: "week_3",
      name: "Woche_3",
      base: "weekAndDay",
      forceFit: true,
      shiftIncrement: 3,
      shiftUnit: "week",
      displayDateFormat: "ddd ll",
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          dateFormat: "MMMM YYYY {[KW}WW{]}",
        },
        {
          unit: "day",
          dateFormat: "ddd D MMM",
        },
      ],
    },
    {
      id: "week_4",
      name: "Woche_4",
      base: "weekAndDay",
      forceFit: true,
      shiftIncrement: 4,
      shiftUnit: "week",
      displayDateFormat: "ddd ll",
      timeResolution: {
        unit: "day",
        increment: 1,
      },
      headers: [
        {
          unit: "week",
          dateFormat: "MMMM YYYY {[KW}WW{]}",
        },
        {
          unit: "day",
          dateFormat: "ddd D MMM",
        },
      ],
    },
  ],
  viewPreset: "week_1",
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: false,
      field: "name",
      text: "Name",
      // width: 200,
      // autoWidth: true,
      // fitMode: "value",
    },
  ],
  tbar: [timeControls, zoomControls, "->", busyDaysFilter],
  listeners: {
    renderRow({ source, row, record, recordIndex }) {},
    presetChange({ from, to, source, endDate, startDate }) {
      const { zoomInButton, zoomOutButton } = source.widgetMap;
      if (zoomInButton) zoomInButton.disabled = to.id === "week_1";
      if (zoomOutButton) zoomOutButton.disabled = to.id === "week_4";
    },
    // eventDrag: (props) => {
    //   console.log("eventDrag props", props);
    // },
  },
  crudManager: {
    eventStore: {
      reapplyFilterOnUpdate: true,
      reapplyFilterOnAdd: true,
    },
    resourceStore: {
      reapplyFilterOnUpdate: true,
      reapplyFilterOnAdd: true,
    },
  },
  enableDeleteKey: false,
  enableUndoRedoKeys: false,
  eventRenderer({ eventRecord, renderData }) {
    let endMarkers = "";
    // if (eventRecord.hasFiles) {
    //   renderData.children.push({ tag: "i", class: "b-fa-paperclip b-fa b-icon icon-white" });
    // }
    // if (eventRecord.freeText) {
    //   renderData.children.push({ tag: "i", class: "b-fa-file-lines b-fa b-icon icon-white" });
    // }
    let dateString = "";
    let quantity = "";
    if (eventRecord.quantity) {
      quantity = ` (${eventRecord.quantity}${eventRecord.unitName ? ` ${eventRecord.unitName}` : ""})`;
    }
    if (Math.ceil(moment(eventRecord.endDate).diff(eventRecord.startDate, "day", true)) > 1) {
      const startDate = moment(eventRecord.startDate);
      const endDate = moment(eventRecord.endDate);
      dateString = ` (${startDate.format("L")}-${endDate.format("L")}) `;
    }
    if (eventRecord.hasFiles) {
      endMarkers += `<i class="b-fa-paperclip b-fa b-icon icon-white ml-1" role="presentation"></i>`;
    }
    if (eventRecord.freeText) {
      endMarkers += `<i class="b-fa-file-lines b-fa b-icon icon-white ml-1" role="presentation"></i>`;
    }
    return StringHelper.encodeHtml(eventRecord.name + quantity + dateString) + endMarkers;
  },
};
export const employeeConfig = {
  ...baseConfig,
  tbar: [
    timeControls,
    zoomControls,
    checklistWidget,
    employeeSearchBar,
    availableResourceToggler,
    makeTeamSwitcher("employee"),
    "->",
    {
      type: "granularity_widget",
      id: "granularity_widget",
      resourceType: "employee",
      onChange: (value) => window.postMessage({ type: "GRANULARITY_CHANGED", payload: value }),
    },
    busyDaysFilter,
  ],
  allowOverlap: false,
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: true,
      draggable: false,
      field: "name",
      // width: 200,
      // autoWidth: true,
      fitMode: "exact",
      text: "Mitarbeiter",
      htmlEncode: false,
      renderer: ({ cellElement, value, record, showMeta, column }) => {
        let addEventButton = "";
        // if (record.events.length) {
        addEventButton = `<button class="_add-event show-overlapping" data-resourceid="${record.id}">+</button>`;
        // }
        let resourceImage = "";
        if (column.showImage) {
          if (record.thumbnail) {
            resourceImage = ` <auth-img draggable="false" loading="lazy" class="resource-avatar b-resource-image _resource-thumbnail" alt="${value}" src="${record.thumbnail}" data-picture="${record.picture}" role="presentation"></auth-img>`;
          } else {
            const splited = value
              .split(" ")
              .slice(0, 2)
              .map((i) => i[0]);
            const initials = splited.join("").toLocaleUpperCase();
            resourceImage = `<div class="resource-avatar b-resource-initials" role="presentation">${initials}</div>`;
          }
        }

        return `
        <div class="b-resource-info">
        ${resourceImage}
          <dl>
            <dt>
            <a href="${
              "/employee/profile/view/" + record.id
            }" data-resourcetype="employee" class="_resource-link">${value}</a>
            </dt>
            <dd class="b-resource-role" role="presentation">${column.showMeta ? column.showMeta(record) : ""}</dd>
            </dl>
            ${addEventButton}
        </div>`;
      },
    },
    // {
    //   field: "position",
    //   text: "Position",
    // },
  ],
};

export const machineConfig = {
  ...baseConfig,
  allowOverlap: false,
  tbar: [
    timeControls,
    zoomControls,
    checklistWidget,
    resourcesSearchBar,
    ...productGroupSelectors,
    availableResourceToggler,
    makeTeamSwitcher("machine"),
    "->",
    {
      type: "granularity_widget",
      id: "granularity_widget",
      resourceType: "machine",
      onChange: (value) => window.postMessage({ type: "GRANULARITY_CHANGED", payload: value }),
    },
    busyDaysFilter,
  ],
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: true,
      // width: 200,
      // autoWidth: true,
      // fitMode: "value",
      field: "name",
      text: "Maschinen",
      htmlEncode: false,
      renderer: ({ cellElement, value, record, column }) => {
        let addEventButton = "";
        // if (record.events.length) {
        addEventButton = `<button class="_add-event show-overlapping" data-resourceid="${record.id}">+</button>`;
        // }
        let resourceImage = "";
        if (column.showImage) {
          if (record.thumbnail) {
            resourceImage = ` <auth-img draggable="false" loading="lazy" class="resource-avatar b-resource-image _resource-thumbnail" alt="${value}" src="${record.thumbnail}" data-picture="${record.picture}" role="presentation"></auth-img>`;
          } else {
            const splited = value
              .split(" ")
              .slice(0, 2)
              .map((i) => i[0]);
            const initials = splited.join("").toLocaleUpperCase();
            resourceImage = `<div class="resource-avatar b-resource-initials" role="presentation">${initials}</div>`;
          }
        }

        return `
        <div class="b-resource-info">
        ${resourceImage}
          <dl>
            <dt><a href="${
              "/machine/profile/view/" + record.id
            }" data-resourcetype="machine" class="_resource-link">${value}</a></dt>
            <dd class="b-resource-role" role="presentation">${column.showMeta ? column.showMeta(record) : ""}</dd>
          </dl>
          ${addEventButton}
        </div>`;
      },
    },
  ],
};

export const vehicleConfig = {
  ...baseConfig,
  allowOverlap: false,
  tbar: [
    timeControls,
    zoomControls,
    checklistWidget,
    resourcesSearchBar,
    ...productGroupSelectors,
    availableResourceToggler,
    makeTeamSwitcher("vehicle"),
    "->",
    {
      type: "granularity_widget",
      id: "granularity_widget",
      resourceType: "vehicle",
      onChange: (value) => window.postMessage({ type: "GRANULARITY_CHANGED", payload: value }),
    },
    busyDaysFilter,
  ],
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: true,
      draggable: false,
      // width: 200,
      // autoWidth: true,
      // fitMode: "value",
      field: "name",
      text: "KFZ",
      htmlEncode: false,
      renderer: ({ cellElement, value, record, column }) => {
        let addEventButton = "";
        // if (record.events.length) {
        addEventButton = `<button class="_add-event show-overlapping" data-resourceid="${record.id}">+</button>`;
        // }
        let resourceImage = "";
        if (column.showImage) {
          if (record.thumbnail) {
            resourceImage = ` <auth-img draggable="false" loading="lazy" class="resource-avatar b-resource-image _resource-thumbnail" alt="${value}" src="${record.thumbnail}" data-picture="${record.picture}" role="presentation"></auth-img>`;
          } else {
            const splited = value
              .split(" ")
              .slice(0, 2)
              .map((i) => i[0]);
            const initials = splited.join("").toLocaleUpperCase();
            resourceImage = `<div class="resource-avatar b-resource-initials" role="presentation">${initials}</div>`;
          }
        }

        return `
        <div class="b-resource-info">
        ${resourceImage}
          <dl>
            <dt><a href="${
              "/vehicle/profile/view/" + record.id
            }" data-resourcetype="vehicle" class="_resource-link">${value}</a></dt>
            <dd class="b-resource-role" role="presentation">${column.showMeta ? column.showMeta(record) : ""}</dd>
          </dl>
          ${addEventButton}
        </div>`;
      },
    },
  ],
};

export const rhbConfig = {
  ...baseConfig,
  allowOverlap: false,
  tbar: [
    timeControls,
    zoomControls,
    checklistWidget,
    resourcesSearchBar,
    ...productGroupSelectors,
    availableResourceToggler,
    makeTeamSwitcher("rhb"),
    "->",
    {
      type: "granularity_widget",
      id: "granularity_widget",
      resourceType: "rhb",
      onChange: (value) => window.postMessage({ type: "GRANULARITY_CHANGED", payload: value }),
    },
    busyDaysFilter,
  ],
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: true,
      // width: 200,
      // autoWidth: true,
      // fitMode: "value",
      field: "name",
      text: "RHB",
      htmlEncode: false,
      renderer: ({ cellElement, value, record, column }) => {
        let addEventButton = "";
        // if (record.events.length) {
        addEventButton = `<button class="_add-event show-overlapping" data-resourceid="${record.id}">+</button>`;
        // }
        let resourceImage = "";
        if (column.showImage) {
          if (record.thumbnail) {
            resourceImage = ` <auth-img draggable="false" loading="lazy" class="resource-avatar b-resource-image _resource-thumbnail" alt="${value}" src="${record.thumbnail}" data-picture="${record.picture}" role="presentation"></auth-img>`;
          } else {
            const splited = value
              .split(" ")
              .slice(0, 2)
              .map((i) => i[0]);
            const initials = splited.join("").toLocaleUpperCase();
            resourceImage = `<div class="resource-avatar b-resource-initials" role="presentation">${initials}</div>`;
          }
        }

        return `
        <div class="b-resource-info">
        ${resourceImage}
          <dl>
            <dt><a href="${
              "/rhb/profile/view/" + record.id
            }" data-resourcetype="rhb" class="_resource-link">${value}</a></dt>
            <dd class="b-resource-role" role="presentation">${column.showMeta ? column.showMeta(record) : ""}</dd>
          </dl>
          ${addEventButton}
        </div>`;
      },
    },
  ],
};

export const supplyConfig = {
  ...baseConfig,
  features: {
    ...baseConfig.features,
  },
  allowOverlap: true,
  tbar: [
    timeControls,
    zoomControls,
    checklistWidget,
    resourcesSearchBar,
    ...productGroupSelectors,
    availableResourceToggler,
    makeTeamSwitcher("supply"),

    "->",
    // {
    //   type: "granularity_widget",
    //   id: "granularity_widget",
    //   resourceType: "supply",
    //   onChange: (value) => window.postMessage({ type: "GRANULARITY_CHANGED", payload: value }),
    // },
    busyDaysFilter,
  ],
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: true,
      // width: 200,
      // autoWidth: true,
      // fitMode: "value",
      field: "name",
      text: "Verbrauchsm.",
      htmlEncode: false,
      renderer: ({ cellElement, value, record, column }) => {
        let addEventButton = "";
        // if (record.events.length) {
        addEventButton = `<button class="_add-event" data-resourceid="${record.id}">+</button>`;
        // }
        let resourceImage = "";
        if (column.showImage) {
          if (record.thumbnail) {
            resourceImage = ` <auth-img draggable="false" loading="lazy" class="resource-avatar b-resource-image _resource-thumbnail" alt="${value}" src="${record.thumbnail}" data-picture="${record.picture}" role="presentation"></auth-img>`;
          } else {
            const splited = value
              .split(" ")
              .slice(0, 2)
              .map((i) => i[0]);
            const initials = splited.join("").toLocaleUpperCase();
            resourceImage = `<div class="resource-avatar b-resource-initials" role="presentation">${initials}</div>`;
          }
        }

        return `
        <div class="b-resource-info">
        ${resourceImage}
          <dl>
            <dt><a href="${
              "/supply/profile/view/" + record.id
            }" data-resourcetype="supply" class="_resource-link">${value}</a></dt>
            <dd class="b-resource-role" role="presentation">${column.showMeta ? column.showMeta(record) : ""}</dd>
          </dl>
          ${addEventButton}
        </div>`;
      },
    },
  ],
};

export const subcontractorConfig = {
  ...baseConfig,
  allowOverlap: true,
  tbar: [
    timeControls,
    zoomControls,
    resourcesSearchBar,
    availableResourceToggler,
    "->",
    // {
    //   type: "granularity_widget",
    //   id: "granularity_widget",
    //   resourceType: "subcontractor",
    //   onChange: (value) => window.postMessage({ type: "GRANULARITY_CHANGED", payload: value }),
    // },
    busyDaysFilter,
  ],
  columns: [
    {
      type: "resourceInfo",
      showEventCount: false,
      showImage: true,
      // width: 200,
      // autoWidth: true,
      // fitMode: "value",
      field: "name",
      text: "Nachuntern.",
      htmlEncode: false,
      renderer: ({ cellElement, value, record, column }) => {
        let addEventButton = "";
        // if (record.events.length) {
        addEventButton = `<button class="_add-event" data-resourceid="${record.id}">+</button>`;
        // }
        let resourceImage = "";
        if (column.showImage) {
          if (record.thumbnail) {
            resourceImage = ` <auth-img draggable="false" loading="lazy" class="resource-avatar b-resource-image _resource-thumbnail" alt="${value}" src="${record.thumbnail}" data-picture="${record.picture}" role="presentation"></auth-img>`;
          } else {
            const splited = value
              .split(" ")
              .slice(0, 2)
              .map((i) => i[0]);
            const initials = splited.join("").toLocaleUpperCase();
            resourceImage = `<div class="resource-avatar b-resource-initials" role="presentation">${initials}</div>`;
          }
        }

        return `
        <div class="b-resource-info">
        ${resourceImage}
          <dl>
            <dt><a href="${
              "/purchasing/subcontractor/profile/view/" + record.id
            }" data-resourcetype="subcontractor" class="_resource-link">${value}</a></dt>
            <dd class="b-resource-role" role="presentation">${column.showMeta ? column.showMeta(record) : ""}</dd>
          </dl>
          ${addEventButton}
        </div>`;
      },
    },
  ],
};

class ResourceType extends ResourceModel {
  static get fields() {
    return [
      {
        name: "active",
      },
      {
        name: "constructionManagers",
      },
      {
        name: "dateRange",
      },
      {
        name: "departures",
      },
      {
        name: "hotelReserveAlarm",
      },
      {
        name: "invoiceHistory",
      },
      {
        name: "isWorkshop",
      },
      {
        name: "progress",
      },
      {
        name: "resourceType",
      },
      {
        name: "selectedHotels",
      },
      {
        name: "unitName",
      },
      {
        name: "order",
      },
      {
        name: "name",
        dataSource: "title",
      },
      {
        name: "expanded",
        dataSource: "visible",
      },
    ];
  }
  // get cls() {
  //   return this.active ? '' : 'unavailable';
  // }
}
ResourceType.exposeProperties();

class ProjectEvent extends EventModel {
  static get fields() {
    return [
      "projectId",
      "resourceType",
      "resourceId",
      "type",
      "locked",
      "isMetaEvent",
      "projectStatusCell",
      "hotelStatusCell",
      "departureStatusCell",
      "detailedInfoCell",
      "projectStatusClass",
      "quantity",
      "unitName",
      "order",
      "comment",
    ];
  }

  get dragValidationText() {
    const { resource, resourceType } = this;

    let result = "";

    switch (resourceType) {
      case "employee":
        result = "Mitarbeiter können nur in der Mitarbeiterzeile abgelegt werden";
        break;
      case "machine":
        result = `Maschine kann nur in der Maschinenzeile abgelegt werden`;
        break;
      case "rhb":
        result = `RHB kann nur in der RHB-Zeile abgelegt werden`;
        break;
      case "vehicle":
        result = `KFZ kann nur in der KFZ-Zeile abgelegt werden`;
        break;
      case "supply":
        result = `Verbrauchsmaterial kann nur in der Verbrauchsmaterial-Zeile abgelegt werden`;
        break;
    }

    return result;
  }

  //   get resizeValidationText() {
  //     let result = '';

  //     switch (this.type) {
  //       case 'Golf':
  //         result = 'Golf game has always fixed duration';
  //         break;
  //       case 'Coding':
  //         result = 'Programming task duration cannot be shortened';
  //         break;
  //     }

  //     return result;
  //   }
}

export const projectConfig = {
  ...baseConfig,
  id: "project-calendar",
  rowHeight: 40,
  barMargin: 2,
  overlappingEventSorter(a, b) {
    // keeps sorting events by name after resize
    if (!a.dbEventId) {
      return 0;
    }
    if (a.startDate > b.endDate) {
      return 0;
    } else {
      return a.name < b.name ? -1 : 1;
    }
  },
  eventStyle: "plain",
  eventColor: "indigo",
  eventStore: {
    // Additional fields added to EventModel
    fields: ["locked"],
  },
  eventRenderer({ eventRecord, renderData }) {
    //NOTE: is set in BryntumScheduler.vue
  },
  features: {
    scheduleMenu: false,
    rowReorder: {
      listeners: {
        gridRowBeforeDragStart: function (event) {
          const context = event.context,
            record = this.grid.getRecordFromElement(context.element);
          return !record.isLeaf && !record.isWorkshop;
        },
        gridRowDrag: function ({ source, context }) {
          if (!context.parent.isRoot) {
            context.valid = false;
            return;
          }
          // not allowed to drop below Workshop project
          const insertBefore = context.insertBefore;
          const parentChildren = context.parent.children;
          let insertAfterIdx;
          if (insertBefore) {
            const insertBeforeIdx = parentChildren.findIndex((item) => item.id === insertBefore.id);
            insertAfterIdx = insertBeforeIdx - 1;
          } else {
            if (parentChildren[parentChildren.length - 1] && parentChildren[parentChildren.length - 1].isWorkshop) {
              context.valid = false;
              return;
            }
            insertAfterIdx = parentChildren.length - 2;
          }
          if (parentChildren[insertAfterIdx] && parentChildren[insertAfterIdx].isWorkshop) {
            context.valid = false;
            return;
          }
        },
        gridRowDrop: function ({ source, context }) {
          if (context.valid) {
            // if insertbefore is a project -> order = insertbefore.order - 1
            // else - find previous project and count order + 1 from that value
            const projectId = context.records[0].id;
            let newOrderValue;
            if (context.insertBefore && !context.insertBefore.isWorkshop) {
              newOrderValue = Math.max(1, context.insertBefore.order - 1);
            } else {
              const newPosition = context.parent.children.findIndex((item) => item.id === projectId);
              // because when moving record, it gets removed from the list and then putted back,
              // the order value should remain the same
              newOrderValue = context.parent.children[newPosition - 1].order;
            }
            const payload = { projectId, newOrderValue };
            window.projectScheduler.trigger(PROJECT_REORDER_EVENT, payload);
          }
        },
      },
      dropOnLeaf: false,
      showGrip: true,
      gripOnly: true,
      hoverExpandTimeout: 999999,
      dragHelperConfig: {
        listeners: {
          drag({ source, context, event }) {
            if (!context.parent.isRoot) {
              context.valid = false;
            }
          },
        },
        dropTargetSelector: "#project-calendar-lockedSubgrid",
        // isElementDraggable: (...args) => {
        //   console.log("isElementDraggable", args);
        //   return false;
        // },
      },
    },
    rowCopyPaste: false,
    eventCopyPaste: false,
    columnAutoWidth: true,
    // regionResize: false,
    cellEdit: false,
    cellMenu: {
      items: {
        removeRow: false,
      },
    },
    timeRanges: {
      showHeaderElements: true,
    },
    tree: true,
    resourceTimeRanges: true,
    eventMenu: {},
    scheduleMenu: false,
    eventDrag: {
      // constrainDragToTimeline: false
    },
    eventDragSelect: true,
    eventDragCreate: false,
  },
  listeners: {
    ...baseConfig.listeners,
    beforeEventEdit({ eventRecord }) {
      if (eventRecord.locked) {
        return false;
      }
    },
  },
  tbar: [
    {
      type: "button",
      ref: "detach",
      tooltip: { html: "Detach", newInstance: true },
      icon: "b-fa-window-restore",
      onAction: detachProjectCalendar,
    },
    {
      type: "button",
      ref: "minimize",
      tooltip: { html: "Alle einklappen", newInstance: true },
      icon: "b-fa-compress-arrows-alt",
      onAction: collapseProjectsInCalendar,
    },
    {
      type: "button",
      ref: "expand",
      tooltip: { html: "Alle ausklappen", newInstance: true },
      icon: "b-fa-expand-arrows-alt",
      onAction: expandProjectsInCalendar,
      hidden: true,
    },
    "->",
    {
      type: "buttongroup",
      toggleGroup: true,
      id: "active-inactive",
      items: [
        {
          type: "button",
          text: "Aktiv",
          onToggle({ pressed }) {
            if (pressed) {
              window.projectScheduler.resourceStore.filter({ id: "active", filterBy: (item) => item.active });
            }
          },
        },
        {
          type: "button",
          text: "Inaktiv",
          onToggle({ pressed }) {
            if (pressed) {
              window.projectScheduler.resourceStore.filter({ id: "active", filterBy: (item) => !item.active });
            }
          },
        },
        {
          type: "button",
          text: "Alle",
          pressed: true,
          onToggle({ pressed }) {
            if (pressed) {
              window.projectScheduler.resourceStore.removeFilter("active");
            }
          },
        },
      ],
    },
  ],
  columns: [
    {
      type: "tree",
      text: 'Projekt <i class="_project-add tooltip-icon b-fa b-fa-plus-circle" title="Projekt hinzufügen"></i>',
      // width: 270,
      // autoWidth: true,
      // fitMode: "value",
      field: "title",
      sortable: false,
      htmlEncode: false,
      htmlEncodeHeaderText: false,
      renderer: ({ value, record }) => {
        if (record.dateRange) {
          const dateRange = !record.isWorkshop && record.dateRange.map((date) => moment(date).format("L")).join(" - ");
          const dateRangeHTML = dateRange
            ? `<div style="font-weight: normal; font-size: 0.8em; display: inline-block;"> (${dateRange})</div>
            <div class="projectinfo-wrap" style="float: right;display: inline-block;"><div class="tooltip-icon"><i class="_project-info b-fa b-fa-info-circle" title="Info" data-resourceid="${record.id}" style="top:-5px; height: 0; position: relative;"></i> <i class="_copy-resources b-fa b-fa-copy" title="Copy resources" data-projectid="${record.id}" style="margin-left:5px; top:-5px; height: 0; position: relative;"></i></div></div>`
            : "";
          return `<div style="width: 100%;">
          <div style="margin-top:3px">
            <a href="${
              record.isWorkshop ? "" : `/project/profile/view/${record.id}`
            }" data-resourcetype="project" class="_resource-link">${value}</a>
          </div>
          ${dateRangeHTML}
          `;
        } else {
          return `<div style="width: 100%;">${value}<div class="montagsplanung-wrap" ><i class="_montagsplanung tooltip-icon b-fa b-fa-calendar-week" title="Planungsvorlage" data-resourceid="${record.id}"></i></div>
          </div >`;
        }
      },
    },
  ],
  crudManager: {
    autoLoad: false,
    eventStore: {
      modelClass: ProjectEvent,
      reapplyFilterOnUpdate: true,
      reapplyFilterOnAdd: true,
    },
    resourceStore: {
      modelClass: ResourceModel,
      reapplyFilterOnUpdate: true,
      reapplyFilterOnAdd: true,
    },
  },
};
