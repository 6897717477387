<template>
  <div>
    <!-- Create Booking dialog -->
    <el-dialog
      :title="$t('Add hotel booking')"
      width="700px"
      :visible="isVisible"
      center
      :show-close="false"
      @close="close"
    >
      <el-form label-width="120px" ref="form" :model="formData" :rules="formDataRules">
        <el-form-item label="Projektlaufzeit" class="mb-0">{{ formattedProjectDateRange }}</el-form-item>

        <el-form-item
          :label="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.wochentage')"
          prop="checkedWeekDays"
          class="mb-0"
        >
          <el-checkbox-group v-model="formData.checkedWeekDays">
            <el-checkbox :label="1" :disabled="formData.disabledWeekDays.includes(1)">Mo</el-checkbox>
            <el-checkbox :label="2" :disabled="formData.disabledWeekDays.includes(2)">Di</el-checkbox>
            <el-checkbox :label="3" :disabled="formData.disabledWeekDays.includes(3)">Mi</el-checkbox>
            <el-checkbox :label="4" :disabled="formData.disabledWeekDays.includes(4)">Do</el-checkbox>
            <el-checkbox :label="5" :disabled="formData.disabledWeekDays.includes(5)">Fr</el-checkbox>
            <el-checkbox :label="6" :disabled="formData.disabledWeekDays.includes(6)">Sa</el-checkbox>
            <el-checkbox :label="7" :disabled="formData.disabledWeekDays.includes(7)">So</el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <el-form-item prop="completeRemainingProject" class="mb-0">
          <el-checkbox v-model="formData.completeRemainingProject">{{
            $t("src.components.project.bryntumscheduler.projecteventcreateeditor.completeRemainingProject")
          }}</el-checkbox>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.abbrechen')"
            @click="close"
          />
          <save-button
            :text="$t('src.components.project.bryntumscheduler.projecteventcreateeditor.bernehmen')"
            @click="addHotelBookings"
          />
        </span>
      </template>
    </el-dialog>
    <!-- Edit Booking dialog -->
    <el-dialog
      title="Edit hotel reservation"
      width="500px"
      :visible="editVisible"
      center
      :show-close="false"
      @close="closeEdit"
      destroy-on-close
    >
      <el-form ref="editForm" :model="editFormData" :rules="editFormDataRules" v-loading="loading">
        <el-form-item label="Projektlaufzeit" class="mb-0">{{ formattedProjectDateRange }}</el-form-item>
        <el-form-item
          :label="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.starte')"
          prop="dateRange"
          style="width: 100%"
        >
          <pr-date-picker
            :focusDate="dayClicked"
            ref="datepicker"
            v-model="editFormData.dateRange"
            is-range
            :projectDateRange="projectDateRange"
          />
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.abbrechen')"
            @click="closeEdit"
          />
          <div>
            <delete-button @click.prevent="removeBooking" />
            <save-button
              :text="$t('src.components.project.bryntumscheduler.statuseventcreateeditor.bernehmen')"
              :loading="loading"
              @click="submitEdit"
            />
          </div>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Form, FormItem, Button, Message, Checkbox, CheckboxGroup, MessageBox } from "element-ui";
import { moment } from "src/config/moment";
import { getDateRangesFromArray } from "../../../utils/getDateRangesFromArray";

const defaultCheckedWeekdays = [1, 2, 3, 4, 5, 6, 7];

export default {
  name: "hotel-booking-dialog",
  components: {
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Button.name]: Button,
    [Message.name]: Message,
    [Checkbox.name]: Checkbox,
    [CheckboxGroup.name]: CheckboxGroup,
  },
  props: {
    currentMonday: Date,
    getBookingRecord: Function,
  },
  data() {
    const weekDaysValidator = (rule, value, callback) => {
      if (!this.formData.completeRemainingProject && !value.length) {
        callback(new Error("Bitte wählen Sie mindestens einen Tag aus"));
      }
      callback();
    };
    return {
      formDataRules: {
        checkedWeekDays: {
          validator: weekDaysValidator,
          trigger: "change",
        },
      },
      loading: false,
      isVisible: false,
      dayClicked: null,
      projectId: null,
      projectName: null,
      projectDateRange: null,
      formData: {
        completeRemainingProject: false,
        checkedWeekDays: [],
        disabledWeekDays: [],
      },
      existingEventsDateRanges: [],
      editVisible: false,
      bookingId: null,
      editFormData: {
        dateRange: null,
      },
      editFormDataRules: {
        dateRange: {
          required: true,
          message: "Bitte Datum auswählen",
          trigger: "change",
        },
      },
    };
  },
  methods: {
    /** Feature: when creating event on a day - check days between clicked day and Friday */
    setCheckedWeekDays() {
      const weekDay = this.dayClicked.weekday();
      let daysSelection = [];

      if (weekDay <= 4) {
        // 4 - index of Friday
        // +1 - to include last array item
        daysSelection = defaultCheckedWeekdays.slice(weekDay, 4 + 1);
      } else {
        // Only clicked day
        daysSelection = [weekDay + 1];
      }
      daysSelection = daysSelection.filter((weekDayNum) => {
        return !this.isDayOverlapExistingEvents(moment(this.dayClicked).isoWeekday(weekDayNum));
      });

      this.formData.checkedWeekDays = daysSelection;
    },
    isDayOverlapExistingEvents(day) {
      const result = this.existingEventsDateRanges.some((range) => range.contains(day));
      return result;
    },
    /** Shows dialog for creating red hotel cells */
    initCreateBooking({ project, clickedDate }) {
      this.existingEventsDateRanges = project.bookings.map((item) => moment.range(item.start, item.end).snapTo("day"));
      this.projectId = project.id;
      this.projectName = project.title;
      this.projectDateRange = project.dateRange.slice();
      this.isVisible = true;

      const momentDate = moment(clickedDate).startOf("day");
      this.dayClicked = momentDate;
      this.$nextTick(() => {
        this.setCheckedWeekDays();
      });

      this.$nextTick(() => {
        this.setCheckedWeekDays();
        this.setDisabledAndCheckedWeekdays();
      });
    },
    /** Shows dialog for editing red hotel cells */
    initEditBooking({ project, bookingId }) {
      const bookingRecord = this.$props.getBookingRecord(project.id, bookingId);
      this.editVisible = true;
      this.projectId = project.id;
      this.projectName = project.title;
      this.projectDateRange = project.dateRange.slice();

      this.bookingId = bookingRecord._id;
      this.editFormData.dateRange = [bookingRecord.start, bookingRecord.end];
    },
    closeEdit() {
      this.editVisible = false;
      this.projectId = null;
      this.bookingId = null;
      this.editFormData.dateRange = null;
      this.$refs.editForm.resetFields();
    },
    close() {
      this.isVisible = false;
      this.dayClicked = null;
      this.projectId = null;
      this.projectName = null;
      this.projectDateRange = null;
      this.existingEventsDateRanges = [];
    },
    setDisabledAndCheckedWeekdays() {
      const projectDateRange = moment.range(this.projectDateRange).snapTo("day");
      const mondayOfClickedDay = moment(this.dayClicked).isoWeekday(1);
      const weekDayDates = defaultCheckedWeekdays.map((weekDay) => this.getDateByWeekday(mondayOfClickedDay, weekDay));
      let disabledWeekDays;

      if (this.formData.completeRemainingProject) {
        disabledWeekDays = defaultCheckedWeekdays.slice();
      } else {
        // get disabled weekday numbers if date is not within project range
        disabledWeekDays = defaultCheckedWeekdays.reduce((arr, current, idx) => {
          if (!weekDayDates[idx].within(projectDateRange) || this.isDayOverlapExistingEvents(weekDayDates[idx])) {
            arr.push(current);
          }
          return arr;
        }, []);
      }
      this.formData.disabledWeekDays = disabledWeekDays;
      // uncheck disabled checkboxes if there are any
      let checkedWeekDays;
      if (this.formData.checkedWeekDays.length) {
        checkedWeekDays = this.formData.checkedWeekDays.slice();
      } else {
        const weekDay = this.dayClicked.weekday();
        let daysSelection = [];
        if (weekDay <= 4) {
          // 4 - index of Friday
          // +1 - to include last array item
          daysSelection = defaultCheckedWeekdays.slice(weekDay, 4 + 1);
        } else {
          // Only clicked day
          daysSelection = [weekDay + 1];
        }

        daysSelection = daysSelection.filter((weekDayNum) => {
          return !this.isDayOverlapExistingEvents(moment(this.dayClicked).isoWeekday(weekDayNum));
        });

        checkedWeekDays = daysSelection;
      }
      this.formData.checkedWeekDays = checkedWeekDays.filter((item) => !disabledWeekDays.includes(item));
    },
    getDateByWeekday(droppedDate, weekDayNumber) {
      let date = moment(droppedDate).isoWeekday(weekDayNumber);
      return date;
    },
    async addHotelBookings() {
      this.$refs.form.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            await this.handleCreateEvent(
              moment(this.dayClicked).isoWeekday(1),
              this.formData.checkedWeekDays,
              this.projectId,
              this.formData.completeRemainingProject
            );
          } catch (err) {
            if (err.response && err.response.data) {
              Message.error(err.response.data.message);
            }
            throw err;
          } finally {
            this.loading = false;
          }
        } else {
          return false;
        }
      });
    },
    async handleCreateEvent(dateClicked, weekdays, projectId, completeRemainingProject) {
      // Array of selected dates of new event
      const newDays = weekdays.sort().map((weekday) => this.getDateByWeekday(dateClicked, weekday));
      //get the assigned project
      let dateRanges;
      if (completeRemainingProject) {
        dateRanges = [[moment(dateClicked).startOf("day"), moment(this.projectDateRange[1]).endOf("day")]];
      } else {
        dateRanges = getDateRangesFromArray(newDays);
      }
      const eventPostObjects = dateRanges.map((item) => ({
        start: item[0].startOf("day"),
        end: item[1].endOf("day"),
        projectId: projectId,
      }));
      // # all checks passed #
      this.addBookingEvents(eventPostObjects);
    },
    async addBookingEvents(postObjects) {
      try {
        const promises = postObjects.map((formBody) => this.axios.post("/api/project-hotel-bookings", formBody));
        const results = await Promise.all(promises);

        this.$emit(
          "updateBooking",
          results.map((i) => i.data),
          "create"
        );
        this.close();
      } catch (error) {
        throw error;
      }
    },
    submitEdit() {
      this.$refs.editForm.validate(async (valid) => {
        if (valid) {
          try {
            this.loading = true;
            const formBody = {
              projectId: this.projectId,
              start: moment(this.editFormData.dateRange[0]).startOf("day"),
              end: moment(this.editFormData.dateRange[1]).endOf("day"),
            };
            const response = await this.axios.put(`/api/project-hotel-bookings/${this.bookingId}`, formBody);
            this.$emit("updateBooking", response.data, "edit");
            this.closeEdit();
          } catch (error) {
            throw error;
          } finally {
            this.loading = false;
          }
        }
      });
    },
    async removeBooking() {
      try {
        MessageBox.confirm("Do you want to delete hotel bookings for the rest of the project?", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          confirmButtonClass: "el-button-danger",
        })
          // deletes current and all next booking records for current project
          .then(async () => {
            try {
              await this.axios.delete(`/api/project-hotel-bookings/${this.bookingId}/and-next`);
              this.$emit("updateBooking", { _id: this.bookingId, projectId: this.projectId }, "delete_all");
            } catch (error) {
              Message.error(error.message);
              return error;
            }
          })
          .catch(async () => {
            try {
              // deletes only current booking record
              await this.axios.delete(`/api/project-hotel-bookings/${this.bookingId}`);
              this.$emit("updateBooking", { _id: this.bookingId, projectId: this.projectId }, "delete");
            } catch (error) {
              Message.error(error.message);
              return error;
            }
          })
          .finally(this.closeEdit);
      } catch (error) {
        throw error;
      }
    },
  },
  watch: {
    /**
     * completeRemainingProject = true - set date range till the end of the project
     * completeRemainingProject = false - pick days from current week
     */
    "formData.completeRemainingProject": function (newVal, oldVal) {
      if (newVal === true) {
        this.formData.checkedWeekDays = [];
        this.formData.disabledWeekDays = defaultCheckedWeekdays.slice();
      } else {
        this.formData.disabledWeekDays = [];

        this.setDisabledAndCheckedWeekdays();
      }
    },
  },
  computed: {
    formattedProjectDateRange() {
      return Array.isArray(this.projectDateRange)
        ? this.projectDateRange.map((i) => moment(i).format("L")).join(" - ")
        : "";
    },
  },
};
</script>
