<template>
  <div class="n-edit-wrapper">
    <el-row type="flex">
      <el-col :span="24">
        <el-breadcrumb class="n-view-breadcrumb" separator="/">
          <el-breadcrumb-item :to="{ path: '/' }">Management</el-breadcrumb-item>
          <el-breadcrumb-item>{{ $t("src.components.contacts.contacts") }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
    </el-row>
    <el-row type="flex">
      <el-col :span="12">
        <div class="n-view-title">{{ $t("src.components.contacts.contactsList") }}</div>
      </el-col>
      <el-col :span="12" style="display: flex; align-items: center; justify-content: flex-end">
        <new-list-item-button v-if="$can('create', 'contacts')" @click="newContact">{{
          $t("src.components.contacts.newContact")
        }}</new-list-item-button>
      </el-col>
    </el-row>
    <div class="card-body" style="background: #fff">
      <el-row type="flex" justify="space-between">
        <!-- SEARCH -->
        <el-col :span="5">
          <el-input
            class="n-search"
            :placeholder="$t('src.components.contacts.suche')"
            clearable
            v-model="searchQuery"
            suffix-icon="el-icon-search"
          >
          </el-input>
        </el-col>
        <!-- AKTIV/INAKTIV FILTER -->
        <el-col class="text-right" :span="5">
          <span style="padding-right: 40px">
            <span class="n-profile-label-title" style="padding-right: 5px">{{
              $t("src.components.contacts.filternNach")
            }}</span>
            <el-dropdown trigger="click" @command="changeActive">
              <span class="n-profile-dropdown-value">
                {{ activeLabel }}<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item v-for="item in filterOptions" :key="item.label" :command="item.value">{{
                  item.label
                }}</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </span>
        </el-col>
      </el-row>
      <el-tabs type="card" v-model="activeTab" style="margin-top: 20px">
        <el-tab-pane :name="tabs[0].value" :label="tabs[0].label">
          <perfect-scrollbar :options="{ suppressScrollX: true }" class="contact-view">
            <el-table
              :data="filteredContacts"
              style="width: 100%"
              :default-sort="{ prop: 'lastName', order: 'ascending' }"
              v-loading="loading"
              height="calc(100vh - 272px)"
            >
              <el-table-column min-width="200" sortable prop="lastName" label="Nachname">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">Nachname</span>
                </template>
                <template v-slot="props">
                  <div>
                    <el-row type="flex" align="middle">
                      <el-col>
                        {{ props.row | formatName }}
                        <el-badge
                          :type="props.row.active ? 'success' : 'danger'"
                          style="padding-top: 9px; padding-left: 6px"
                          is-dot
                        />
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" sortable :sort-method="websiteSort" prop="company">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{ $t("src.components.contacts.company") }}</span>
                </template>
                <!-- <template v-slot="props">
              <a :href="props.row.website" rel="noindex nofollow" target="_blank">{{ props.row.website }}</a>
            </template> -->
              </el-table-column>
              <el-table-column
                min-width="100"
                sortable
                :sort-method="(a, b) => hrDataSort('mobile', a, b)"
                prop="mobile"
              >
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{ $t("src.components.contacts.mobile") }}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="100" sortable :sort-method="(a, b) => hrDataSort('phone', a, b)" prop="phone">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{ $t("src.components.contacts.phone") }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
            min-width="100"
            sortable
            :sort-method="(a, b) => hrDataSort('city', a, b)"
            :formatter="(r) => getHrDataValue(r, 'city')"
          >
            <template v-slot:header>
              <span class="n-table-header">{{ $t("src.components.contacts.contactsglobal.stadt") }}</span>
            </template>
          </el-table-column> -->
              <el-table-column fixed="right" width="130">
                <template v-slot="props">
                  <button
                    class="btn btn-sm btn-ghost"
                    v-if="$can('update', 'contacts')"
                    @click="editContact(props.row)"
                  >
                    <pencil-icon />
                  </button>
                  <button class="btn btn-sm btn-ghost" v-if="$can('read', 'contacts')" @click="viewContact(props.row)">
                    <eye-outline-icon />
                  </button>
                  <button
                    class="btn btn-sm btn-ghost"
                    v-if="$can('delete', 'contacts')"
                    @click="deleteContact(props.row)"
                  >
                    <trash-can-outline-icon />
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </perfect-scrollbar>
        </el-tab-pane>
        <!-- Auftraggeber -->
        <el-tab-pane :name="tabs[1].value" :label="tabs[1].label">
          <perfect-scrollbar :options="{ suppressScrollX: true }" class="contact-view">
            <el-table
              :data="filteredContacts"
              style="width: 100%"
              :default-sort="{ prop: 'lastName', order: 'ascending' }"
              v-loading="loading"
              height="calc(100vh - 272px)"
            >
              <el-table-column min-width="200" sortable prop="lastName" label="Nachname">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">Firma</span>
                </template>
                <template v-slot="props">
                  <div>
                    <el-row type="flex" align="middle">
                      <el-col>
                        {{ props.row | formatName }}
                        <el-badge
                          :type="props.row.active ? 'success' : 'danger'"
                          style="padding-top: 9px; padding-left: 6px"
                          is-dot
                        />
                      </el-col>
                    </el-row>
                  </div>
                </template>
              </el-table-column>
              <el-table-column min-width="100" prop="nameSearch">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{ $t("companySearch") }}</span>
                </template>
              </el-table-column>
              <el-table-column min-width="100" sortable :sort-method="(a, b) => hrDataSort('phone', a, b)" prop="phone">
                <!-- HEADER -->
                <template v-slot:header>
                  <span class="n-table-header">{{ $t("src.components.contacts.phone") }}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column
            min-width="100"
            sortable
            :sort-method="(a, b) => hrDataSort('city', a, b)"
            :formatter="(r) => getHrDataValue(r, 'city')"
          >
            <template v-slot:header>
              <span class="n-table-header">{{ $t("src.components.contacts.contactsglobal.stadt") }}</span>
            </template>
          </el-table-column> -->
              <el-table-column fixed="right" width="130">
                <template v-slot="props">
                  <button
                    class="btn btn-sm btn-ghost"
                    v-if="$can('update', 'contacts')"
                    @click="editContact(props.row)"
                  >
                    <pencil-icon />
                  </button>
                  <button class="btn btn-sm btn-ghost" v-if="$can('read', 'contacts')" @click="viewContact(props.row)">
                    <eye-outline-icon />
                  </button>
                  <button
                    class="btn btn-sm btn-ghost"
                    v-if="$can('delete', 'contacts')"
                    @click="deleteContact(props.row)"
                  >
                    <trash-can-outline-icon />
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </perfect-scrollbar>
        </el-tab-pane>
      </el-tabs>
    </div>
    <contact-details
      @closed="discardDetails"
      @onContactSave="saveContact"
      @setFromData="setFromData"
      :contactData="selectedContact"
      :deleteContact="deleteContact"
      :isEditMode="contactEditMode"
      :isNewContact="isNewContact"
      :contactType="activeTab"
    />
  </div>
</template>

<script>
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Message, Table, Tabs, TableColumn, Dropdown, DropdownItem, DropdownMenu, Badge, TabPane } from "element-ui";
import { debounce, get, filter } from "lodash";
import SaveProfileButton from "../UIComponents/Buttons/SaveProfileButton.vue";
import EyeOutline from "vue-material-design-icons/EyeOutline";
import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import ContactDetails from "./ContactDetails.vue";
import NewListItemButton from "../UIComponents/Buttons/NewListItemButton.vue";

export default {
  name: "contacts-global",
  components: {
    PerfectScrollbar,
    SaveProfileButton,
    Message,
    ContactDetails,
    NewListItemButton,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    [Badge.name]: Badge,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [EyeOutline.name]: EyeOutline,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
  },
  data() {
    return {
      activeTab: "contact",
      tabs: [
        { label: "Ansprechpartner", value: "contact" },
        { label: "Auftraggeber", value: "client" },
      ],
      activeLabels: new Map([
        [true, "Aktiv"],
        [false, "Inaktiv"],
        [null, "Alle"],
      ]),
      activeFilter: true,
      filterOptions: [
        {
          value: true,
          label: "Aktiv",
        },
        {
          value: false,
          label: "Inaktiv",
        },
        {
          value: null,
          label: "Alle",
        },
      ],
      loading: false,
      searchQuery: "",
      contacts: [],
      filteredContacts: [],
      selectedContact: null, // data passed to view/edit drawer
      contactEditMode: false, // to distinguish between view and edit button
      isNewContact: false, // to delete contact if created new one and then close drawer
    };
  },
  computed: {
    activeLabel() {
      return this.activeLabels.get(this.activeFilter);
    },
  },
  mounted() {
    this.fetch();
    this.debounceSetFilteredContacts = debounce(this.setFilteredContacts, 400);
  },
  methods: {
    changeActive(command) {
      this.activeFilter = command;
      this.setFilteredContacts();
    },
    async fetch() {
      try {
        this.loading = true;
        const response = await this.axios.get("/api/contacts");
        this.contacts = response.data;
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    newContact() {
      this.selectedContact = {};
      this.isNewContact = true;
      this.contactEditMode = true;
    },
    setFromData(data) {
      this.selectedContact = { ...data };
    },
    viewContact(contactData) {
      this.selectedContact = contactData;
      this.isNewContact = false;
      this.contactEditMode = false;
    },
    editContact(contactData) {
      this.selectedContact = contactData;
      this.isNewContact = false;
      this.contactEditMode = true;
    },
    async deleteContact(contactData) {
      await this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/contacts/${contactData._id}`);
          const foundIdx = this.contacts.findIndex((item) => item._id === contactData._id);
          if (foundIdx !== -1) {
            this.contacts.splice(foundIdx, 1);
          }
        } catch (error) {
          if (error.response && error.response.data.message) {
            if (error.response.data.code === 1) {
              Message.error(`Kontakt wird in Projekten verwendet: ${error.response.data.message.join(", ")}`);
            } else {
              Message.error(error.response.data.message);
            }
          } else {
            Message.error(error.message);
          }
          throw error;
        }
      });
    },
    saveContact(contactData) {
      const foundIdx = this.contacts.findIndex((item) => item._id === contactData._id);
      if (foundIdx !== -1) {
        this.$set(this.contacts, foundIdx, contactData);
      } else {
        this.contacts.push(contactData);
      }
    },
    websiteSort(a, b) {
      const aSite = a.website ? a.website.toLowerCase() : undefined;
      const bSite = b.website ? b.website.toLowerCase() : undefined;
      if (aSite > bSite) {
        return 1;
      } else if (aSite < bSite) {
        return -1;
      } else {
        return 0;
      }
    },
    hrDataSort(namespace, a, b) {
      const aData = a[namespace];
      const bData = b[namespace];
      if (aData > bData) {
        return 1;
      } else if (aData < bData) {
        return -1;
      } else {
        return 0;
      }
    },
    setFilteredContacts() {
      let filteredContacts = this.contacts.slice();
      let activeFilterPredicate;
      switch (this.activeFilter) {
        // active
        case true:
          activeFilterPredicate = (item) => item.active;
          break;
        // inactive
        case false:
          activeFilterPredicate = (item) => !item.active;
          break;
        // both
        default:
          activeFilterPredicate = () => true;
          break;
      }
      const contactTypePredicate = (item) => item.type === this.activeTab;
      const predicates = [activeFilterPredicate, contactTypePredicate];
      if (this.searchQuery && this.searchQuery.length) {
        const searchString = this.searchQuery.toLowerCase();
        predicates.push((contact) => {
          let matches = false;
          if (contact.nameSearch && contact.nameSearch.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          if (contact.name && contact.name.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          if (contact.lastName && contact.lastName.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          if (!matches && contact.phone && contact.phone.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          if (!matches && contact.company && contact.company.toLowerCase().indexOf(searchString) !== -1) {
            matches = true;
          }
          return matches;
        });
      }
      if (!predicates.length) {
        this.filteredContacts = filteredContacts;
      } else {
        this.filteredContacts = filter(filteredContacts, (doc) => predicates.every((func) => func(doc)));
      }
    },
    editContact(contactData, isNew) {
      this.selectedContact = contactData;
      this.isNewContact = !!isNew;
      this.contactEditMode = true;
    },
    discardDetails() {
      this.selectedContact = null;
      this.contactEditMode = false;
      this.isNewContact = false;
    },
  },
  watch: {
    activeTab() {
      this.setFilteredContacts();
    },
    contacts(newVal) {
      if (newVal) {
        this.setFilteredContacts();
      }
    },
    searchQuery(newVal) {
      this.debounceSetFilteredContacts();
    },
  },
  filters: {
    formatName(record) {
      if (record.lastName && record.name) {
        return `${record.lastName}, ${record.name}`;
      } else {
        return record.lastName || record.name;
      }
    },
  },
};
</script>

<style>
.contact-view.ps {
  height: calc(100vh - 272px);
}
</style>
