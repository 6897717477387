<template>
  <div class="card" style="flex-grow: 1; margin-bottom: 0">
    <!-- HEADER -->
    <el-row type="flex" :gutter="20">
      <!-- SEARCH -->
      <el-col :span="6">
        <div class="n-search-indextable">
          <el-input
            :placeholder="$t('src.components.uicomponents.helper.indextable.suche')"
            clearable
            v-model="searchQuery"
            suffix-icon="el-icon-search"
          >
          </el-input>
        </div>
      </el-col>
      <!-- SLOT FOR ADDITIONAL CONTROLS FROM PARENT -->
      <el-col :span="4" style="margin-top: 24px">
        <slot name="additional" />
      </el-col>

      <!-- Filter -->
      <el-col :span="14" class="text-right" style="padding-top: 30px" v-if="!isTabMode">
        <!-- OPTIONAL FILTER -->
        <span v-if="multiSelect.filterOptions.length > 0" style="padding-right: 20px">
          <!-- DESCRIPTION -->
          <span class="n-profile-label-title" style="padding-right: 5px">{{ multiSelect.label }}</span>
          <!-- DROPDOWN -->
          <el-dropdown trigger="click" @command="changeMultiSelect">
            <span class="n-profile-dropdown-value">
              {{ multiSelect.value.label }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in multiSelect.filterOptions" :key="item.value" :command="item.value">{{
                item.label
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>

        <!-- AKTIV/INAKTIV FILTER -->
        <span style="padding-right: 40px" v-if="enabledActiveFilter">
          <!-- DESCRIPTION -->
          <span class="n-profile-label-title" style="padding-right: 5px">{{
            $t("src.components.uicomponents.helper.indextable.filternNach")
          }}</span>
          <!-- DROPDOWN -->
          <el-dropdown trigger="click" @command="changeActive">
            <span class="n-profile-dropdown-value">
              {{ active }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in filterOptions" :key="item.value" :command="item.label">{{
                item.label
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </el-col>

      <el-col v-else :span="19" class="text-right" style="padding-top: 30px">
        <!-- AKTIV/INAKTIV FILTER -->
        <span style="padding-right: 40px">
          <!-- DESCRIPTION -->
          <span class="n-profile-label-title" style="padding-right: 5px">{{
            $t("src.components.uicomponents.helper.indextable.filternNach")
          }}</span>
          <!-- DROPDOWN -->
          <el-dropdown trigger="click" @command="changeActive">
            <span class="n-profile-dropdown-value">
              {{ active }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in filterOptions" :key="item.value" :command="item.label">{{
                item.label
              }}</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </span>
      </el-col>
    </el-row>
    <el-tabs v-if="isTabMode" type="card" v-model="activeType" style="margin-top: 20px">
      <el-tab-pane
        v-for="option in multiSelect.filterOptions"
        :key="option.value"
        :name="option.value"
        :label="option.label"
      >
      </el-tab-pane>
    </el-tabs>
    <!-- TABLE -->
    <el-table
      :class="isTabMode ? '' : 'table-wrapper'"
      :data="queriedData"
      style="width: 100%"
      height="calc(100vh - 272px)"
      v-loading="loading"
      element-loading-text="Lade Daten..."
      :default-sort="defaultSort"
      @sort-change="handleSortChange"
    >
      <!-- VARIABLE CONTENT FROM CALLING COMPONENT -->
      <el-table-column
        sortable
        :width="column.width"
        :min-width="column.minWidth"
        :align="column.align"
        v-for="column in extendedColumns"
        :key="column.label"
        :label="column.label"
        :prop="column.key"
        :sort-by="column.key"
        :sort-method="column.sortMethod || defaultSortMethod(column.key)"
      >
        <!-- HEADER -->
        <template v-slot:header>
          <span class="n-table-header">{{ column.label }}</span>
        </template>

        <!-- CELL CONTENT -->
        <template v-slot="props">
          <slot :name="column.label" :data="props"></slot>
        </template>
      </el-table-column>

      <!-- FIX CONTENT: DISPO AND STATUS -->
      <el-table-column min-width="180" v-if="supressColumns.indexOf('dispo') == -1">
        <!-- HEADER -->
        <template v-slot:header>
          <span class="n-table-header">{{ $t("src.components.uicomponents.helper.indextable.projektStatus") }}</span>
        </template>
        <template v-slot="props">
          <div class="text-wrap">{{ getDispo(props) }}</div>
        </template>
      </el-table-column>
      <el-table-column min-width="190" v-if="supressColumns.indexOf('status') == -1">
        <template v-slot:header>
          <span class="n-table-header">{{ $t("src.components.uicomponents.helper.indextable.ressourceStatus") }}</span>
        </template>
        <template v-slot="scope">
          <div class="text-wrap">
            {{ getStatus(scope.row.statusReference) }}
          </div>
        </template>
      </el-table-column>

      <!-- Action Buttons -->
      <el-table-column align="right" fixed="right" class-name="action-buttons td-actions" width="160">
        <template v-slot="props">
          <template v-if="modelType === 'project' && props.row.isWorkshop">
            <router-link class="btn btn-sm btn-ghost" :to="`/project/profile/workshop/${props.row.id}`">
              <eye-outline-icon />
            </router-link>
          </template>
          <template v-else>
            <slot name="extended_actions" :props="props" />
            <router-link
              v-if="$can('update', authSubject)"
              data-testid="edit_item"
              class="btn btn-ghost"
              :to="getEditUrl(props.row.id)"
            >
              <pencil-icon />
            </router-link>
            <router-link
              v-if="showViewButton && $can('read', authSubject)"
              class="btn btn-sm btn-ghost"
              :to="getViewUrl(props.row.id)"
              data-testid="view_item"
            >
              <eye-outline-icon />
            </router-link>
            <el-button
              v-if="$can('delete', authSubject) && !hideRemoveAction"
              class="btn btn-sm btn-ghost"
              @click.prevent="removeModel(props)"
              data-testid="delete_item"
            >
              <trash-can-outline-icon />
            </el-button>
          </template>
          <project-color :color="props.row.statusColor" position="right" />
        </template>
      </el-table-column>
      <infinite-loading
        slot="append"
        :identifier="infiniteId"
        @infinite="infiniteHandler"
        force-use-infinite-wrapper=".el-table__body-wrapper"
        :distance="300"
      >
        <template slot="no-more">
          <span></span>
        </template>
      </infinite-loading>
    </el-table>
  </div>
</template>

<script>
import { Table, TableColumn, Message, MessageBox, Tabs, TabPane } from "element-ui";
import * as moment from "moment";
import { indexOf, groupBy, get, debounce, sortBy, reverse } from "lodash";
import EyeIcon from "vue-material-design-icons/EyeOutline";
import InfiniteLoading from "vue-infinite-loading";
import { mapState } from "vuex";
import ProjectColor from "../../Project/ProjectColor.vue";
import { until } from "src/utils/until";

export const SEARCH_QUERY_NAMESPACE = "indexTableSearchQuery";

// used to create urls to back-end
const modelTypeToUrlName = {
  machine: "machines",
  vehicle: "vehicles",
  project: "projects",
  employee: "employees",
  serviceProvider: "service-providers",
  subcontractor: "subcontractor",
};
// used to navigate through profile pages
function modelTypeToAppPathname(modelType) {
  if (modelType === "serviceProvider" || modelType === "subcontractor") {
    return `purchasing/${modelType}`;
  } else if (modelType === "users" || modelType === "user-groups") {
    return `administration/${modelType}`;
  } else {
    return `${modelType}`;
  }
}

export default {
  name: "index-table",
  props: {
    defaultSort: { type: Object },
    authSubject: {
      type: String,
      default: "none",
    },
    enabledActiveFilter: { type: Boolean, default: () => true },
    hideRemoveAction: { type: Boolean },
    showViewButton: {
      type: Boolean,
      default: true,
    },
    searchBySuppliers: {
      type: Boolean,
    },
    modelType: String,
    extendedColumns: Array,
    suppressActiveButtons: false,
    supressColumns: {
      type: Array,
      default: () => [],
    },
    supressEntities: {
      type: Object,
      default: () => {},
    },
    propsToSearch: {
      type: Array,
      default: () => ["name"],
    },
    multiSelectProps: {
      type: Object,
      validator: (obj) => {
        if (typeof obj !== "object") {
          return false;
        }
        if (!Array.isArray(obj.filterOptions)) {
          return false;
        }
        // should specify which prop to filter
        if (typeof obj.propToFilter !== "string") {
          return false;
        }
        // filterOptions should have following structure [{label: string, value: string, default: bool}];
        return obj.filterOptions.every((item) => item.value && item.label);
      },
    },
    rowClassName: {
      type: Function,
    },
    normalizeResponse: {
      type: Function,
    },
  },
  components: {
    InfiniteLoading,
    ProjectColor,
    [EyeIcon.name]: EyeIcon,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Message.name]: Message,
    [MessageBox.name]: MessageBox,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
  },
  data() {
    return {
      active: "Aktiv",
      filterOptions: [
        {
          value: 0,
          label: "Aktiv",
        },
        {
          value: 1,
          label: "Inaktiv",
        },
        {
          value: 2,
          label: "Alle",
        },
      ],
      loading: false,
      dataTable: [], // all data received from DB
      projectsMeta: [],
      projectEventsMap: {},
      searchQuery: "",
      filteredData: [], // data filtered by "active" filter
      queriedData: [], // data filtered by search quuery
      multiSelect: {
        filterOptions: [], // predefined filter options from props
        propToFilter: "", // object propname that is used to filter
        value: "", // array of selected filter options
        label: "",
      },
      activeType: "", // used to filter records when "tabMode" is set
      PAGE: 1, // gets incremented by infinite loader to return next slice of list
      OFFSET: 50, // amount of records that are returned within one page
      infiniteId: 0,
      today: moment().startOf("day"),
    };
  },
  async mounted() {
    // check if returned from Profile view to preserve search filter query
    if (new RegExp(`^\/${this.modelType}\/(view|profile)\/(.*)`, "g").test(this.routeState.from.path)) {
      const preservedSearchQuery = sessionStorage.getItem(SEARCH_QUERY_NAMESPACE);
      this.searchQuery = preservedSearchQuery;
      const initialActiveFilterValue = sessionStorage.getItem(this.activeFilterKey);
      if (initialActiveFilterValue !== null) {
        this.active = initialActiveFilterValue;
      }
    } else {
      // or drop saved value
      sessionStorage.removeItem(SEARCH_QUERY_NAMESPACE);
      sessionStorage.removeItem(this.activeFilterKey);
    }
    if (this.$props.multiSelectProps) {
      this.multiSelect.label = this.$props.multiSelectProps.label;
      this.multiSelect.filterOptions = sortBy(this.$props.multiSelectProps.filterOptions.slice(), "label");
      this.multiSelect.propToFilter = this.$props.multiSelectProps.propToFilter;
      this.activeType = this.$props.multiSelectProps.filterOptions.find((i) => i.default).value;

      //get option marked as default
      let defaultOption = this.$props.multiSelectProps.filterOptions.find((option) => {
        return option.default;
      });
      this.multiSelect.value = defaultOption;
    }
    await until(() => this.accessRightsLoaded);
    await this.initTable();
    this.$root.$on("refetch_indexTable", this.initTable);
  },
  created() {
    this.debounceFilterBySearchQuery = debounce(this.filterBySearchQuery, 300);
    this.debounceDiscardInfiniteScroll = debounce(this.discardInfiniteScroll, 300);
  },
  beforeDestroy() {
    this.$root.$off("refetch_indexTable", this.initTable);
  },
  computed: {
    ...mapState({ routeState: (state) => state.route }),
    ...mapState("account", { accessRights: "accessRights", accessRightsLoaded: "accessRightsLoaded" }),
    hasCalendarAccess() {
      const access = get(this.accessRights, "calendar", { specificAccess: {}, generalAccess: null });
      return !!access.generalAccess;
    },
    hasAccessToWorkshopProjects() {
      const access = get(this.accessRights, "workshop_projects.generalAccess", null);
      return !!access;
    },
    activeFilterKey() {
      return `activeFilter`;
    },
    isTabMode() {
      return this.multiSelectProps && this.multiSelectProps.tabMode;
    },
  },
  methods: {
    handleSortChange({ column, prop, order }) {
      console.log("sort", { column, prop, order });
      const columnProps = this.extendedColumns.find((item) => item.key === prop);
      this.dataTable.sort(columnProps.sortMethod);
      if (order === "descending") {
        reverse(this.dataTable);
      }
      console.log(
        "this.dataTable",
        this.dataTable.map((i) => i.bvName)
      );
      this.$nextTick(() => {
        this.filterData();
      });
    },
    updateTableData(newData) {
      this.dataTable = JSON.parse(JSON.stringify(newData));
      this.$nextTick(() => {
        this.filterData();
      });
    },
    async initTable() {
      try {
        this.loading = true;
        //get data from api REST call
        const projectMetaReq = this.axios
          .get("/api/projects/meta")
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            Message({ type: "error", message: error.message });
          });
        const url = modelTypeToUrlName[this.modelType] || this.modelType;
        const params = {};
        if (this.searchBySuppliers) {
          params.withSuppliers = true;
        }
        if (this.modelType === "project" && this.hasAccessToWorkshopProjects) {
          params.include_workshop = true;
        }
        const modelTypeReq = this.axios
          .get("/api/" + url, { params })
          .then((response) => {
            if (this.normalizeResponse && response.data.length) {
              return this.normalizeResponse(response.data);
            } else {
              return response.data;
            }
          })
          .catch((error) => {
            Message({
              message: error.message,
              type: "error",
            });
            throw error;
          });
        const suppliersReq = this.loadSuppliers();
        const fetchRequests = [projectMetaReq, modelTypeReq, suppliersReq];

        //if dispo is not in supress column list
        if (this.supressColumns.indexOf("dispo") === -1 && this.hasCalendarAccess) {
          fetchRequests.push(this.loadProjectEvents());
        }
        //if status is not in supress column list
        if (this.supressColumns.indexOf("status") === -1 && this.hasCalendarAccess) {
          //load now status list from server
          fetchRequests.push(this.loadStatusList());
        }
        await Promise.all(fetchRequests)
          .then((responses) => {
            const [projectMetaData, modelTypeData, suppliersReq, projectEventsData, statusEventsData] = responses;
            // provide each model with status events data
            let normalizedModelTypeData;
            if (statusEventsData) {
              normalizedModelTypeData = modelTypeData.reduce((array, current) => {
                if (statusEventsData[current.id]) {
                  current.statusReference = [].concat(statusEventsData[current.id]);
                } else {
                  current.statusReference = [];
                }
                array.push(current);
                return array;
              }, []);
            } else {
              normalizedModelTypeData = modelTypeData;
            }
            if (suppliersReq) {
              if (!normalizedModelTypeData) {
                normalizedModelTypeData = modelTypeData.slice();
              }
              normalizedModelTypeData = normalizedModelTypeData.map((item) => {
                // const supplierNames = item.suppliers.map((supplierId) => suppliersReq[supplierId]).join("/");
                const supplierNames = item.suppliers.map((supplier) => supplier.name).join("/");
                return {
                  ...item,
                  supplierNames,
                };
              });
            }
            this.projectsMeta = projectMetaData;
            if (this.defaultSort) {
              const sortMethod = this.extendedColumns.find((item) => item.key === this.defaultSort.prop).sortMethod;

              normalizedModelTypeData.sort(sortMethod || this.defaultSortMethod(this.defaultSort.prop));
              if (this.defaultSort.order === "descending") {
                reverse(normalizedModelTypeData);
              }
            }
            this.dataTable = normalizedModelTypeData;

            this.$emit("tableFetched", modelTypeData);

            if (projectEventsData) {
              this.projectEventsMap = projectEventsData;
            }
            this.filterData();
          })
          .catch((err) => {
            throw err;
          });
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    changeActive(newFilter) {
      sessionStorage.setItem(this.activeFilterKey, newFilter);
      this.active = newFilter;
      this.filterData();
    },
    changeMultiSelect(newFilter) {
      let selectedOption = this.multiSelect.filterOptions.find((option) => option.value === newFilter);
      this.multiSelect.value = selectedOption;
      this.filterData();
    },
    filterData() {
      if (this.$props.enabledActiveFilter) {
        switch (this.active) {
          case "Aktiv":
            this.filteredData = this.dataTable.filter(function (entity) {
              return entity.active === true || entity.enabled === true; // enabled - for keycloak users
            });
            break;

          case "Inaktiv":
            this.filteredData = this.dataTable.filter(function (entity) {
              return entity.active === false || entity.enabled === false; // enabled - for keycloak users
            });
            break;

          case "Alle":
            this.filteredData = this.dataTable.slice();
            break;
        }
      } else {
        this.filteredData = this.dataTable.slice();
      }
      this.filterBySearchQuery();
      this.discardInfiniteScroll();
    },
    async removeModel(props) {
      let self = this;

      MessageBox.confirm("wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(async function () {
        const modelName = modelTypeToUrlName[self.modelType] || self.modelType;

        await self.axios
          .delete(`/api/${modelName}/${props.row.id}`)
          .then(function (entities) {
            Message({ message: "erfolgreich gelöscht", type: "success" });

            //remove from table
            let entity = self.dataTable.find((e) => e.id == props.row.id);
            let index = indexOf(self.dataTable, entity);
            self.dataTable.splice(index, 1);
          })
          .then(self.initTable)
          .catch((error) => {
            if (error.response && error.response.data && error.response.data.message === "HAS_ASSIGNED_RESOURCES") {
              const requestDelete = (releaseResources) =>
                self.axios.delete("/api/projects/" + props.row.id, { params: { releaseResources } });
              MessageBox.confirm("Zugewiesene Ressourcen freigeben?", {
                confirmButtonText: "Ja",
                cancelButtonText: "Nein",
                type: "warning",
              })
                .then(() => requestDelete(true))
                .then(self.initTable)
                .catch((err) => {
                  // Message({
                  //   message: err.message,
                  //   type: "error"
                  // });
                });
            } else {
              Message({
                message: error.message,
                type: "error",
              });
            }
          });

        self.filterData();
        self.$emit("itemRemoved", props.row.id);
      });
    },
    defaultSortMethod(prop) {
      return function (a, b) {
        let aVal = get(a, prop, ""),
          bVal = get(b, prop, "");
        aVal = aVal && aVal.toString().toLowerCase();
        bVal = bVal && bVal.toString().toLowerCase();
        if (aVal > bVal) {
          return 1;
        } else if (aVal < bVal) {
          return -1;
        } else {
          return 1;
        }
      };
    },
    getFormatedDate(date) {
      if (date) {
        try {
          return moment(date).format("DD.MM.YYYY");
        } catch (e) {
          console.log(e);
        }
      } else {
        return "N/A";
      }
    },
    filter(filter) {
      this.active = filter;
    },
    async loadProjectEvents() {
      let self = this;
      return await this.axios
        .get("/api/project-events/project", { params: { excludeOld: true, resourceType: self.modelType } })
        .then(function (result) {
          // transform list of events into map grouped by resource id
          return groupBy(result.data, "resourceId");
        })
        .catch(function (error) {
          Message({ message: error.message, type: "error" });
          return {};
        });
    },
    async loadStatusList() {
      let self = this;
      return await this.axios
        .get("/api/status-events", {
          params: {
            excludeOld: true,
            resourceType: self.modelType,
          },
        })
        .then(function (result) {
          return groupBy(result.data, "resourceId");
          // Object.keys(statusListMap).forEach(entityId => {
          //   const entityIdx = self.dataTable.findIndex(item => item.id === entityId);
          //   if (entityIdx !== -1) {
          //     self.dataTable[entityIdx].statusReference = [].concat(statusListMap[entityId]);
          //   }
          // });
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
          return [];
        });
    },
    async loadSuppliers() {
      if (!this.searchBySuppliers) {
        return Promise.resolve(null);
      }
      const response = await this.axios.get("/api/suppliers/meta", { params: { resourceType: this.modelType } });
      return response.data.reduce((obj, curr) => {
        obj[curr._id] = curr.name;
        return obj;
      }, {});
    },
    getDispo(props) {
      //get dispo model of current model
      const projectEventsOfResource = this.projectEventsMap[props.row.id];
      if (!projectEventsOfResource || !projectEventsOfResource.length) {
        // 2561: if currently not available - return "not available"
        if (
          props.row.statusReference &&
          props.row.statusReference.some(({ start }) => this.today.isSameOrAfter(start, "day"))
        ) {
          return "nicht verfügbar";
        }
        return "verfügbar";
      }

      let dispo = "";
      //loop project data
      let lastIndex = projectEventsOfResource.length - 1;
      for (let index = 0; index < projectEventsOfResource.length; index++) {
        // consider that project events are sorted ascendingly by "Start" date
        const projectEvent = projectEventsOfResource[index];
        if (!index || !this.isEventsAdjoining(projectEventsOfResource[index - 1], projectEvent)) {
          const projectData = this.projectsMeta.find((item) => item.id === projectEvent.projectId);
          dispo += projectData ? projectData.bvName : "?? ";
          dispo += "\n";
          dispo += this.getFormatedDate(projectEvent.start);
        }
        // if event is not last in list and next event is adjoining to current - complete date range later
        if (
          projectEventsOfResource[index + 1] &&
          this.isEventsAdjoining(projectEvent, projectEventsOfResource[index + 1])
        ) {
          continue;
          // otherwise - complete date range with current event.
        } else {
          dispo += " - " + this.getFormatedDate(projectEvent.end);
        }
        if (index !== lastIndex) {
          dispo += "\n\n";
        }
      }
      return dispo;
    },
    getStatus(statusList) {
      if (statusList && statusList.length > 0) {
        let result = "";
        let maxLength = statusList.length > 3 ? 3 : statusList.length;
        let lastIndex = maxLength - 1;
        for (let index = 0; index < maxLength; index++) {
          const status = statusList[index];
          let from = this.getFormatedDate(status.start);
          let to = this.getFormatedDate(status.end);
          result += `${(status.statusType && status.statusType.label) || "??"}\n ${from} - ${to}`;
          if (index !== lastIndex) {
            result += "\n\n";
          }
        }
        return result;
      }

      return "keine Einschränkung";
    },
    // checks if events belong to same project and follow one after another
    isEventsAdjoining(firstEvent, secondEvent) {
      return (
        firstEvent.projectId === secondEvent.projectId &&
        moment(firstEvent.end).add(1, "days").isSame(new Date(secondEvent.start), "day")
      );
    },
    infiniteHandler($state) {
      if (!this.filteredData.length) {
        $state.complete();
        return;
      }
      this.PAGE += 1;
      $state.loaded();
      if (this.filteredData.length && this.PAGE * this.OFFSET >= this.filteredData.length) {
        $state.complete();
      }
    },
    discardInfiniteScroll() {
      this.PAGE = 1;
      this.infiniteId++;
    },
    filterBySearchQuery() {
      // preserve search query for case when return from profile to index view
      sessionStorage.setItem(SEARCH_QUERY_NAMESPACE, this.searchQuery);
      let result = this.filteredData.slice();

      //use multiselect when set by parent component and selected option is not default
      if (this.multiSelect.filterOptions.length > 0 && !this.multiSelect.value.default) {
        const propName = this.multiSelect.propToFilter;
        const selectedOption = this.multiSelect.value.value;
        result = result.filter((item) => {
          return selectedOption === item[propName];
        });
      }

      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        const andComparator = query.split("+").map((item) => item.trim());
        if (andComparator.length > 1) {
          result = result.filter((entity) => {
            return this.propsToSearch.some((prop) => {
              const property = get(entity, prop);
              return property && andComparator.every((term) => property.toString().toLowerCase().indexOf(term) !== -1);
            });
          });
        } else {
          result = result.filter((entity) => {
            return this.propsToSearch.some((prop) => {
              const property = get(entity, prop);
              return property && property.toString().toLowerCase().indexOf(query) !== -1;
            });
          });
        }
      }
      if (!result.length) {
        this.queriedData = [];
        return;
      }
      result = result.slice(0, this.PAGE * this.OFFSET);
      if (this.searchQuery) {
        this.queriedData = [];
        this.$nextTick(() => {
          this.queriedData = result;
        });
      } else {
        this.queriedData = result;
      }
    },
    getEditUrl(recordId) {
      return `/${modelTypeToAppPathname(this.modelType)}/profile/${recordId}`;
    },
    getViewUrl(recordId) {
      return `/${modelTypeToAppPathname(this.modelType)}/profile/view/${recordId}`;
    },
  },
  watch: {
    PAGE(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.filterBySearchQuery();
      }
    },
    searchQuery(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.debounceFilterBySearchQuery();
        this.debounceDiscardInfiniteScroll();
      }
    },
    filterOptions(newVal, oldVal) {
      if (JSON.stringify(newVal).length !== JSON.stringify(oldVal).length) {
        this.debounceFilterBySearchQuery();
        this.debounceDiscardInfiniteScroll();
      }
    },
    activeType(newVal) {
      this.changeMultiSelect(newVal);
    },
  },
};
</script>

<style lang="scss">
// to properly hide text behind fixed column (was transparent before)
.table-striped tbody .el-table__row:nth-of-type(odd) {
  background-color: transparent;
}

.text-wrap {
  vertical-align: top;
  white-space: pre-line;

  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 20px;
  color: #000000;
}

.el-table .td-actions {
  button.btn {
    margin-right: 5px;
  }
}

.avatar-bigger {
  width: 50px;
  height: 50px;
  overflow: hidden;
  border-radius: 50%;
  margin-bottom: 15px;
}

.front {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  height: 80vh;
  width: 600px;
  top: 5vh;
  left: 50%;
  transform: translateX(-50%);
  margin: 0;
}

.image-slot {
  font-size: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50px;
  height: 50px;
  background: #f5f7fa;
  color: #909399;
}

.n-table-header {
  font-size: 14px;
  line-height: 16px;
  color: #727272;
  text-transform: none;
}

.table-wrapper {
  margin-top: 24px;
}

.el-table .cell {
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
}
</style>
