<template>
  <el-tooltip :value="visible" content="kopiert" manual>
    <span class="el-tag el-tag--info el-tag--small el-tag--light ml-2" @dblclick="copy">
      <span style="user-select: none">#{{ ticketNumber }}</span>
    </span>
  </el-tooltip>
</template>

<script>
import { Tag, Tooltip } from "element-ui";

export default {
  name: "todo-ticket-number",
  props: {
    ticketNumber: Number,
  },
  components: {
    [Tooltip.name]: Tooltip,
    [Tag.name]: Tag,
  },
  data() {
    return { visible: false };
  },
  methods: {
    copy(e) {
      e.preventDefault();
      navigator.clipboard.writeText(this.ticketNumber).then(() => {
        this.visible = true;
        setTimeout(() => {
          this.visible = false;
        }, 1200);
      });
    },
  },
};
</script>
