<template>
  <div>
    <div class="type-settings-wrapper">
      <div style="flex-grow: 1">
        <profile-select
          :editMode="true"
          :title="getTitle()"
          :multiple="false"
          :items="optionsOrdered"
          v-model="selectedTypeID"
          valueIdentifier="_id"
          :hideInactive="false"
          :disableInactive="false"
        />
      </div>
      <div v-if="isEditMode" style="margin-left: 6px; margin-bottom: 6px">
        <el-button class="btn btn-sm btn-ghost" @click="createSettings">
          <plus-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="editSettings">
          <pencil-icon />
        </el-button>
        <el-button class="btn btn-sm btn-ghost" @click="deleteType">
          <trash-can-outline-icon />
        </el-button>
      </div>
    </div>

    <!-- DIALOG -->
    <el-dialog :title="type + ' Konfiguration'" :visible.sync="dialogVisible">
      <el-form :model="formData" :rules="formRules" ref="typeSettingsForm" :disabled="!isEditMode">
        <el-form-item class="col-md-8" label="Aktiv" prop="active" :label-width="formData.labelwidth">
          <profile-switch v-model="formData.active" no-title></profile-switch>
        </el-form-item>
        <el-form-item class="col-md-8" :label="type" prop="selectedType" :label-width="formData.labelwidth">
          <el-input v-model="formData.selectedType" auto-complete="off"></el-input>
        </el-form-item>

        <el-form-item
          class="col-md-8"
          v-if="useSymbol"
          prop="symbol"
          :label="$t('src.components.uicomponents.helper.typesettings.symbol')"
          :label-width="formData.labelwidth"
        >
          <symbol-select v-model="formData.symbol" />
        </el-form-item>
        <el-form-item
          class="col-md-8"
          v-if="useColors"
          prop="color"
          :label="$t('src.components.uicomponents.helper.typesettings.symbolFarbe')"
          :label-width="formData.labelwidth"
        >
          <color-select v-model="formData.color" />
        </el-form-item>
        <el-form-item
          class="col-md-8"
          prop="accessGroups"
          :label="$t('src.components.uicomponents.helper.typesettings.accessGroups')"
          :label-width="formData.labelwidth"
          v-if="useAccessGroups"
        >
          <el-select
            clearable
            multiple
            v-model="formData.accessGroups"
            :placeholder="$t('src.components.uicomponents.helper.typesettings.accessGroups')"
          >
            <el-option v-for="item in accessGroupOptions" :key="item.id" :label="item.name" :value="item.id" />
          </el-select>
        </el-form-item>

        <el-form-item
          class="col-md-8"
          v-if="useSystemRelevant"
          :label="$t('src.components.uicomponents.helper.typesettings.systemrelevanz')"
          :label-width="formData.labelwidth"
        >
          <el-tooltip
            content="Systemrelevante Status werden im u.a. Kalender verwendet"
            placement="bottom"
            effect="light"
          >
            <el-checkbox v-model="formData.systemRelevant" :border="true">{{
              formData.systemRelevant ? "systemrelevant" : "ohne Auswirkung"
            }}</el-checkbox>
          </el-tooltip>
        </el-form-item>
        <el-form-item
          class="col-md-8"
          v-if="useInSma"
          :label="$t('src.components.uicomponents.helper.typesettings.usedInSma')"
          :label-width="formData.labelwidth"
        >
          <el-checkbox v-model="formData.usedInSma" :border="true">{{
            formData.usedInSma ? "in SMA verwendet" : "nicht in SMA verwendet"
          }}</el-checkbox>
        </el-form-item>
      </el-form>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.uicomponents.helper.typesettings.abbrechen')"
            @click="dialogVisible = false"
          ></cancel-button>
          <save-button
            v-if="!dialogIsEditForm"
            :text="$t('src.components.uicomponents.helper.typesettings.speichern')"
            @click="saveDialog"
          ></save-button>
          <save-button
            v-if="dialogIsEditForm"
            :text="$t('src.components.uicomponents.helper.typesettings.speichern')"
            @click="updateDialog"
          ></save-button>
        </span>
      </template>
    </el-dialog>
    <el-dialog
      :visible.sync="removeTypeWizardVisible"
      width="800"
      :title="$t('Resolve document types')"
      @closed="dismissWizard"
    >
      <div class="d-flex justify-content-between align-items-center" style="width: 100%">
        <p>
          {{ $t("Select new type for all documents") }}
        </p>
        <profile-select
          :filterable="true"
          :multiple="false"
          v-model="bulkTypeSelection"
          :items="availableTypes"
          valueIdentifier="_id"
          :editMode="true"
          label="label"
          :title="$t('Type bulk edit')"
          :placeholder="$t('Select type')"
          style="width: 400px"
        />
      </div>
      <el-table :data="removeTypeWizardData" style="width: 100%" ref="table" @selection-change="handleChangeSelection">
        <el-table-column type="selection" width="60" />
        <el-table-column min-width="200" prop="name">
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{ $t("src.components.uicomponents.helper.documents.documents.name") }}</span>
          </template>
        </el-table-column>
        <el-table-column min-width="200" prop="document">
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{
              $t("src.components.uicomponents.helper.documents.documents.dateiname")
            }}</span>
          </template>
          <template v-slot="scope">
            <div style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis">
              {{ getFilenameForDocument(scope.row) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column width="400" prop="type">
          <!-- HEADER -->
          <template v-slot:header>
            <span class="n-table-header">{{ $t("src.components.uicomponents.helper.documents.documents.typ") }}</span>
          </template>
          <!-- CONTENT -->
          <template v-slot="scope">
            <v-select
              :filterable="true"
              v-model="scope.row.type"
              :options="availableTypes"
              valueIdentifier="_id"
              labelIdentifier="label"
              :placeholder="$t('Select type')"
            />
          </template>
        </el-table-column>
      </el-table>
      <template v-slot:footer>
        <span class="dialog-footer">
          <cancel-button
            :text="$t('src.components.management.projectsettings.projecttypesettings.abbrechen')"
            @click="dismissWizard"
          ></cancel-button>
          <save-button
            :text="$t('src.components.management.projectsettings.projecttypesettings.speichern')"
            @click="submitTypeDeletion"
            :disabled="notAllTypesSelected"
          ></save-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import {
  ButtonGroup,
  Input,
  FormItem,
  Form,
  Dialog,
  Select,
  Option,
  Button,
  Message,
  MessageBox,
  Checkbox,
  Tooltip,
  Table,
  TableColumn,
} from "element-ui";

import { indexOf, orderBy } from "lodash";

import Vue from "vue";
import SymbolSelect from "./SymbolSelect.vue";
import ColorSelect from "./ColorSelect.vue";

export default {
  name: "type-settings",
  components: {
    [Tooltip.name]: Tooltip,
    Button,
    Message,
    MessageBox,
    SymbolSelect,
    ColorSelect,
    [Option.name]: Option,
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Select.name]: Select,
    [Dialog.name]: Dialog,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Input.name]: Input,
    [ButtonGroup.name]: ButtonGroup,
    [Checkbox.name]: Checkbox,
  },
  props: {
    type: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    useSymbol: {
      type: Boolean,
      default: true,
    },
    useColors: {
      type: Boolean,
      default: true,
    },
    useSystemRelevant: {
      type: Boolean,
      default: false,
    },
    useAccessGroups: {
      type: Boolean,
      default: false,
    },
    forAdmin: {
      type: Boolean,
    },
    modelid: {
      type: String,
      default: "",
      description: "model id like employee",
    },
    modelType: {
      type: String,
      default: "",
      description: "model type like status",
    },
    useInSma: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      formRules: {
        selectedType: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        symbol: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
        color: [{ required: true, message: "Dieses Feld wird benötigt", trigger: "change" }],
      },
      checked: true,
      options: [],
      selectedTypeID: "",
      accessGroupOptions: [],
      formData: {
        labelwidth: "200px",
        selectedType: "",
        accessGroups: [],
        symbol: "",
        color: "",
        modelid: this.modelid,
        systemRelevant: false,
        usedInSma: false,
        active: true,
      },
      dialogVisible: false,
      dialogIsEditForm: false,
      removeTypeWizardVisible: false,
      removeTypeWizardData: [],
      availableTypes: [],
      selectedRecords: [],
      bulkTypeSelection: "",
    };
  },
  beforeDestroy() {
    this.$root.$off("loadSettings");
  },
  mounted() {
    //Sometimes modelid data is loaded later
    if (this.modelid) {
      this.loadDataFromServer();
    }

    if (this.$props.useAccessGroups && this.$can("read", "usergroup")) {
      this.fetchAccessGroups();
    }
    this.$root.$on("loadSettings", (data) => {
      if (data.modelid === this.modelid && data.modelType === this.modelType) {
        this.loadDataFromServer();
      }
    });
  },
  watch: {
    //we need to watch the modelid and lazy load its depending data!
    //modelid source is loaded after this component is created
    modelid: function (newVal) {
      if (newVal) {
        this.loadDataFromServer();
      }
    },
    bulkTypeSelection(newVal) {
      this.selectedRecords.forEach((id) => {
        const idx = this.removeTypeWizardData.findIndex((i) => i._id === id);
        this.removeTypeWizardData[idx].type = newVal;
      });
      this.$refs.table.clearSelection();
      this.selectedRecords = [];
    },
  },
  computed: {
    optionsOrdered: function () {
      let ordered = orderBy(this.options, (o) => o.label && o.label.toLowerCase(), ["asc"]);
      return ordered;
    },
    notAllTypesSelected() {
      return !this.removeTypeWizardData.every((item) => !!item.type);
    },
  },
  methods: {
    dismissWizard() {
      this.removeTypeWizardVisible = false;
      this.removeTypeWizardData = [];
      this.availableTypes = [];
      this.selectedRecords = [];
      this.bulkTypeSelection = "";
    },
    async loadDataFromServer() {
      try {
        this.$root.$emit("settingsLoading", { loading: true, modelType: this.modelType, modelid: this.modelid });
        const response = await this.axios.get("/api/model-type", {
          params: {
            modelType: this.modelType,
            modelId: this.modelid,
            forAdmin: this.forAdmin ? true : undefined,
          },
        });
        this.options = response.data;
        // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
        let data = {
          options: this.optionsOrdered,
          operation: "loaded",
          info: null,
          modelID: this.modelid,
          modelType: this.modelType,
        };
        this.$root.$emit("settingsChanged", data);
        this.$emit("dataChanged", data);
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      } finally {
        this.$root.$emit("settingsLoading", { loading: false, modelType: this.modelType, modelid: this.modelid });
      }
    },
    getTitle() {
      return this.type + " (" + this.optionsOrdered.length + ")";
    },
    createSettings() {
      //empty form data
      this.formData.selectedType = "";
      this.formData.symbol = "";
      this.formData.color = "";
      this.formData.systemRelevant = false;
      this.formData.usedInSma = false;
      this.formData.active = true;
      this.formData.accessGroups = [];

      //show dialog
      this.dialogIsEditForm = false;
      this.dialogVisible = true;
    },
    editSettings() {
      let self = this;

      if (!this.selectedTypeID) {
        MessageBox.alert("Bitte '" + this.type + "' Typ auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }

      this.axios
        .get("/api/model-type/" + self.selectedTypeID)
        .then(function (response) {
          self.formData.selectedType = response.data.label;
          self.formData.symbol = response.data.icon;
          self.formData.color = response.data.color;
          self.formData.modelid = self.modelid;
          self.formData.systemRelevant = response.data.systemRelevant;
          self.formData.usedInSma = response.data.usedInSma;
          self.formData.accessGroups = response.data.accessGroups;
          self.formData.active = response.data.active;

          self.dialogIsEditForm = true;
          self.dialogVisible = true;
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    saveDialog() {
      let self = this;

      this.$refs.typeSettingsForm.validate((valid) => {
        if (valid) {
          if (this.formData.selectedType === "") {
            MessageBox.alert("Neuer Typ ist leer.", "", {
              type: "error",
              showClose: false,
            });
            return;
          }

          let entity = {
            // _id: null, //ID is set on server side
            active: self.formData.active,
            label: self.formData.selectedType,
            icon: self.formData.symbol,
            systemRelevant: self.formData.systemRelevant,
            usedInSma: self.formData.usedInSma,
            accessGroups: self.formData.accessGroups,
            color: self.formData.color,
            model: self.modelType,
            modelID: self.modelid,
          };

          this.axios
            .post("/api/model-type", entity)
            .then(function (response) {
              self.dialogVisible = false;
              Vue.set(self.options, self.options.length, response.data);
              // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
              let data = {
                options: self.optionsOrdered.filter((item) => item.active),
                operation: "created",
                info: response.data,
                modelID: self.modelid,
                modelType: self.modelType,
              };

              self.$root.$emit("settingsChanged", data);
              self.$emit("dataChanged", data);
            })
            .catch(function (error) {
              Message({
                message: error.message,
                type: "error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateDialog() {
      const isInvalid = this.$refs.typeSettingsForm.validate((valid) => !valid);
      if (isInvalid) {
        return;
      }
      let self = this;

      let entity = {
        active: self.formData.active,
        label: self.formData.selectedType,
        icon: self.formData.symbol,
        systemRelevant: self.formData.systemRelevant,
        usedInSma: self.formData.usedInSma,
        accessGroups: self.formData.accessGroups,
        color: self.formData.color,
        model: self.modelType,
        modelID: self.modelid,
      };

      this.axios
        .put("/api/model-type/" + self.selectedTypeID, entity)
        .then(function (response) {
          let option = self.options.find((x) => x._id === self.selectedTypeID);
          let index = indexOf(self.options, option);
          Vue.set(self.options, index, response.data);

          self.dialogIsEditForm = false;
          self.dialogVisible = false;
          // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
          let data = {
            options: self.optionsOrdered.filter((item) => item.active),
            operation: "updated",
            info: response.data,
            modelID: self.modelid,
            modelType: self.modelType,
          };

          self.$root.$emit("settingsChanged", data);
          self.$emit("dataChanged", data);
        })
        .catch(function (error) {
          Message({
            message: error.message,
            type: "error",
          });
        });
    },
    async handleDeleteType() {
      await this.axios.delete("/api/model-type/" + this.selectedTypeID).then((response) => {
        const option = this.options.find((x) => x._id === this.selectedTypeID);
        const index = indexOf(this.options, option);
        Vue.delete(this.options, index, response.data);
        // NOTE: same interface is used for ProjectTypeSettings.vue. When change - also adjust there!
        const data = {
          options: this.optionsOrdered.filter((item) => item.active),
          operation: "deleted",
          info: this.selectedTypeID,
          modelID: this.modelid,
          modelType: this.modelType,
        };

        this.$root.$emit("settingsChanged", data);
        this.$emit("dataChanged", data);

        this.selectedTypeID = "";
      });
    },
    deleteType() {
      let self = this;

      if (!this.selectedTypeID) {
        MessageBox.alert(this.type + " Typ auswählen", "", {
          type: "error",
          showClose: false,
        });
        return;
      }

      MessageBox.confirm("wirklich löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
        cancelButtonClass: "el-button--danger",
        confirmButtonClass: "button-default",
      }).then(async () => {
        try {
          await this.handleDeleteType();
        } catch (error) {
          if (error.response && error.response.data && error.response.data.code === 101) {
            this.removeTypeWizardVisible = true;
            this.removeTypeWizardData = error.response.data.data.map((item) => ({ ...item, type: "" }));
            this.availableTypes = this.options.filter((item) => item._id !== this.selectedTypeID);
          } else if (error.response && error.response.data && error.response.data.message) {
            Message({
              message: error.response.data.message,
              type: "error",
            });
          } else {
            Message({
              message: error.message,
              type: "error",
            });
          }
        }
      });
    },
    async fetchAccessGroups() {
      try {
        const response = await this.axios.get("/api/user-groups");
        this.accessGroupOptions = response.data || [];
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    getFilenameForDocument(record) {
      return record.fileList && record.fileList[0] ? record.fileList[0].name : undefined;
    },
    async submitTypeDeletion() {
      try {
        const formBody = this.removeTypeWizardData.slice();
        await this.axios.post("/api/documents/bulk-edit", formBody);
        await this.handleDeleteType();
        this.dismissWizard();
      } catch (error) {
        throw error;
      }
    },
    handleChangeSelection(selection) {
      this.selectedRecords = selection.map((item) => item._id);
    },
  },
};
</script>

<style>
.icon-options .el-select-dropdown__item.selected {
  background-color: #f5f7fa;
}

.type-settings-wrapper {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: nowrap;
}
</style>
