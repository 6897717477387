<template>
  <div style="height: 100%">
    <div v-if="data" class="note-editor-wrapper" v-loading="noteLoading">
      <div class="note-editor-header">
        <div class="note-avatar">
          <lazy-image :src="getAvatarSrc" />
        </div>
        <div>
          <div class="note-author">{{ getAuthorName }}</div>
          <div class="note-date">{{ data | formatDate }}</div>
        </div>
      </div>
      <div class="note-editor-body d-flex flex-column flex-grow-1">
        <el-row :gutter="15" type="flex" align="middle">
          <el-col :span="16">
            <ValidationObserver ref="validateObserver" slim>
              <div class="mb-2">
                <!-- v-if="data.isOwner" -->
                <profile-input
                  size="small"
                  :placeholder="$t('Title')"
                  :label="$t('Title')"
                  name="Titels der Notiz"
                  rules="required"
                  :editMode="data.isOwner"
                  v-model="data.title"
                />
                <!-- <b v-else style="font-size: 15px">{{ data.title }}</b> -->
              </div>
            </ValidationObserver>
          </el-col>
          <el-col :span="8" style="align-self: stretch">
            <template v-if="isWorkshop">
              <profile-input size="small" :label="$t('Project')" :editMode="false" :value="data.projectName" />
            </template>
            <template v-else>
              <profile-select
                :editMode="data.isOwner && !data.workshopName"
                :multiple="false"
                size="small"
                :title="$t('Workshop')"
                :placeholder="$t('Workshop')"
                :items="projectsList"
                v-model="data.workshopProjectId"
                :hideInactive="true"
                clearable
              />
            </template>
          </el-col>
        </el-row>

        <div v-if="data.isOwner" class="d-flex flex-column flex-grow-1 mb-2" style="min-height: 400px">
          <note-wysiwyg v-model="data.body" />
        </div>
        <div class="mb-2" v-else v-html="data.body" />
        <drawer-upload
          :data="{ metadata: getMetadata() }"
          location="NOTES"
          :on-remove="removeFile"
          :on-success="fileUploaded"
          :file-list="data.pictures"
          class="mb-2"
          :disabled="!data.isOwner"
          :pictureList="true"
        />
        <note-child-notes
          v-if="data.projectId"
          :data="data.childNotes"
          :isOwner="data.isOwner"
          :isPrivilegedUser="isPrivilegedUser"
          :projectId="data.projectId"
          @editNote="editChildNote"
          @deleteNote="deleteChildNote"
        />
        <div class="note-editor-footer">
          <div class="d-flex justify-space-between" v-if="data.isOwner">
            <div>
              <el-button
                type="danger"
                v-if="!data.isNew && (data.isOwner || isPrivilegedUser)"
                @click="handleDeleteNote"
                style="margin-right: auto"
              >
                {{ $t("Delete") }}
              </el-button>
              <el-button @click="$emit('cancel')">{{ $t("Cancel") }}</el-button>
            </div>
            <div style="margin-left: auto">
              <el-button type="primary" @click="submit">{{ $t("Save") }}</el-button>
            </div>
          </div>
          <div class="d-flex justify-space-between" v-else>
            <el-button
              type="danger"
              v-if="!data.isNew && isPrivilegedUser"
              @click="handleDeleteNote"
              style="margin-right: auto"
            >
              {{ $t("Delete") }}
            </el-button>
            <el-button type="primary" @click="addChildNote">{{ $t("Add new note") }}</el-button>
          </div>
        </div>
      </div>
      <el-dialog
        ref="dialog"
        :title="$t('Add note')"
        :visible.sync="isVisible"
        @closed="discard"
        :before-close="handleClose"
        width="540px"
      >
        <ValidationObserver ref="validateChildNote" slim>
          <div class="mb-3">
            <note-wysiwyg v-model="currentChildNote.body" />
          </div>
          <drawer-upload
            :data="{ metadata: getMetadata() }"
            location="NOTES"
            :on-remove="removeChildFile"
            :on-success="childFileUploaded"
            :file-list="currentChildNote.pictures"
            :pictureList="true"
            class="mb-2"
          />
          <div class="d-flex justify-content-between">
            <el-button @click="discard">{{ $t("Cancel") }}</el-button>
            <el-button type="primary" @click="saveChildNote">{{ $t("Save") }}</el-button>
          </div>
        </ValidationObserver>
      </el-dialog>
    </div>
    <div v-else class="empty-data">Keine Notiz ausgewählt</div>
  </div>
</template>

<script>
import DrawerUpload from "../Invoices/DrawerUpload.vue";
import { ValidationObserver } from "vee-validate";
import { Message, Dialog, MessageBox } from "element-ui";
import { moment } from "src/config/moment";
import NoteChildNotes from "./NoteChildNotes.vue";
import { unlink } from "src/utils/unlink";
import LazyImage from "../../UIComponents/Image/LazyImage.vue";
import { mapState } from "vuex";
import NoteWysiwyg from "./NoteWysiwyg.vue";

export default {
  name: "note-editor",
  props: {
    data: { type: Object },
    noteLoading: { type: Boolean },
    isWorkshop: { type: Boolean },
    projectId: { type: String, required: true },
    isPrivilegedUser: { type: Boolean },
    projectsList: { type: Array, default: () => [] },
  },
  components: {
    LazyImage,
    ValidationObserver,
    DrawerUpload,
    NoteChildNotes,
    NoteWysiwyg,
    [MessageBox.name]: MessageBox,
    [Message.name]: Message,
    [Dialog.name]: Dialog,
  },
  data() {
    return {
      timestamp: new Date().getTime(),
      isVisible: false,
      currentChildNote: {},
      currentEmployeeImageSrc: `${window.location.origin}/static/img/faces/avatar-placeholder.jpg`,
    };
  },
  methods: {
    async fetchCurrentEmployeeImageSrc() {
      const response = await this.axios.get("/api/employees/avatar");
      if (response.data) {
        this.currentEmployeeImageSrc = response.data;
      }
    },
    getMetadata() {
      return `project_${this.data.projectId}_notes_0`;
    },
    fileUploaded(response, file, fileList) {
      this.data.pictures = this.data.pictures.concat(response);
      // this.updateFiles();
    },
    childFileUploaded(response, file, fileList) {
      this.currentChildNote.pictures = [].concat(this.currentChildNote.pictures, response).filter(Boolean);
      // this.updateFiles();
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.data.pictures = this.data.pictures.filter((item) => item._id !== file._id);
        // this.updateFiles();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async removeChildFile(file) {
      try {
        const metadata = this.getMetadata(true);
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
            tempId: file.tempId,
          },
        });
        this.currentChildNote.pictures = this.currentChildNote.pictures.filter((item) => item._id !== file._id);
        // this.updateFiles();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async submit() {
      try {
        const isValid = await this.$refs.validateObserver.validate();
        if (!isValid) {
          return;
        }
        const { isNew, ...formData } = this.data;
        if (this.data.isNew) {
          const response = await this.axios.post(`/api/notes`, formData);
          this.$emit("onNoteSubmit", response.data);
        } else {
          const response = await this.axios.put(`/api/notes/${this.data._id}`, formData);
          this.$emit("onNoteSubmit", response.data);
        }
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    editChildNote(note) {
      this.isVisible = true;
      this.currentChildNote = unlink(note);
      if (!this.currentChildNote.pictures) {
        this.currentChildNote.pictures = [];
      }
    },
    handleDeleteNote() {
      this.$emit("removeNote");
    },
    deleteChildNote(noteId) {
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/notes/${this.data._id}/${noteId}`);
          const newNotes = this.data.childNotes.filter((item) => item._id !== noteId);

          this.$nextTick(() => {
            this.$emit("updateChildNotes", newNotes);
          });
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    addChildNote() {
      this.isVisible = true;
      this.currentChildNote = {
        isNew: true,
        projectId: this.projectId,
        pictures: [],
      };
    },
    async saveChildNote() {
      try {
        const isValid = await this.$refs.validateChildNote.validate();
        if (!isValid) {
          return;
        }
        let response;
        const formBody = { ...this.currentChildNote, projectId: this.data.projectId };
        if (this.currentChildNote.isNew) {
          response = await this.axios.post(`/api/notes/project/${this.projectId}/${this.data._id}`, formBody);
        } else {
          response = await this.axios.put(`/api/notes/${this.data._id}/${this.currentChildNote._id}`, formBody);
        }
        this.$emit("updateChildNotes", response.data);
        this.discard();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    handleClose(done) {
      let shouldPrompt = false;
      if (this.currentChildNote.isNew) {
        if (
          (this.currentChildNote.title && this.currentChildNote.title.length) ||
          (this.currentChildNote.body && this.currentChildNote.body.length)
        ) {
          shouldPrompt = true;
        }
      } else {
        if (
          this.currentChildNote.title !== this.data.title ||
          this.currentChildNote.body !== this.data.body ||
          (this.currentChildNote.pictures &&
            this.data.pictures &&
            this.currentChildNote.pictures.length !== this.data.pictures.length)
        ) {
          shouldPrompt = true;
        }
      }
      if (shouldPrompt) {
        MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonClass: "el-button--success",
        })
          .then(() => {
            this.saveChildNote();
            done();
          })
          .catch(() => {
            done();
          });
      } else {
        this.discard();
      }
    },
    discard() {
      this.isVisible = false;
      this.currentChildNote = {};
    },
  },
  watch: {
    data(newVal, oldVal) {
      if (newVal) {
        if (newVal.isNew) {
          this.fetchCurrentEmployeeImageSrc();
        }
        if (!oldVal || oldVal._id !== newVal._id) {
          this.timestamp = new Date().getTime();
        }
      }
    },
  },
  computed: {
    ...mapState("account", ["user"]),
    childNoteTitle() {
      if (this.currentChildNote._id) {
        return this.$t("Edit note");
      } else {
        return this.$t("New note");
      }
    },
    getAvatarSrc() {
      if (this.data.isNew) {
        return this.currentEmployeeImageSrc;
      } else {
        return `/api/employees/${this.data.authorEmployeeId}/picture`;
      }
    },
    getAuthorName() {
      if (this.data.isNew) {
        return `${this.user.name} (Sie)`;
      } else {
        return this.data.authorName;
      }
    },
  },
  filters: {
    formatDate(noteRecord) {
      if (noteRecord && noteRecord.updatedAt) {
        return moment(noteRecord.updatedAt).format("lll");
      } else {
        return "Jetzt";
      }
    },
  },
};
</script>

<style></style>
