<template>
  <div>
    <el-dialog
      :title="projectInfo.title"
      width="700px"
      :visible.sync="isVisible"
      center
      :before-close="dismissProjectInfo"
      @closed="discard"
    >
      <h6 class="title text-center">{{ $t("src.components.project.bryntumscheduler.projectinfomodal.bauleitung") }}</h6>
      <el-timeline v-if="actualConstructionManagers.length">
        <el-timeline-item
          :reverse="true"
          v-for="employee in actualConstructionManagers"
          :timestamp="formatDateRange(employee.dateRange)"
          :key="employee.idx"
        >
          <p v-html="formatEmployeeTitle(employee)"></p>
        </el-timeline-item>
      </el-timeline>
      <div class="empty-placeholder" v-else>
        {{ $t("src.components.project.bryntumscheduler.projectinfomodal.leer") }}
      </div>
      <div v-if="showDownloadReport">
        <template>
          <h6 class="title text-center">
            {{ $t("src.components.project.bryntumscheduler.projectinfomodal.pickampPack") }}
          </h6>
          <el-timeline v-if="pipResponse.length">
            <el-timeline-item
              :reverse="true"
              v-for="(item, idx) in pipResponse"
              :timestamp="formatDateRange(item.dateRange)"
              :key="idx"
            >
              <div class="flex align-center justify-between">
                <div>
                  {{ item.name }}
                </div>
                <div style="flex-shrink: 0" class="flex">
                  <div class="info-action" @click="viewFile(item)"><eye-icon /></div>
                  <div class="info-action" @click="downloadFile(item.url)"><download-icon /></div>
                  <div class="info-action" @click="print(item.url)">
                    <printer-icon />
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="empty-placeholder" v-else>
            {{ $t("src.components.project.bryntumscheduler.projectinfomodal.leer") }}
          </div>
        </template>
        <template>
          <h6 class="title text-center">
            {{ $t("src.components.project.bryntumscheduler.projectinfomodal.projectListe") }}
          </h6>
          <el-timeline v-if="prListResponse.length">
            <el-timeline-item
              :reverse="true"
              v-for="(item, idx) in prListResponse"
              :timestamp="formatDateRange(item.dateRange)"
              :key="idx"
            >
              <div class="flex align-center justify-between">
                <div>
                  {{ item.name }}
                </div>
                <div style="flex-shrink: 0" class="flex">
                  <div class="info-action" @click="viewFile(item)"><eye-icon /></div>
                  <div class="info-action" @click="downloadFile(item.url)"><download-icon /></div>
                  <div class="info-action" @click="print(item.url)">
                    <printer-icon />
                  </div>
                </div>
              </div>
            </el-timeline-item>
          </el-timeline>
          <div class="empty-placeholder" v-else>
            {{ $t("src.components.project.bryntumscheduler.projectinfomodal.leer") }}
          </div>
        </template>
      </div>
      <div class="text-right" v-if="showBulkProlong">
        <el-button @click.prevent="$emit('onProlongClicked', projectInfoId)">
          Verkürzung/Verlängerung von Ressourcen
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { Dialog, Timeline, TimelineItem, Button, ButtonGroup, Message } from "element-ui";
import { moment } from "src/config/moment";
import printJs from "print-js";
import DownloadIcon from "vue-material-design-icons/Download";
import PrinterIcon from "vue-material-design-icons/PrinterOutline";
import EyeIcon from "vue-material-design-icons/EyeOutline";
import { mapActions } from "vuex";

// dismissModal
export default {
  name: "project-info-modal",
  props: {
    projectInfoId: String,
    projects: Array,
    employees: Array,
    isViewOnly: Boolean,
    viewDateRange: {
      startDate: Date,
      endDate: Date,
    },
    showBulkProlong: Boolean,
  },
  components: {
    PrinterIcon,
    EyeIcon,
    DownloadIcon,
    [Dialog.name]: Dialog,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Button.name]: Button,
    [Message.name]: Message,
    [ButtonGroup.name]: ButtonGroup,
  },
  data() {
    return {
      isVisible: false,
      pipResponse: [],
      prListResponse: [],
      positionOptions: [
        { id: "CONSTRUCTION_MANAGER", label: "Bauleiter" },
        { id: "RESPONSIBLE_EMPLOYEE", label: "Verantwortlicher Mitarbeiter" },
      ],
    };
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    async fetchLatestReports() {
      const dateRange = [
        moment(this.viewDateRange.startDate).format("YYYY-MM-DD"),
        moment(this.viewDateRange.endDate).format("YYYY-MM-DD"),
      ];
      const pipResponse = await this.axios.post("/api/reports/get-generated", {
        reportType: "pick_pack",
        projectId: this.projectInfoId,
        dateRange: dateRange,
      });
      const prListResponse = await this.axios.post("/api/reports/get-generated", {
        reportType: "project_list",
        projectId: this.projectInfoId,
        dateRange: dateRange,
      });
      this.pipResponse = pipResponse.data;
      this.prListResponse = prListResponse.data;
    },
    formatEmployeeTitle(record) {
      const { employee, position } = record;
      const employeeRecord = this.employees.find((item) => item.id === employee);
      let employeeName;
      if (employeeRecord) {
        employeeName = employeeRecord.title;
      } else {
        employeeName = employee;
      }

      const positionRecord = this.positionOptions.find((item) => item.id === position) || {};
      return `<b>${employeeName}</b> (${positionRecord.label})`;
    },
    dismissProjectInfo() {
      this.$emit("dismissModal");
    },
    viewFile(record) {
      if (record.url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + record.url;
        this.openPdf({ url: completeUrl, fileName: record.name });
      } else {
        window.open(`${url}?view=true`, "_blank");
      }
    },
    downloadFile(url) {
      window.open(url, "_blank");
    },
    async print(url) {
      try {
        const fileResponse = await this.axios.get(url, { responseType: "blob" });
        const objectUrl = URL.createObjectURL(fileResponse.data);
        printJs({
          printable: objectUrl,
          type: "pdf",
          onError: (err) => {
            throw err;
          },
          onPrintDialogClose: () => URL.revokeObjectURL(objectUrl),
        });
        // const newWindow = window.open(objectUrl, "_blank");
        // window.URL.revokeObjectURL(objectUrl);
        // const link = document.createElement("a");
        // link.href = objectUrl;
        // link.target = "_self";
        // link.download = file.name;
        // document.body.appendChild(link);
        // link.click();
        // document.body.removeChild(link);
        Message({
          message: "PDF erstellt",
          type: "success",
        });
      } catch (error) {
        Message({
          message: error.message,
          type: "error",
        });
        throw error;
      }
    },
    formatDateRange(dateRange) {
      if (dateRange.length) {
        return `${moment(dateRange[0]).format("L")}-${moment(dateRange[1]).format("L")}`;
      }
      return "";
    },
    discard() {
      this.pipResponse = [];
      this.prListResponse = [];
    },
  },
  computed: {
    projectInfo() {
      if (!this.projects || !this.projectInfoId) {
        return {};
      }
      return this.projects.find((item) => item.id === this.projectInfoId) || {};
    },
    showDownloadReport() {
      return !this.projectInfo.isWorkshop;
    },
    actualConstructionManagers() {
      const projectInfo = this.projectInfo;
      if (projectInfo && projectInfo.constructionManagers && projectInfo.constructionManagers.length) {
        // filter records in the past
        const momentStartDate = moment(this.viewDateRange.startDate);
        const momentEndDate = moment(this.viewDateRange.endDate).subtract(1, "hour").endOf("day");
        return projectInfo.constructionManagers.filter((item) => {
          if (item.dateRange && item.dateRange.length) {
            return (
              momentStartDate.isSameOrBefore(item.dateRange[1], "day") &&
              momentEndDate.isSameOrAfter(item.dateRange[0], "day")
            );
          }
          return false;
        });
      }
      return [];
    },
  },
  watch: {
    projectInfoId: function (newVal, oldVal) {
      if (!newVal) {
        this.isVisible = false;
      } else {
        this.isVisible = true;
        this.fetchLatestReports();
      }
    },
  },
};
</script>

<style>
.empty-placeholder {
  padding: 10px;
  text-align: center;
  color: rgb(180, 180, 180);
}
.label-no-margins label.el-form-item__label {
  margin: 0;
  padding: 0;
}
.info-action {
  color: #6f7379;
  cursor: pointer;
  padding: 4px;
  margin: 0 5px;
  font-size: 1.25rem;
}
</style>
