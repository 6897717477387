<template>
  <el-drawer
    :wrapperClosable="false"
    :withHeader="false"
    :visible.sync="visible"
    direction="rtl"
    :destroy-on-close="true"
    :before-close="handleClose"
    @closed="discard"
    size="630px"
  >
    <div style="display: flex; flex-direction: column; height: 100vh; overflow: hidden" v-loading="loading">
      <div
        class="container"
        style="padding: 0 30px; display: flex; flex-direction: column; height: 100vh; overflow: hidden"
      >
        <div class="drawer-back" @click="visible = false">
          <chevron-left-icon />{{ $t("src.components.generic.supplierarticles.supplierarticledetails.sourcing") }}
        </div>
        <div class="drawer-info">
          <h5 class="drawer-name">{{ supplierTitle }}</h5>
          <edit-profile-button v-if="!localEditMode && $can('update', 'sourcing')" @click="localEditMode = true">{{
            $t("src.components.generic.supplierarticles.supplierarticledetails.bearbeiten")
          }}</edit-profile-button>
        </div>
        <el-form
          label-position="top"
          :model="localSupplierArticle"
          ref="form"
          class="drawer-form"
          :rules="formDataRules"
        >
          <div class="drawer-subtitle">
            {{ $t("src.components.generic.supplierarticles.supplierarticledetails.artikeldaten") }}
          </div>
          <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
            <el-col :span="12">
              <el-form-item>
                <profile-input
                  :value="localSupplierArticle.supplier && localSupplierArticle.supplier.uid"
                  :label="$t('src.components.generic.supplierarticles.supplierarticledetails.lieferantennummer')"
                  :editMode="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item prop="deviation">
                <profile-switch
                  :title="$t('src.components.generic.supplierarticles.supplierarticledetails.switcherTitle')"
                  v-model="localSupplierArticle.deviation"
                  :disabled="!localEditMode"
                  :active-text="$t('src.components.generic.supplierarticles.supplierarticledetails.abweichung')"
                  :inactive-text="$t('src.components.generic.supplierarticles.supplierarticledetails.keineAbweichung')"
                  inactive-color="#18ce68"
                  active-color="#ff6868"
                  :colorizeActive="false"
                  :active-value="true"
                  :inactive-value="false"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <profile-input
                  v-model="localSupplierArticle.articleNum"
                  :label="$t('src.components.generic.supplierarticles.supplierarticledetails.lieferantenArtikelNummer')"
                  :editMode="localEditMode"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item>
                <profile-input
                  v-model="localSupplierArticle.articleDescr"
                  :label="
                    $t('src.components.generic.supplierarticles.supplierarticledetails.lieferantenArtikelBezeichnung')
                  "
                  :editMode="localEditMode"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-divider></el-divider>
          <perfect-scrollbar
            style="height: calc(100vh - 448px); padding-right: 10px"
            :options="{ suppressScrollX: true }"
          >
            <div v-if="localSupplierArticle.deviation">
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      v-model="localSupplierArticle.manufacturer"
                      :label="$t('src.components.generic.supplierarticles.supplierarticledetails.hersteller')"
                      :editMode="localEditMode && localSupplierArticle.deviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      v-model="localSupplierArticle.manufacturerArticleDesc"
                      :label="
                        $t(
                          'src.components.generic.supplierarticles.supplierarticledetails.herstellerArtikelBezeichnung'
                        )
                      "
                      :editMode="localEditMode && localSupplierArticle.deviation"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      v-model="localSupplierArticle.manufacturerArticleNum"
                      :label="
                        $t('src.components.generic.supplierarticles.supplierarticledetails.herstellerArtikelNummer')
                      "
                      :editMode="localEditMode && localSupplierArticle.deviation"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider></el-divider>
            </div>
            <!-- SUPPLY-SPECIFIC FIELDS START -->
            <div v-if="resourceType === 'supply'">
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      :label="
                        $t('src.components.generic.supplierarticles.supplierarticledetails.gebindeVerpackungseinheit')
                      "
                      showDisabledInput
                      v-model="localSupplierArticle.bundleAmount"
                      :editMode="localEditMode"
                    >
                      <template v-slot:append>
                        <el-select
                          :disabled="!localEditMode"
                          v-model="localSupplierArticle.bundleUnit"
                          style="width: 130px"
                          clearable
                          @clear="localSupplierArticle.bundleUnit = null"
                        >
                          <el-option v-for="unit in units" :key="unit._id" :value="unit._id" :label="unit.label" />
                        </el-select>
                      </template>
                    </profile-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      :label="$t('src.components.generic.supplierarticles.supplierarticledetails.abrechnungseinheit')"
                      v-model="localSupplierArticle.accountingAmount"
                      :editMode="localEditMode"
                      showDisabledInput
                    >
                      <template v-slot:append>
                        <el-select
                          :disabled="!localEditMode"
                          v-model="localSupplierArticle.accountingUnit"
                          style="width: 130px"
                          clearable
                          @clear="localSupplierArticle.accountingUnit = null"
                        >
                          <el-option v-for="unit in units" :key="unit._id" :value="unit._id" :label="unit.label" />
                        </el-select>
                      </template>
                    </profile-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      :label="$t('src.components.generic.supplierarticles.supplierarticledetails.maeinheit')"
                      v-model="localSupplierArticle.measurementAmount"
                      :editMode="localEditMode"
                      showDisabledInput
                    >
                      <template v-slot:append>
                        <el-select
                          :disabled="!localEditMode"
                          v-model="localSupplierArticle.measurementUnit"
                          style="width: 130px"
                          clearable
                          @clear="localSupplierArticle.measurementUnit = null"
                        >
                          <el-option v-for="unit in units" :key="unit._id" :value="unit._id" :label="unit.label" />
                        </el-select>
                      </template>
                    </profile-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item>
                    <profile-input
                      :label="$t('src.components.generic.supplierarticles.supplierarticledetails.mindestbestellmenge')"
                      v-model="localSupplierArticle.minOrderQuantity"
                      :editMode="localEditMode"
                      showDisabledInput
                    >
                      <template v-slot:append>
                        <el-select
                          :disabled="!localEditMode"
                          v-model="localSupplierArticle.minOrderUnit"
                          style="width: 130px"
                          clearable
                          @clear="localSupplierArticle.minOrderUnit = null"
                        >
                          <el-option v-for="unit in units" :key="unit._id" :value="unit._id" :label="unit.label" />
                        </el-select>
                      </template>
                    </profile-input>
                  </el-form-item>
                </el-col>
              </el-row>
              <el-divider />
            </div>
            <!-- SUPPLY-SPECIFIC FIELDS END -->
            <!-- PRICE CALCULATION START -->
            <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
              <el-col :span="11">
                <el-form-item class="custom-suffix">
                  <profile-input
                    :value="newPrice.listPrice"
                    @input="handlePriceChange('listPrice', $event)"
                    :label="
                      $t(
                        'src.components.generic.supplierarticles.supplierarticledetails.aktuellerListenpreisAbrechnungseinheit'
                      )
                    "
                    :editMode="localEditMode"
                    viewModeSuffix="€"
                  >
                    <template #suffix>
                      <div>&#8364;<!-- Euro sign --></div>
                    </template>
                  </profile-input>
                </el-form-item>
                <el-form-item class="custom-suffix">
                  <profile-input
                    :value="newPrice.discount"
                    @input="handlePriceChange('discount', $event)"
                    :label="$t('src.components.generic.supplierarticles.supplierarticledetails.aktuellerRabatt')"
                    :editMode="localEditMode"
                    viewModeSuffix="%"
                  >
                    <template #suffix>
                      <div>%</div>
                    </template>
                  </profile-input>
                </el-form-item>
                <el-form-item class="custom-suffix">
                  <profile-input
                    :value="newPrice.purchasingPrice"
                    @input="handlePriceChange('purchasingPrice', $event)"
                    :label="
                      $t(
                        'src.components.generic.supplierarticles.supplierarticledetails.aktuellerEinkaufspreisAbrechnungseinheit'
                      )
                    "
                    :editMode="localEditMode"
                    viewModeSuffix="€"
                  >
                    <template #suffix>
                      <div>&#8364;</div>
                    </template>
                  </profile-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <div class="text-right">
                  <el-button
                    @click="showHistory = !showHistory"
                    :icon="showHistory ? 'el-icon-arrow-down' : 'el-icon-arrow-up'"
                    type="text"
                    size="small"
                    class="outline-none"
                    >{{ $t("src.components.generic.supplierarticles.supplierarticledetails.history") }}</el-button
                  >
                </div>
                <el-collapse-transition>
                  <div v-show="showHistory">
                    <div v-if="localSupplierArticle.price">
                      <el-timeline reverse>
                        <el-timeline-item
                          v-for="(price, index) in localSupplierArticle.price"
                          :key="index"
                          :timestamp="formatDate(price.createdAt)"
                        >
                          <el-card :body-style="{ padding: '10px', position: 'relative' }" shadow="never">
                            <p>
                              <b>{{
                                $t("src.components.generic.supplierarticles.supplierarticledetails.listenpreis")
                              }}</b
                              >{{
                                $t("src.components.generic.supplierarticles.supplierarticledetails.9162", {
                                  "1": numberToString(price.listPrice),
                                })
                              }}
                            </p>
                            <p>
                              <b>{{ $t("src.components.generic.supplierarticles.supplierarticledetails.rabatt") }}</b
                              >{{
                                $t("src.components.generic.supplierarticles.supplierarticledetails.2206", {
                                  "1": numberToString(price.discount),
                                })
                              }}
                            </p>
                            <p>
                              <b>{{
                                $t("src.components.generic.supplierarticles.supplierarticledetails.einkaufspreis")
                              }}</b
                              >{{
                                $t("src.components.generic.supplierarticles.supplierarticledetails.8933", {
                                  "1": numberToString(price.purchasingPrice),
                                })
                              }}
                            </p>
                            <div v-if="localEditMode" class="timeline-delete-btn" @click="deletePrice(price._id)">
                              <trash-can-outline-icon />
                            </div>
                          </el-card>
                        </el-timeline-item>
                      </el-timeline>
                    </div>
                    <div v-else style="padding: 10px; text-align: center; color: ">
                      {{ $t("src.components.generic.supplierarticles.supplierarticledetails.keineDaten") }}
                    </div>
                  </div>
                </el-collapse-transition>
              </el-col>
            </el-row>
            <!-- PRICE CALCULATION END -->
            <el-divider />
            <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
              <el-col :span="12">
                <el-form-item>
                  <profile-select
                    v-model="localSupplierArticle.deliveryConditions"
                    :multiple="false"
                    :title="$t('src.components.generic.supplierarticles.supplierarticledetails.lieferkonditionen')"
                    :editMode="localEditMode"
                    size="small"
                    :items="deliveryConditionOptions"
                  />
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item class="custom-suffix">
                  <profile-input
                    v-if="localEditMode"
                    v-model="localSupplierArticle.deliveryConditionsPrice"
                    :label="$t('src.components.generic.supplierarticles.supplierarticledetails.lieferkonditionenPreis')"
                    :editMode="localEditMode"
                    viewModeSuffix="€"
                  >
                    <template #suffix>
                      <div>&#8364;<!-- Euro sign --></div>
                    </template>
                  </profile-input>
                  <div class="custom-suffix" v-else>
                    <profile-input
                      :value="
                        localSupplierArticle.deliveryConditionsPrice &&
                        localSupplierArticle.deliveryConditionsPrice.toString().replace('.', ',')
                      "
                      :label="
                        $t('src.components.generic.supplierarticles.supplierarticledetails.lieferkonditionenPreis')
                      "
                      :editMode="localEditMode"
                      viewModeSuffix="€"
                    >
                      <template #suffix>
                        <div>&#8364;<!-- Euro sign --></div>
                      </template>
                    </profile-input>
                  </div>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item>
                  <profile-input
                    v-model="localSupplierArticle.termsOfPayment"
                    :label="$t('src.components.generic.supplierarticles.supplierarticledetails.zahlungsbedingungen')"
                    :editMode="localEditMode"
                    type="textarea"
                    :rows="2"
                  >
                  </profile-input>
                </el-form-item>
              </el-col>
              <el-col :span="24" class="mb-2">
                <drawer-upload
                  :data="{ metadata: getMetadata() }"
                  :on-remove="removeFile"
                  :on-success="fileUploaded"
                  location="SUPPLIER_ARTICLES"
                  :file-list="localSupplierArticle.documents"
                  :disabled="!localEditMode"
                />
              </el-col>
            </el-row>
            <div class="mb-2">
              <profile-switch
                :title="$t('src.components.generic.supplierarticles.supplierarticledetails.aktiv')"
                :disabled="!localEditMode"
                v-model="localSupplierArticle.active"
                :active-text="$t('src.components.generic.supplierarticles.supplierarticledetails.ja')"
                :inactive-text="$t('src.components.generic.supplierarticles.supplierarticledetails.nein')"
                colorizeActive
              />
            </div>
          </perfect-scrollbar>
        </el-form>
      </div>
      <div class="drawer-footer" v-if="localEditMode">
        <el-button @click="exit">{{
          $t("src.components.generic.supplierarticles.supplierarticledetails.abbrechen")
        }}</el-button>
        <el-button type="primary" @click="submit" :loading="loading">{{
          $t("src.components.generic.supplierarticles.supplierarticledetails.speichern")
        }}</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { isEmpty, get, last } from "lodash";
import { moment } from "src/config/moment";
import { isDataChanged } from "src/utils/isDataChanged.js";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import {
  Drawer,
  Input,
  Button,
  Form,
  FormItem,
  MessageBox,
  Message,
  Switch,
  Divider,
  Timeline,
  TimelineItem,
  Tooltip,
} from "element-ui";
import CollapseTransition from "element-ui/lib/transitions/collapse-transition";
import Pencil from "vue-material-design-icons/Pencil";
import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import ProfileInput from "../../UIComponents/Inputs/ProfileInput.vue";
import ProfileSelect from "../../UIComponents/Inputs/ProfileSelect.vue";
import EditProfileButton from "../../UIComponents/Buttons/EditProfileButton.vue";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import { stringToNumber } from "src/utils/stringToNumber";
import DrawerUpload from "../../Project/Invoices/DrawerUpload.vue";

const INITIAL_SUPPLIER = () => ({ active: true, supplier: {} });
const INITIAL_PRICE = () => ({
  initial: true,
  listPrice: "",
  discount: "",
  purchasingPrice: "",
});

export default {
  name: "supplier-article-details",
  components: {
    PerfectScrollbar,
    ProfileInput,
    ProfileSelect,
    EditProfileButton,
    DrawerUpload,
    [TrashCanOutline.name]: TrashCanOutline,
    [CollapseTransition.name]: CollapseTransition,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [Tooltip.name]: Tooltip,
    [Switch.name]: Switch,
    [Drawer.name]: Drawer,
    [Divider.name]: Divider,
    [Input.name]: Input,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [MessageBox.name]: MessageBox,
    [Message.name]: Message,
    [Pencil.name]: Pencil,
    [ChevronLeft.name]: ChevronLeft,
  },
  props: {
    resourceType: String,
    articleId: String,
    isEditMode: Boolean,
    units: { type: Array },
    deliveryConditionOptions: { type: Array },
  },
  data() {
    return {
      isDirty: false,
      _preservedData: {},
      showHistory: false,
      formDataRules: {},
      localEditMode: false,
      visible: false,
      localSupplierArticle: INITIAL_SUPPLIER(),
      loading: false,
      newPrice: INITIAL_PRICE(),
    };
  },
  methods: {
    async fetch(articleId) {
      try {
        this.loading = true;
        const response = await this.axios.get(`/api/supplier-articles/${articleId}`);
        this.localSupplierArticle = response.data;
      } catch (error) {
        throw error;
      } finally {
        this.loading = false;
      }
    },
    checkDirtyFlag() {
      this.isDirty = isDataChanged(this._preservedData, this.localSupplierArticle);
    },
    discardDirty() {
      this.isDirty = false;
    },
    formatDate(dateString) {
      return moment(dateString).format("lll");
    },
    handleClose(done) {
      if (this.isDirty) {
        MessageBox.confirm("Sie haben noch nicht gespeicherte Daten, fahren Sie fort?", {
          type: "warning",
        })
          .then((_) => {
            done();
            return _;
          })
          .then(() => {
            Object.assign(this, {
              localEditMode: false,
              localSupplierArticle: INITIAL_SUPPLIER(),
            });
          })
          .catch((_) => {});
      } else {
        Object.assign(this, {
          localEditMode: false,
          visible: false,
          localSupplierArticle: INITIAL_SUPPLIER(),
        });
      }
    },
    discard() {
      this.$emit("closed");
      this.visible = false;
      this.localSupplierArticle = INITIAL_SUPPLIER();
    },
    exit() {
      Object.assign(this, {
        localEditMode: false,
        showHistory: false,
        visible: false,
        localSupplierArticle: INITIAL_SUPPLIER(),
        newPrice: INITIAL_PRICE(),
      });
      this.discard();
    },
    enableAddPrice() {
      // TODO: to separate component
      this.priceIsAdding = true;
      this.localSupplierArticle.listPrice.push({ value: "" });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (!valid) {
          return false;
        }
        this.updateArticleData();
      });
    },
    deletePrice(priceId) {
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/supplier-articles/${this.articleId}/price/${priceId}`);
          const priceItemIdx = this.localSupplierArticle.price.findIndex((item) => item._id === priceId);
          if (priceItemIdx !== -1) {
            this.localSupplierArticle.price.splice(priceItemIdx, 1);
          }
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    async updateArticleData() {
      try {
        this.loading = true;
        const formData = {
          pricesToDelete: this.pricesToDelete,
          updatedData: this.localSupplierArticle,
        };
        // set new price only if it's changed
        if (!this.newPrice.initial) {
          // drop initial from calculation
          const { initial, ...newPrice } = this.newPrice;
          // convert strings with "," as decimal delimiter to numbers
          formData.newPrice = Object.entries(newPrice).reduce((obj, entry) => {
            obj[entry[0]] = stringToNumber(entry[1]);
            return obj;
          }, {});
        }
        if (this.localSupplierArticle.deliveryConditionsPrice) {
          formData.updatedData.deliveryConditionsPrice = stringToNumber(
            this.localSupplierArticle.deliveryConditionsPrice || "0"
          );
        }
        const response = await this.axios.put(`/api/supplier-articles/${this.articleId}`, formData);
        Message.success("Lieferant gespeichert");
        this.$emit("onSupplierSave", response.data);
        this.exit();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async updateFiles() {
      try {
        const formData = {
          updatedData: { ...this.localSupplierArticle, documents: this.localSupplierArticle.documents },
        };
        const response = await this.axios.put(`/api/supplier-articles/${this.articleId}`, formData);
        Message.success("Lieferant datei gespeichert");
        this.$emit("onSupplierSave", response.data);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    getMetadata() {
      if (this.localSupplierArticle) {
        return `${this.localSupplierArticle.resourceType}_${this.localSupplierArticle.resourceId}_supplierArticle_${this.articleId}`;
      } else {
        return null;
      }
    },
    fileUploaded(response, file, fileList) {
      this.localSupplierArticle.documents = this.localSupplierArticle.documents.concat(response);
      this.updateFiles();
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.localSupplierArticle.documents = this.localSupplierArticle.documents.filter(
          (item) => item._id !== file._id
        );
        this.updateFiles();
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    numberToString(n) {
      if (n) {
        return Number(n).toLocaleString("de-DE", { maximumFractionDigits: 2, minimumFractionDigits: 2 });
      } else {
        return n;
      }
    },
    setInitialPrice() {
      const lastPriceRecord = last(this.localSupplierArticle.price);
      this.newPrice = {
        initial: true,
        listPrice: this.numberToString(get(lastPriceRecord, "listPrice", "")),
        discount: this.numberToString(get(lastPriceRecord, "discount", "")),
        purchasingPrice: this.numberToString(get(lastPriceRecord, "purchasingPrice", "")),
      };
    },
    handlePriceChange(namespace, value) {
      this.newPrice[namespace] = value.toLocaleString("de-DE");
      this.newPrice.initial = false;
      this.calculatePriceDataBy(namespace);
    },
    calculatePriceDataBy(namespace) {
      const listPrice = this.newPrice.listPrice ? stringToNumber(this.newPrice.listPrice) : 0;
      const discount = this.newPrice.discount ? stringToNumber(this.newPrice.discount) : 0;
      if (namespace === "listPrice" || namespace === "discount") {
        this.newPrice.purchasingPrice = Number(listPrice - listPrice * (discount / 100)).toLocaleString("de-DE", {
          maximumFractionDigits: 2,
        });
      } else if (namespace === "purchasingPrice") {
        this.newPrice.discount = Number(
          (1 - stringToNumber(this.newPrice.purchasingPrice || "0") / listPrice) * 100
        ).toLocaleString("de-DE", {
          maximumFractionDigits: 2,
        });
      }
    },
    async showArticleDetails(articleId) {
      this.visible = true;
      await this.fetch(articleId);
      this.setInitialPrice();
      // methods for handling prompt message for unsaved data
      this._preservedData = JSON.parse(JSON.stringify(this.localSupplierArticle)); // preserve initial data to check for changes
      this.checkDirtyFlag(this.localSupplierArticle);
    },
  },
  computed: {
    supplierTitle() {
      return get(this.localSupplierArticle, "supplier.name", "");
    },
  },
  watch: {
    localSupplierArticle: {
      handler: function (newVal, oldVal) {
        this.checkDirtyFlag(newVal);
      },
      deep: true,
    },
    isEditMode(newVal) {
      this.localEditMode = newVal;
    },
  },
};
</script>

<style lang="scss">
button.outline-none:focus {
  outline: none;
}
.timeline-delete-btn {
  font-size: 18px;
  cursor: pointer;
  float: right;
}
.drawer-form .el-form-item {
  margin-bottom: 10px;
}
.custom-suffix {
  .el-input__suffix {
    padding: 0 3px;
    color: #606266;
  }
  input {
    text-align: right;
  }
}
</style>
