<template>
  <div>
    <form>
      <span class="n-profile-title" style="margin-left: 0">{{ tabName }}</span>

      <div class="card-body" style="padding-left: 0; padding-right: 10px">
        <el-row :gutter="20" type="flex" justify="space-between">
          <!--  -->
          <el-col :span="12" style="display: flex; flex-wrap: nowrap">
            <template v-if="computedEditMode">
              <el-col style="flex-shrink: 0; width: 140px">
                <!-- AVATAR -->
                <avatar-uploader
                  class="n-grid-container-edit-avatar"
                  :isEditMode="computedEditMode"
                  :actionURI="actionURI"
                  :avatar="projectEntity.picture"
                  @avatar-changed="setPicture"
                  :name="'project_' + projectEntity.id + '_picture_0'"
                />
                <div style="width: 140px">
                  <profile-switch
                    :title="$t('src.components.project.profile.aktiv')"
                    :disabled="!computedEditMode"
                    v-model="projectEntity.active"
                    :active-text="$t('src.components.project.profile.ja')"
                    :inactive-text="$t('src.components.project.profile.nein')"
                    colorizeActive
                  />
                </div>
              </el-col>
              <el-col style="flex-grow: 1">
                <el-row>
                  <el-col>
                    <!-- NAME -->
                    <profile-input
                      required
                      rules="required"
                      v-model="projectEntity.bvName"
                      label="BV Name"
                      :editMode="computedEditMode"
                      style="width: 100%; max-width: 350px"
                      name="BV-Name"
                    />
                  </el-col>

                  <el-col style="width: 50px">
                    <!-- COLOR PICKER -->
                    <span class="n-profile-label-title">{{ $t("src.components.project.profile.farbe") }}</span
                    ><br />
                    <div
                      v-if="projectEntity.constructionPhase > 1"
                      class="project-color-view"
                      :style="{
                        border: '1px solid black',
                        width: '30px',
                        height: '30px',
                        backgroundColor: projectEntity.color,
                      }"
                    ></div>
                    <el-color-picker
                      v-else
                      size="medium"
                      :disabled="!computedEditMode"
                      v-model="projectEntity.color"
                      :predefine="predefinedColors"
                    ></el-color-picker>
                  </el-col>
                </el-row>
              </el-col>
            </template>
            <template v-else>
              <el-col style="flex-shrink: 0; width: 140px">
                <!-- AVATAR -->
                <avatar-uploader
                  class="n-grid-container-view-avatar n-grid-container-view"
                  :isEditMode="computedEditMode"
                  :actionURI="actionURI"
                  :avatar="projectEntity.picture"
                  @avatar-changed="setPicture"
                  :name="'project_' + projectEntity.id + '_picture_0'"
                />
              </el-col>
              <el-col :lg="6" :md="8">
                <div class="n-profile-name">
                  <span class="n-profile-label-title">{{ $t("src.components.project.profile.name") }}</span
                  ><br />
                  {{ projectEntity.bvName }}
                </div>
                <div style="display: flex">
                  <!-- AKTIV/INAKTIV -->
                  <div style="display: flex; align-items: center; width: 80px" class="n-profile-name-light">
                    <el-badge class="profile" :type="projectEntity.active ? 'success' : 'danger'" is-dot />
                    {{ projectEntity.active ? "Aktiv" : "Inaktiv" }}
                  </div>
                  <!-- COLOR PICKER -->
                  <div style="width: 60px; display: inline-block">
                    <span class="n-profile-label-title">{{ $t("src.components.project.profile.farbe") }}</span
                    ><br />
                    <div
                      v-bind:style="{
                        border: '1px solid black',
                        width: '30px',
                        height: '30px',
                        backgroundColor: projectEntity.color,
                      }"
                    />
                  </div>
                </div>
              </el-col>
            </template>
          </el-col>
          <el-col :span="12" style="display: flex; justify-content: flex-start">
            <project-status
              :isEditMode="computedEditMode"
              :progress="projectEntity.progress"
              :progressDates="projectEntity.progressDates"
              v-on:updateProgress="updateProgress"
            />
          </el-col>
        </el-row>

        <hr />

        <p class="profile-title">{{ $t("src.components.project.profile.allgemein") }}</p>
        <el-row :gutter="20">
          <el-col :md="12">
            <div class="form-group">
              <profile-select
                :title="$t('src.components.project.profile.projekttypen')"
                :editMode="computedEditMode"
                v-model="projectEntity.projectTypes"
                :placeholder="$t('src.components.project.profile.projekttypen')"
                valueIdentifier="_id"
                label="name"
                :clearable="true"
                :items="options['projectTypes']"
                disable-inactive
              />
            </div>
          </el-col>
        </el-row>
        <div class="form-group">
          <profile-input
            type="textarea"
            :editMode="computedEditMode"
            name="BV-Beschreibung"
            :label="$t('src.components.project.profile.bvBeschreibung')"
            :autosize="{ minRows: 4 }"
            required
            rules="required"
            v-model="projectEntity.bvDescription"
          ></profile-input>
        </div>
        <core-services-table
          :coreServicesOptions="options['coreServices']"
          :coreServices="projectEntity.coreServices"
          :isEditMode="computedEditMode"
        />
        <hr />
        <p class="profile-title">{{ $t("src.components.project.profile.ansprechpartner") }}</p>
        <div class="form-group">
          <project-client-contact-record
            :projectId="project.id"
            :contactData="project.client"
            :allContacts="allContacts"
            :title="$t('src.components.project.profile.auftraggeber')"
            :disabled="!computedEditMode"
            @setContact="setContact('client', $event)"
            @deleteFile="addFileToDelete"
          />
          <el-row :gutter="20">
            <!-- <el-col :md="6">
              <profile-input
                :editMode="computedEditMode"
                :label="$t('src.components.project.profile.auftraggeber')"
                required
                rules="required"
                :name="$t('src.components.project.profile.auftraggeber')"
                :placeholder="$t('src.components.project.profile.auftraggeber')"
                v-model="projectEntity.client"
              ></profile-input>
            </el-col> -->
            <el-col :md="6">
              <profile-input
                :editMode="computedEditMode"
                :label="$t('src.components.project.profile.hauptAuftraggeber')"
                :name="$t('src.components.project.profile.hauptAuftraggeber')"
                :placeholder="$t('src.components.project.profile.hauptAuftraggeber')"
                v-model="projectEntity.mainClient"
              ></profile-input>
            </el-col>
            <el-col :md="6">
              <profile-input
                :editMode="computedEditMode"
                :name="$t('src.components.project.profile.bauherr')"
                :label="$t('src.components.project.profile.bauherr')"
                :placeholder="$t('src.components.project.profile.bauherr')"
                v-model="projectEntity.owner"
              ></profile-input>
            </el-col>
          </el-row>
        </div>
        <hr />

        <p class="profile-title">{{ $t("src.components.project.profile.bvAdressdaten") }}</p>
        <div class="form-group">
          <el-row :gutter="20">
            <el-col :md="6">
              <profile-input
                :editMode="computedEditMode"
                type="text"
                :name="$t('src.components.project.profile.bvObjekt')"
                :label="$t('src.components.project.profile.bvObjekt')"
                :placeholder="$t('src.components.project.profile.bvObjekt')"
                v-model="projectEntity.hrClient.bvObject"
              />
            </el-col>
            <el-col :md="6">
              <profile-input
                :editMode="computedEditMode"
                type="text"
                :name="$t('src.components.project.profile.adresszusatz')"
                :label="$t('src.components.project.profile.adresszusatz')"
                :placeholder="$t('src.components.project.profile.adresszusatz')"
                v-model="projectEntity.hrClient.addressAddition"
              />
            </el-col>
          </el-row>
        </div>
        <div class="form-group">
          <el-row :gutter="20">
            <el-col :md="6">
              <profile-input
                :editMode="computedEditMode"
                :name="$t('src.components.project.profile.strae')"
                :label="$t('src.components.project.profile.strae')"
                :placeholder="$t('src.components.project.profile.strae')"
                v-model="projectEntity.hrClient.street"
              />
            </el-col>
            <el-col :md="3">
              <profile-input
                :editMode="computedEditMode"
                :name="$t('src.components.project.profile.hausnummer')"
                :label="$t('src.components.project.profile.hausnummer')"
                :placeholder="$t('src.components.project.profile.nr')"
                v-model="projectEntity.hrClient.no"
              />
            </el-col>
            <el-col :md="3">
              <profile-input
                :editMode="computedEditMode"
                :name="$t('src.components.project.profile.plz')"
                :label="$t('src.components.project.profile.plz')"
                :placeholder="$t('src.components.project.profile.plz')"
                v-model="projectEntity.hrClient.zip"
              />
            </el-col>
            <el-col :md="6"
              ><profile-input
                :editMode="computedEditMode"
                :name="$t('src.components.project.profile.stadt')"
                :label="$t('src.components.project.profile.stadt')"
                :placeholder="$t('src.components.project.profile.stadt')"
                v-model="projectEntity.hrClient.city"
              />
            </el-col>
          </el-row>
        </div>

        <hr />
        <el-row>
          <el-col :span="12">
            <p class="profile-title">{{ $t("src.components.project.profile.zeitrume") }}</p>
          </el-col>
          <el-col :span="12" class="text-right">
            <profile-radio-group
              class="mr-2"
              v-model="hideOldTimes"
              :items="[
                { label: 'Alle Einträge', value: false },
                { label: 'Aktuelle Einträge', value: true },
              ]"
            />
          </el-col>
        </el-row>

        <div class="form-group">
          <el-row :gutter="20">
            <el-col :sm="12">
              <!-- <span class="n-profile-label-title">{{ $t('src.components.project.profile.projektlaufzeit') }}</span>
              <br /> -->
              <profile-date-picker
                v-if="!project.id"
                :isEditMode="computedEditMode"
                style="max-width: 100%; min-width: 100%"
                v-model="projectEntity.dateRange"
                :title="$t('src.components.project.profile.projektlaufzeit')"
                required
                rules="required"
                is-range
                :placeholder="$t('src.components.project.profile.projektlaufzeit')"
                :name="$t('src.components.project.profile.projektlaufzeit')"
              />
              <template v-else>
                <project-date-change
                  v-if="computedEditMode"
                  :dateRange="projectEntity.dateRange"
                  :projectId="project.id"
                  :projectName="project.bvName"
                  @onChange="handleDateChange"
                />
                <profile-date-picker
                  v-else
                  :isEditMode="false"
                  style="max-width: 100%; min-width: 100%"
                  v-model="projectEntity.dateRange"
                  :title="$t('src.components.project.profile.projektlaufzeit')"
                  required
                  rules="required"
                  is-range
                  :placeholder="$t('src.components.project.profile.projektlaufzeit')"
                  :name="$t('src.components.project.profile.projektlaufzeit')"
                />
              </template>
            </el-col>
            <el-col :sm="12">
              <working-times
                :hideOld="hideOldTimes"
                :isEditMode="computedEditMode"
                :workingDays="projectEntity.workingSchedule"
                :hotelReserveAlarm="project.hotelReserveAlarm"
                :projectDateRange="project.dateRange"
                @save="updateProfile"
              />
            </el-col>
          </el-row>
          <el-row :gutter="20">
            <el-col :span="12">
              <project-interruptions
                :isEditMode="computedEditMode"
                :interruptions="projectEntity.interruptions"
                :projectDateRange="projectEntity.dateRange"
                :hideOld="hideOldTimes"
              />
            </el-col>
            <el-col :span="12">
              <detailed-info-schedule
                :isEditMode="computedEditMode"
                :detailedInfoSchedule="projectEntity.detailedInfoSchedule"
                :projectDateRange="projectEntity.dateRange"
                :hideOld="hideOldTimes"
              />
            </el-col>
          </el-row>
        </div>
        <hr />
        <p class="profile-title">{{ $t("src.components.project.profile.leistungen") }}</p>
        <el-row :gutter="20">
          <el-col :span="24">
            <div class="form-group">
              <el-row>
                <el-col :span="2">
                  <profile-switch
                    height="40px"
                    v-model="projectEntity.enableHazardousSubstances"
                    :disabled="!computedEditMode"
                    :title="$t('src.components.project.profile.aktiv')"
                    :editMode="computedEditMode"
                  />
                </el-col>
                <el-col :span="10">
                  <profile-select
                    :title="$t('src.components.project.profile.gefahrstoffe')"
                    :editMode="computedEditMode"
                    :disabled="!projectEntity.enableHazardousSubstances"
                    v-model="projectEntity.selectedHazardousSubstances"
                    :placeholder="$t('src.components.project.profile.bitteAuswhlen')"
                    multiple
                    valueIdentifier="_id"
                    labelIdentifier="label"
                    :items="options['hazardousSubstances']"
                  />
                </el-col>
              </el-row>
            </div>
          </el-col>
          <el-col :span="24">
            <profile-input
              :editMode="computedEditMode"
              type="textarea"
              :label="$t('src.components.project.profile.wichtigeInformationen')"
              :name="$t('src.components.project.profile.wichtigeInformationen')"
              :placeholder="$t('src.components.project.profile.wichtigeInformationen')"
              :autosize="{ minRows: 3, maxRows: 4 }"
              v-model="projectEntity.servicesFreeText"
            ></profile-input>
          </el-col>
        </el-row>

        <hr />

        <employee-control
          :editMode="computedEditMode"
          :projectEntity="projectEntity"
          :projectDateRange="project.dateRange"
          @save="updateProfile"
        />
        <hr />
        <hotels-reservation
          :onAddHotel="addHotel"
          :onRemoveHotel="removeHotel"
          :onEditHotel="editHotel"
          :selectedHotels="hotels"
          :projectDateRange="project.dateRange"
          :viewOnly="!computedEditMode"
          v-on:changeHotelReserveAlarm="changeHotelReserveAlarm"
          :hotelReserveAlarm="projectEntity.hotelReserveAlarm"
          v-on:changeExcludeLastDayFromBooking="changeExcludeLastDayFromBooking"
          :excludeLastDayFromBooking="projectEntity.excludeLastDayFromBooking"
          :projectId="projectEntity.id"
        ></hotels-reservation>
        <hr />
        <el-row class="mb-10" justify="space-between">
          <el-col :span="12">
            <p class="profile-title" id="hotel-section">
              {{ $t("src.components.project.profile.abfahrtzeiten") }}
            </p>
          </el-col>
          <el-col :span="12" class="text-right">
            <profile-radio-group
              class="mr-2"
              v-model="hideOldDepartures"
              :items="[
                { label: 'Alle Einträge', value: false },
                { label: 'Aktuelle Einträge', value: true },
              ]"
            />
          </el-col>
        </el-row>

        <departure-control
          :title="$t('src.components.project.profile.abfahrtBhfSchwegler')"
          :hideOld="hideOldDepartures"
          :isEditMode="computedEditMode"
          :projectDateRange="projectEntity.dateRange"
          :selectedDepartures="projectEntity.selectedSchweglerDepartures"
        />

        <departure-control
          :title="$t('src.components.project.profile.abfahrtBv')"
          :hideOld="hideOldDepartures"
          :isEditMode="computedEditMode"
          :projectDateRange="projectEntity.dateRange"
          :selectedDepartures="projectEntity.selectedBvDepartures"
        />
        <hr />
        <div class="form-group">
          <profile-input
            type="textarea"
            :editMode="computedEditMode"
            :label="$t('src.components.project.profile.jourFixTermination')"
            :name="$t('src.components.project.profile.jourFixTermination')"
            :autosize="{ minRows: 4 }"
            v-model="projectEntity.jourFixTermination"
          ></profile-input>
        </div>
        <div class="form-group">
          <profile-input
            type="textarea"
            :editMode="computedEditMode"
            :label="$t('src.components.project.profile.projectTimings')"
            :name="$t('src.components.project.profile.projectTimings')"
            :autosize="{ minRows: 4 }"
            v-model="projectEntity.projectTimings"
          ></profile-input>
        </div>
        <div class="form-group" v-if="!isNew">
          <project-notes
            :isEditMode="isEditMode"
            :projectNotes="project.notes"
            @updateData="$emit('updateProject', null, { submitAfterUpdate: true })"
          />
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import {
  Message,
  MessageBox,
  RadioGroup,
  RadioButton,
  Tabs,
  TabPane,
  Switch,
  DatePicker,
  TimeSelect,
  Timeline,
  TimelineItem,
  Checkbox,
  ColorPicker,
} from "element-ui";
import { debounce, set, get } from "lodash";
import { isDataChanged } from "src/utils/isDataChanged";
import { Avatar, Collapse, CollapseItem, Keyfigures, TimePicker } from "src/components/UIComponents";
import HotelsReservation from "./HotelsReservation";
import ProjectStatus from "./ProjectStatus";
import EmployeeControl from "./EmployeeControl.vue";
import DepartureBV from "./DepartureControl.vue";
import WorkingTimes from "./WorkingTimes.vue";
import CoreServicesTable from "./CoreServicesTable.vue";
import Pencil from "vue-material-design-icons/Pencil";
import TrashCanOutline from "vue-material-design-icons/TrashCanOutline";
import { moment } from "src/config/moment";
import ProfileSelect from "../UIComponents/Inputs/ProfileSelect.vue";
import ProjectInterruptions from "./ProjectInterruptions.vue";
import ProjectDateChange from "./ProjectDateChange.vue";
import DetailedInfoSchedule from "./DetailedInfoSchedule.vue";
import { mapState } from "vuex";
import ProjectNotes from "./ProjectNotes.vue";
import ProjectClientContactRecord from "./ProjectClientContactRecord.vue";
import { unlink } from "src/utils/unlink";

const weekDays = [
  { label: "Mo", value: 1 },
  { label: "Di", value: 2 },
  { label: "Mi", value: 3 },
  { label: "Do", value: 4 },
  { label: "Fr", value: 5 },
  { label: "Sa", value: 6 },
  { label: "So", value: 7 },
];
export default {
  name: "project-profile",
  props: {
    project: Object,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    isNew: Boolean,
    accessRights: String,
    allContacts: Array,
    projectConfigurationManager: { type: Object },
  },
  components: {
    ProjectClientContactRecord,
    DetailedInfoSchedule,
    ProjectInterruptions,
    TimePicker,
    Message,
    MessageBox,
    [Keyfigures.name]: Keyfigures,
    [ProjectNotes.name]: ProjectNotes,
    [ColorPicker.name]: ColorPicker,
    [Avatar.name]: Avatar,
    [RadioButton.name]: RadioButton,
    [RadioGroup.name]: RadioGroup,
    [Tabs.name]: Tabs,
    [TabPane.name]: TabPane,
    Collapse,
    CollapseItem,
    ProfileSelect,
    ProjectDateChange,
    [Switch.name]: Switch,
    [DatePicker.name]: DatePicker,
    [TimeSelect.name]: TimeSelect,
    [Timeline.name]: Timeline,
    [TimelineItem.name]: TimelineItem,
    [HotelsReservation.name]: HotelsReservation,
    [ProjectStatus.name]: ProjectStatus,
    [Checkbox.name]: Checkbox,
    [EmployeeControl.name]: EmployeeControl,
    [Pencil.name]: Pencil,
    [TrashCanOutline.name]: TrashCanOutline,
    [DepartureBV.name]: DepartureBV,
    [WorkingTimes.name]: WorkingTimes,
    [CoreServicesTable.name]: CoreServicesTable,
  },
  data() {
    return {
      weekDays: weekDays,
      hideOldTimes: true,
      predefinedColors: [
        "#581713",
        "#a94b43",
        "#fbd283",
        "#62733f",
        "#034f73",
        "#96e3ff",
        "#255385",
        "#09111e",
        "#181238",
        "#d9d6cd",
        "#3d3936",
        "#837e7a",
        "#8d8d95",
        "#b5c4d7",
        "#dcba9f",
        "#d2cdca",
        "#fee0a0",
        "#84a0b5",
        "#bddc79",
        "#0ed145",
      ],
      tabName: "Projektdaten",
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      active: "",
      projectEntity: {
        hrClient: {},
        hrWorksiteSupervisor: {},
        hrDivisionManager: {},
        selectedHotels: [],
        hotelReserveAlarm: false,
        excludeLastDayFromBooking: false,
        selectedBvDepartures: [],
      }, //using this data instead of props project variable
      options: {
        hazardousSubstances: [],
        coreServices: [],
        projectTypes: [],
        docTypes: [],
      },
      hideOldDepartures: true,
    };
  },
  created() {
    this.debounceUpdateData = debounce(this.updateProfile, 300);
  },
  mounted() {
    //register for profile settings update
    this.$root.$on("settingsChanged", (data) => {
      if (data.modelType === "project") {
        //operation = "deleted" may remove the assignment
        if (data.operation === "deleted") {
          switch (data.modelID) {
            case "hazardousSubstances":
              this.deleteSettings(data.options, this.projectEntity.selectedHazardousSubstances);
              break;
          }
        }
        //operation = "loaded", "updated" and "created" just updates the options select
        this.options[data.modelID] = data.options;
      }
    });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
  },
  watch: {
    "$props.project": function (newVal, oldVal) {
      if (isDataChanged(newVal, this.projectEntity)) {
        this.projectEntity = newVal;
      }
    },
    projectEntity: {
      handler: function (val, oldVal) {
        this.debounceUpdateData();
      },
      deep: true,
    },
  },
  computed: {
    ...mapState("account", { allAccessRights: "accessRights" }),

    projectAccess() {
      return get(this.allAccessRights, "project", { specificAccess: {}, generalAccess: null });
    },
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
    hotels: function () {
      if (!this.projectEntity || !this.projectEntity.selectedHotels) {
        return [];
      }
      return this.projectEntity.selectedHotels.sort(function (a, b) {
        return new Date(a.hotelDateRange[0]).getTime() - new Date(b.hotelDateRange[0]).getTime();
      });
    },
  },
  methods: {
    setContact(namespace, contactData) {
      const newData = set(unlink(this.project), namespace, contactData);
      this.$emit("updateProject", newData);
    },
    addFileToDelete(url) {
      // TODO:??
    },
    deleteSettings(options, assignedIDs) {
      //synchonize allready assigned ids
      if (assignedIDs) {
        let updateProfileRequired = false;

        for (let index = 0; index < assignedIDs.length; index++) {
          let test = options.filter((option) => option._id === assignedIDs[index]);

          if (test.length == 0) {
            assignedIDs.splice(index); // remove this ID
            index -= 1; //correct index of for loop
            updateProfileRequired = true;
          }
        }

        if (updateProfileRequired) {
          this.updateProfile();
        }
      }
    },
    changeHotelReserveAlarm() {
      this.projectEntity.hotelReserveAlarm = !this.projectEntity.hotelReserveAlarm;
    },
    changeExcludeLastDayFromBooking() {
      console.log("changeExcludeLastDayFromBooking");
      this.projectEntity.excludeLastDayFromBooking = !this.projectEntity.excludeLastDayFromBooking;
    },
    removeHotel(idx) {
      this.projectEntity.selectedHotels.splice(idx, 1);
      this.updateProfile({ submitAfterUpdate: true });
    },
    addHotel(hotel) {
      if (!this.projectEntity.selectedHotels) {
        this.projectEntity.selectedHotels = [];
      }
      this.projectEntity.selectedHotels.push({
        hotelID: hotel.hotelID,
        hotelDateRange: hotel.hotelDateRange,
        freeText: hotel.freeText,
        documents: hotel.documents,
        withBreakfast: hotel.withBreakfast,
        price: hotel.price,
      });
      this.updateProfile({ submitAfterUpdate: true });
    },
    editHotel(hotel, hotelIdx) {
      this.projectEntity.selectedHotels.splice(hotelIdx, 1, hotel);
      this.updateProfile({ submitAfterUpdate: true });
    },
    setPicture(pictureSettings) {
      this.projectEntity.picture = pictureSettings;
      this.$nextTick(() => {
        this.updateProfile();
      });
    },
    updateProfile(params) {
      this.$emit("updateProject", { ...this.projectEntity }, params);
    },
    handleDateChange(newData) {
      this.$emit("updateProject", { ...this.projectEntity, ...newData }, { submitAfterUpdate: false });
    },
    updateProgress(progressData, changeProgress = true) {
      if (changeProgress) {
        this.projectEntity.progress = progressData.progress;
      }
      if (!this.projectEntity.progressDates) {
        this.projectEntity.progressDates = [];
      }
      const progressIdx = this.projectEntity.progressDates.findIndex((item) => item.progress === progressData.progress);
      if (progressIdx !== -1) {
        this.projectEntity.progressDates.splice(progressIdx, 1, progressData);
        // when redo project - delete progressDates
        const progressToDelete = this.projectEntity.progressDates.filter(
          (data) => data.progress.replace("progress_", "") > progressData.progress.replace("progress_", "")
        );
        if (progressToDelete.length) {
          this.projectEntity.progressDates = this.projectEntity.progressDates.filter((item) =>
            progressToDelete.some((record) => record.progress !== item.progress)
          );
        }
      } else {
        this.projectEntity.progressDates.push(progressData);
      }
      this.debounceUpdateData();
    },
  },
  filters: {
    formatDate: function (date) {
      return moment(date).format("DD.MM.YYYY");
    },
  },
};
</script>

<style lang="scss">
.mb-10 {
  margin-bottom: 10px;
}
.mb-20 {
  margin-bottom: 20px;
}
.m-0 {
  margin: 0;
}
.overflow-ellipsis {
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}
.ps {
  height: calc(100vh - 225px);
}
// to show all weekdays without scroll
.height-all-days {
  height: 325px;
  .el-select-dropdown__wrap {
    margin: 0 !important;
    height: 325px !important;
    overflow: visible !important;
  }
  .el-scrollbar,
  .el-scrollbar__wrap {
    // overflow-y: visible;
    overflow: hidden;
    height: 100%;
  }
  .el-scrollbar__bar {
    display: none;
  }
}
.project-general-info {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: stretch;
}
.project-color-view {
  display: inline-block;
  width: 30px;
  height: 30px;
}
.profile-title {
  font-size: 18px;
  font-weight: normal;
}
</style>
