<template>
  <div style="align-self: flex-start">
    <el-dropdown @command="handleItemCommand">
      <button class="btn btn-sm btn-ghost card-btn" @click.stop style="font-size: 1rem">
        <dots-vertical-icon />
      </button>
      <el-dropdown-menu slot="dropdown">
        <template v-if="data.isOwner || isPrivilegedUser || data.isResponsible || isWorkshop">
          <el-dropdown-item :command="{ action: 'status', payload: 'TODO' }">
            <todo-progress-dot status="TODO" />
            {{ $t("To do") }}
          </el-dropdown-item>
          <!-- TODO -> IN_PROGRESS -->
          <el-dropdown-item :command="{ action: 'status', payload: 'IN_PROGRESS' }">
            <todo-progress-dot status="IN_PROGRESS" />
            {{ $t("In progress") }}
          </el-dropdown-item>
          <!-- IN_PROGRESS -> DONE -->
          <el-dropdown-item :command="{ action: 'status', payload: 'DONE' }">
            <todo-progress-dot status="DONE" />
            {{ $t("Done") }}
          </el-dropdown-item>
        </template>
        <el-dropdown-item :command="{ action: 'export_pdf' }" v-if="isPrivilegedUser || isWorkshop">
          <el-tooltip
            content="Anzeigbare Dateiformate: png, jpg, jpeg,
                tiff, bmp, gif, msg."
            placement="left"
            ><span> <download-icon class="mr-1" />{{ $t("Export as PDF") }} </span>
          </el-tooltip></el-dropdown-item
        >
        <el-dropdown-item v-if="data.isOwner || isPrivilegedUser" :command="{ action: 'delete' }">
          <trash-can-outline-icon class="mr-1" />{{
            $t("src.components.project.invoices.invoices.lschen")
          }}</el-dropdown-item
        >
        <el-dropdown-item v-if="data.isOwner || isPrivilegedUser || isWorkshop" :command="{ action: 'edit' }">
          <pencil-icon class="mr-1" />{{ $t("src.components.project.invoices.invoices.bearbeiten") }}</el-dropdown-item
        >
      </el-dropdown-menu>
    </el-dropdown>
    <el-dialog :title="$t('Edit todo')" :visible.sync="isEditVisible" @close="cancelEditModal">
      <ValidationObserver ref="observer" slim>
        <div class="row mb-2">
          <div class="col-lg-6 col-xl-4">
            <profile-select
              :title="$t('Priority')"
              :name="$t('Priority')"
              rules="required"
              :editMode="true"
              :clearable="false"
              :multiple="false"
              v-model="editingRecord.priority"
              :items="priorityOptions"
              required
              size="small"
            />
          </div>
          <div class="col-lg-6 col-xl-4">
            <profile-input
              v-if="isWorkshop"
              :editMode="true"
              :label="$t('Assignee')"
              :placeholder="$t('Assignee')"
              size="small"
              v-model="editingRecord.workshopFreeText"
            ></profile-input>
            <!-- type="textarea" -->
            <!-- :autosize="{ minRows: 1, maxRows: 4 }" -->
            <profile-select
              v-else
              :title="$t('Responsible')"
              :name="$t('Responsible')"
              rules="required"
              :editMode="editingRecord.isOwner"
              :clearable="false"
              :multiple="false"
              v-model="editingRecord.responsibleId"
              label="name"
              isGrouped
              filterable
              valueIdentifier="_id"
              :items="projectMembers"
              required
              size="small"
            />
          </div>
          <div class="col-lg-6 col-xl-4">
            <profile-date-picker
              v-model="editingRecord.dueDate"
              :title="$t('Deadline')"
              :isEditMode="
                editingRecord.isOwner || editingRecord.isResponsible || (editingRecord.workshopProjectId && isWorkshop)
              "
              :name="$t('Deadline')"
              size="small"
            />
          </div>
          <div class="col-lg-12" v-if="data.isOwner || isPrivilegedUser">
            <ValidationObserver ref="validateTitleObserver" slim>
              <profile-input
                size="small"
                :placeholder="$t('Title')"
                name="Titels der Aufgabe"
                rules="required"
                no-label
                :editMode="true"
                :showErrorText="false"
                v-model="editingRecord.title"
              />
            </ValidationObserver>
            <div class="d-flex flex-column flex-grow-1 mb-2" style="min-height: 400px">
              <ValidationObserver ref="validateBodyObserver" slim>
                <todo-wysiwyg v-model="editingRecord.body" name="Beschreibung der Aufgabe" rules="required" />
              </ValidationObserver>
            </div>
            <drawer-upload
              :data="{ metadata: getMetadata() }"
              location="TODOS"
              :before-upload="handleBeforeUpload"
              :on-remove="removeFile"
              :on-success="fileUploaded"
              :file-list="editingRecord.attachments"
              class="mb-2"
              :pictureList="true"
            />
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <el-button @click="cancelEditModal">{{ $t("Cancel") }}</el-button>
          <el-button type="primary" @click="submitChanges">{{ $t("Save") }}</el-button>
        </div>
      </ValidationObserver>
    </el-dialog>
  </div>
</template>

<script>
import { ValidationObserver } from "vee-validate";
import { Dropdown, DropdownItem, DropdownMenu, Badge, Dialog, Tooltip, Message } from "element-ui";
import { last } from "lodash";
import DotsVertical from "vue-material-design-icons/DotsVertical";
import TodoProgressDot from "./TodoProgressDot.vue";
import DownloadIcon from "vue-material-design-icons/Download.vue";
import TodoWysiwyg from "./TodoWysiwyg.vue";
import DrawerUpload from "../Invoices/DrawerUpload.vue";
import { resizeImage } from "src/utils/resizeImage";

export default {
  name: "todo-editor-dropdown",
  components: {
    ValidationObserver,
    TodoProgressDot,
    TodoWysiwyg,
    DrawerUpload,
    [DownloadIcon.name]: DownloadIcon,
    [DotsVertical.name]: DotsVertical,
    [Dialog.name]: Dialog,
    [Badge.name]: Badge,
    [Dropdown.name]: Dropdown,
    [DropdownItem.name]: DropdownItem,
    [DropdownMenu.name]: DropdownMenu,
    [Tooltip.name]: Tooltip,
  },
  props: {
    data: Object,
    isWorkshop: Boolean,
    isPrivilegedUser: Boolean,
    priorityOptions: Array,
    projectMembers: Array,
  },
  data() {
    return {
      isEditVisible: false,
      editingRecord: {},
    };
  },
  methods: {
    handleItemCommand(data) {
      switch (data.action) {
        case "status":
          this.$emit("setStatus", { id: this.data._id, status: data.payload });
          break;
        case "delete":
          this.$emit("delete", this.data._id);
          break;
        case "export_pdf":
          this.$emit("exportPdf", this.data._id);
          break;
        case "edit":
          this.showEditModal();
          break;
        default:
          break;
      }
    },
    showEditModal() {
      this.isEditVisible = true;
      const { _id, responsibleId, priority, dueDate, isOwner, workshopFreeText, body, title, attachments } = this.data;
      this.editingRecord = {
        _id,
        isOwner,
        responsibleId,
        priority,
        dueDate,
        workshopFreeText,
        body,
        title,
        attachments,
      };
    },
    cancelEditModal() {
      this.editingRecord = {};
      this.isEditVisible = false;
    },
    async submitChanges() {
      const isValid = await this.$refs.validateBodyObserver.validate();
      if (!isValid) {
        return;
      }
      this.$emit("editRecord", { ...this.editingRecord });
      this.cancelEditModal();
    },
    fileUploaded(response, file, fileList) {
      this.data.attachments = this.data.attachments.concat(response);
    },
    getMetadata() {
      return `project_${this.data.projectId}_todos_0`;
    },
    async removeFile(file) {
      try {
        const metadata = this.getMetadata();
        await this.axios.delete("/api/fileupload", {
          params: {
            filename: file.name,
            metadata: metadata,
          },
        });
        this.data.attachments = this.data.attachments.filter((item) => item._id !== file._id);
      } catch (error) {
        Message.error(error.message);
        throw error;
      }
    },
    async handleBeforeUpload(file) {
      try {
        const imageFormats = ["jpg", "jpeg", "png", "gif"];
        const ext = last(file.name.split(".")).toLowerCase();
        const supportedImageFormat = imageFormats.find((item) => item === ext);
        const isGt2M = file.size / 1024 / 1024 > 2;
        if (supportedImageFormat && isGt2M) {
          const objectUrl = URL.createObjectURL(file);
          const img = await resizeImage(objectUrl, {
            maxWidth: 1024,
            maxHeight: 768,
            contentType: `image/${supportedImageFormat}`,
            quality: 0.7,
          });
          const newFile = dataURLtoFile(img.url, file.name);
          return newFile;
        }
        return file;
      } catch (error) {
        console.log("error.message", error.message);
        throw error;
      }
    },
  },
};
</script>

<style></style>
