<template>
  <div class="pending-icon" v-if="status === 'uploaded' || status === 'success'">
    <cloud-clock-icon class="icon2x mb-2" :size="48" />
  </div>
  <el-tooltip content="Fehler beim Hochladen einer Datei" placement="top" v-else-if="status === 'error'">
    <div class="pending-icon">
      <file-document-alert-outline-icon class="icon2x mb-2" :size="48" />
    </div>
  </el-tooltip>
  <el-tooltip content="Upload Prozess wurde gestartet" placement="top" v-else>
    <div class="pending-icon">
      <progress-upload-icon class="pulse icon2x mb-2" :size="48" />
      <div>{{ formattedProgress }}</div>
    </div>
  </el-tooltip>
</template>

<script>
import { Tooltip } from "element-ui";
import ProgressUploadIcon from "vue-material-design-icons/ProgressUpload.vue";
import CloudClockIcon from "vue-material-design-icons/CloudClock.vue";
import FileDocumentAlertOutlineIcon from "vue-material-design-icons/FileDocumentAlertOutline.vue";

export default {
  name: "pending-icon",
  components: {
    [Tooltip.name]: Tooltip,
    ProgressUploadIcon,
    CloudClockIcon,
    FileDocumentAlertOutlineIcon,
  },
  props: {
    progress: Number,
    status: String,
  },
  computed: {
    formattedProgress() {
      if (this.progress && this.progress !== 100) {
        return `(...${Math.floor(this.progress)}%)`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
