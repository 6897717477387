<template>
  <div>
    <form>
      <span class="n-profile-title">{{ tabName }}</span>

      <div class="card-body">
        <el-row :gutter="20">
          <template v-if="isEditMode">
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-edit-avatar"
                :isEditMode="isEditMode"
                :actionURI="actionURI"
                :avatar="vehicle.picture"
                @avatar-changed="setPicture"
                :name="'vehicle_' + vehicle.id + '_picture_0'"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <el-row>
                <el-col>
                  <!-- NAME -->
                  <profile-input
                    required
                    v-model="vehicle.name"
                    rules="required"
                    :name="$t('src.components.vehicle.profile.name')"
                    :label="$t('src.components.vehicle.profile.name')"
                    :editMode="isEditMode"
                  />
                </el-col>
                <el-col style="width: 50px">
                  <!-- COLOR PICKER -->
                  <span class="n-profile-label-title">{{ $t("src.components.vehicle.profile.farbe") }}</span
                  ><br />
                  <el-color-picker
                    size="medium"
                    :disabled="!isEditMode"
                    v-model="vehicle.color"
                    :predefine="predefinedColors"
                  ></el-color-picker>
                </el-col>
              </el-row>
            </el-col>
          </template>
          <template v-else>
            <el-col style="flex-shrink: 0; width: 140px">
              <!-- AVATAR -->
              <avatar-uploader
                class="n-grid-container-view-avatar n-grid-container-view"
                :isEditMode="isEditMode"
                :actionURI="actionURI"
                :avatar="vehicle.picture"
                @avatar-changed="setPicture"
                :name="'vehicle_' + vehicle.id + '_picture_0'"
              />
            </el-col>
            <el-col :lg="6" :md="8">
              <div class="n-profile-name">
                <span class="n-profile-label-title">{{ $t("src.components.vehicle.profile.name") }}</span
                ><br />
                {{ vehicle.name }}
              </div>
              <div style="display: flex">
                <!-- AKTIV/INAKTIV -->
                <div style="display: flex; align-items: center; width: 80px" class="n-profile-name-light">
                  <el-badge class="profile" :type="vehicle.active ? 'success' : 'danger'" is-dot />
                  {{ vehicle.active ? "Aktiv" : "Inaktiv" }}
                </div>
                <!-- COLOR PICKER -->
                <div style="width: 60px; display: inline-block">
                  <span class="n-profile-label-title">{{ $t("src.components.vehicle.profile.farbe") }}</span
                  ><br />
                  <div
                    v-bind:style="{
                      border: '1px solid black',
                      width: '30px',
                      height: '30px',
                      backgroundColor: vehicle.color,
                    }"
                  />
                </div>
              </div>
            </el-col>
          </template>

          <el-col :lg="5" :md="8">
            <!-- Warengruppe 1 DROPDOWN -->
            <product-group-select
              :editMode="isEditMode"
              productGroupType="pg1"
              v-model="vehicle.selectedProductGroup1"
            />
          </el-col>

          <el-col :lg="5" :md="8">
            <!--  Warengruppe 2 DROPDOWN -->
            <product-group-select
              :editMode="isEditMode"
              productGroupType="pg2"
              v-model="vehicle.selectedProductGroup2"
            />
            <!-- Position -->
            <profile-input type="number" v-model="vehicle.order" :label="$t('Order')" :editMode="computedEditMode" />
          </el-col>

          <el-col :lg="5" :md="8">
            <profile-select
              :title="$t('src.components.vehicle.profile.bentigteFhrererscheine')"
              :items="options['licence']"
              v-model="vehicle.requiredDrivingLicences"
              valueIdentifier="_id"
              :editMode="isEditMode"
              class="mb-2"
            />

            <!-- TEAM -->
            <profile-input v-model="vehicle.team" :label="$t('team')" :editMode="computedEditMode" />
          </el-col>
        </el-row>
        <el-row :gutter="20" v-if="isEditMode">
          <el-col style="width: 140px">
            <!-- AKTIV/INAKTIV -->
            <profile-switch
              :title="$t('src.components.vehicle.profile.aktiv')"
              v-model="vehicle.active"
              :active-text="$t('src.components.vehicle.profile.ja')"
              :inactive-text="$t('src.components.vehicle.profile.nein')"
              colorizeActive
            />
          </el-col>
        </el-row>
        <hr />

        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.vehicle.profile.allgemein") }}</div>
        <el-row class="n-profile-spacer" :gutter="20">
          <el-col style="width: 140px">
            <profile-switch
              :title="$t('src.components.vehicle.profile.dispoRelevant')"
              :disabled="!isEditMode"
              v-model="vehicle.projectRelevant"
              :active-text="$t('src.components.vehicle.profile.ja')"
              :inactive-text="$t('src.components.vehicle.profile.nein')"
            />
          </el-col>
          <el-col :md="6">
            <profile-input
              v-model="vehicle.description"
              :label="$t('src.components.vehicle.profile.beschreibung')"
              :editMode="isEditMode"
            />
          </el-col>
        </el-row>

        <hr />

        <div class="n-profile-section-font n-profile-section">
          {{ $t("src.components.vehicle.profile.fahrzeugdaten") }}
        </div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="4">
            <profile-input
              v-model="vehicle.registration"
              :label="$t('src.components.vehicle.profile.kennzeichen')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.manufacturer"
              :label="$t('src.components.vehicle.profile.hersteller')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.model"
              :label="$t('src.components.vehicle.profile.modell')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.type"
              :label="$t('src.components.vehicle.profile.typ')"
              :editMode="isEditMode"
            />
          </el-col>
        </el-row>
        <el-row class="n-profile-spacer">
          <el-col :span="4">
            <profile-input
              v-model="vehicle.vehicleNumber"
              :label="$t('src.components.vehicle.profile.fahrzeugnummer')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.numberSeats"
              :label="$t('src.components.vehicle.profile.anzahlSitze')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.firstRegistration"
              :label="$t('src.components.vehicle.profile.erstzulassung')"
              :editMode="isEditMode"
            />
          </el-col>
        </el-row>

        <hr />

        <div class="n-profile-section-font n-profile-section">{{ $t("src.components.vehicle.profile.mae") }}</div>
        <el-row style="margin-bottom: 20px">
          <el-col :span="4">
            <profile-input
              v-model="vehicle.unladenWeight"
              :label="$t('src.components.vehicle.profile.leergewichtKg')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.grossVehicleWeight"
              :label="$t('src.components.vehicle.profile.zulssigesGesamtgewichtKg')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.height"
              :label="$t('src.components.vehicle.profile.hheMm')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.width"
              :label="$t('src.components.vehicle.profile.breiteMm')"
              :editMode="isEditMode"
            />
          </el-col>
        </el-row>
        <el-row class="n-profile-spacer">
          <el-col :span="4">
            <profile-input
              v-model="vehicle.length"
              :label="$t('src.components.vehicle.profile.lngeMm')"
              :editMode="isEditMode"
            />
          </el-col>
          <el-col :span="4" :offset="1">
            <profile-input
              v-model="vehicle.workload"
              :label="$t('src.components.vehicle.profile.nutzlastKg')"
              :editMode="isEditMode"
            />
          </el-col>
        </el-row>
        <hr />
        <date-history
          :isEditMode="computedEditMode"
          :dateHistory="vehicle.dateHistory"
          :dateOfEntering="vehicle.dateOfEntering"
          :dateOfLeaving="vehicle.dateOfLeaving"
          resourceType="vehicle"
          :resourceId="vehicle.id"
          @setProfileData="setProfileData"
          @saveDateHistory="saveDateHistory"
        />
      </div>
    </form>
  </div>
</template>

<script>
import { Message, Switch, DatePicker, ColorPicker } from "element-ui";
import { mapActions } from "vuex";
import { Collapse, CollapseItem, Avatar } from "src/components/UIComponents";
import DateHistory from "../Generic/DateHistory.vue";

export default {
  name: "vehicle-profile",
  props: {
    accessRights: String,
    vehicle: Object,
    tabName: String,
    isEditMode: {
      type: Boolean,
      default: true,
    },
    updateProfile: {
      type: Function,
      required: true,
    },
  },
  components: {
    [DatePicker.name]: DatePicker,
    Message,
    [Avatar.name]: Avatar,
    [Switch.name]: Switch,
    Collapse,
    CollapseItem,
    DateHistory,
    [ColorPicker.name]: ColorPicker,
  },
  data() {
    return {
      predefinedColors: [
        "#581713",
        "#a94b43",
        "#fbd283",
        "#62733f",
        "#034f73",
        "#96e3ff",
        "#255385",
        "#09111e",
        "#181238",
        "#d9d6cd",
        "#3d3936",
        "#837e7a",
        "#8d8d95",
        "#b5c4d7",
        "#dcba9f",
        "#d2cdca",
        "#fee0a0",
        "#84a0b5",
        "#bddc79",
        "#0ed145",
      ],
      actionURI: this.axios.defaults.baseURL + "/api/avatars", //used for profile control
      daterangeOptions: {
        firstDayOfWeek: 1,
      },
      options: {
        licence: [],
      },
    };
  },
  watch: {
    "$props.vehicle": {
      handler: function (val, oldVal) {
        if (!val.inital) {
          this.checkDirty(val);
        }
      },
      deep: true,
    },
  },
  mounted() {
    //settins changed
    this.$root.$on("settingsChanged", (data) => {
      //in this case we dont have modelType === "vehicle"
      //just drivingLicences from employee is reused

      //operation = "deleted" may remove the assignement
      if (data.modelType === "employee" && data.operation === "deleted") {
        switch (data.modelID) {
          case "licence":
            this.deleteSettings(data.options, this.vehicle.requiredDrivingLicences);
            break;
        }
      }

      //operation = "loaded", "updated" and "created" just updates the options select
      this.options[data.modelID] = data.options;
    });
  },
  beforeDestroy() {
    this.$root.$off("settingsChanged");
  },
  methods: {
    ...mapActions("dirtyFlag", { checkDirty: "checkDirty" }),
    setProfileData(data) {
      this.$emit("setProfileData", data);
    },
    saveDateHistory(data) {
      this.$emit("savePartialProfileData", data);
    },
    deleteSettings(options, assignedIDs) {
      //synchonize allready assigned ids
      if (assignedIDs) {
        let updateProfileRequired = false;

        for (let index = 0; index < assignedIDs.length; index++) {
          let test = options.filter((option) => option._id === assignedIDs[index]);

          if (test.length == 0) {
            assignedIDs.splice(index); // remove this ID
            index -= 1; //correct index of for loop
            updateProfileRequired = true;
          }
        }

        if (updateProfileRequired) {
          this.updateProfile(this.vehicle);
        }
      }
    },
    setPicture(pictureSettings) {
      this.vehicle.picture = pictureSettings;
    },
  },
  computed: {
    computedEditMode() {
      return this.isEditMode && (this.accessRights === "full" || this.accessRights === "manage");
    },
  },
};
</script>

<style lang="scss" scoped>
.profile .el-badge__content.is-dot {
  padding: 0px 0px 0px 0px; /* padding: top right bottom left */
}

.n-grid-container-view {
  display: grid;
  grid-template-columns: 0px 100px 200px 100px;
  grid-template-areas:
    "avatar avatar content content"
    "avatar avatar content content";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
  text-align: left;
}

.n-grid-container-view-avatar {
  grid-area: avatar;
}
.n-grid-container-view-content {
  grid-area: content;
}

.n-grid-container-edit {
  display: grid;
  grid-template-columns: 0px 100px 100px 100px;
  grid-template-areas:
    "avatar avatar firstname firstname"
    "avatar avatar state empty";
  grid-gap: 0px 20px; //rows columns
  padding: 0px;
}

.n-grid-container-edit > div {
  text-align: left;
}

.n-grid-container-edit-avatar {
  grid-area: avatar;
}
.n-grid-container-edit-firstname {
  grid-area: firstname;
}

.n-grid-container-edit-state {
  margin-top: 20px;
  grid-area: state;
}
</style>
