<template>
  <el-drawer
    :withHeader="false"
    :visible.sync="visible"
    direction="rtl"
    :wrapperClosable="false"
    :destroy-on-close="true"
    :before-close="handleClose"
    @closed="discard"
    size="630px"
    ref="drawer"
  >
    <ValidationObserver ref="validateObserver">
      <div>
        <perfect-scrollbar style="height: calc(100vh - 50px)" :options="{ suppressScrollX: true }">
          <div class="container" style="padding: 0 30px">
            <div class="drawer-back" @click="closeDrawer">
              <chevron-left-icon />{{ $t("src.components.suppliers.supplierdetails.lieferanten") }}
            </div>
            <div class="drawer-info">
              <h5 class="drawer-name">{{ supplierTitle }}</h5>
              <edit-profile-button v-if="$can('update', 'supplier') && !localEditMode" @click="localEditMode = true">{{
                $t("src.components.suppliers.supplierdetails.bearbeiten")
              }}</edit-profile-button>
            </div>
            <el-form label-position="top" :model="localSupplier" ref="form" class="drawer-form" :rules="formDataRules">
              <el-row :gutter="20" type="flex">
                <el-col :span="18">
                  <el-form-item prop="resourceTypes">
                    <profile-select
                      style="margin-top: 4px"
                      :required="true"
                      :multiple="true"
                      v-model="localSupplier.resourceTypes"
                      :title="$t('src.components.suppliers.supplierdetails.ressourceTyp')"
                      :editMode="localEditMode"
                      :items="resourceTypesOptions"
                      size="small"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-form-item prop="active">
                    <profile-switch
                      :title="$t('src.components.suppliers.supplierdetails.aktiv')"
                      :disabled="!localEditMode"
                      v-model="localSupplier.active"
                      :active-text="$t('src.components.suppliers.supplierdetails.ja')"
                      :inactive-text="$t('src.components.suppliers.supplierdetails.nein')"
                      colorizeActive
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <hr />

              <div class="drawer-subtitle">{{ $t("src.components.suppliers.supplierdetails.allgemein") }}</div>
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <el-col :span="12">
                  <el-form-item prop="name">
                    <profile-input
                      v-model="localSupplier.name"
                      :label="$t('src.components.suppliers.supplierdetails.firmenname')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="website">
                    <profile-input
                      v-model="localSupplier.website"
                      :label="$t('src.components.suppliers.supplierdetails.webseite')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.phone">
                    <profile-input
                      v-model="localSupplier.hrSupplier.phone"
                      :label="$t('src.components.suppliers.supplierdetails.telefon')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.fax">
                    <profile-input
                      v-model="localSupplier.hrSupplier.fax"
                      :label="$t('src.components.suppliers.supplierdetails.fax')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.email">
                    <profile-input
                      v-model="localSupplier.hrSupplier.email"
                      name="emailcompany"
                      type="email"
                      rules="email"
                      :label="$t('src.components.suppliers.supplierdetails.eMail')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="ourCustomerId">
                    <profile-input
                      v-model="localSupplier.ourCustomerId"
                      name="Unsere Kundennummer"
                      :label="$t('ourCustomerId')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <hr />

              <div class="drawer-subtitle">{{ $t("src.components.suppliers.supplierdetails.adressdaten") }}</div>
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.street">
                    <profile-input
                      v-model="localSupplier.hrSupplier.street"
                      :label="$t('src.components.suppliers.supplierdetails.strae')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.no">
                    <profile-input
                      v-model="localSupplier.hrSupplier.no"
                      :label="$t('src.components.suppliers.supplierdetails.hausnummer')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.zip">
                    <profile-input
                      v-model="localSupplier.hrSupplier.zip"
                      :label="$t('src.components.suppliers.supplierdetails.plz')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.city">
                    <profile-input
                      v-model="localSupplier.hrSupplier.city"
                      :label="$t('src.components.suppliers.supplierdetails.stadt')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.addressAddition">
                    <profile-input
                      v-model="localSupplier.hrSupplier.addressAddition"
                      :label="$t('src.components.suppliers.supplierdetails.adresszusatz')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="hrSupplier.country">
                    <profile-select
                      :multiple="false"
                      v-model="localSupplier.hrSupplier.country"
                      :title="$t('country')"
                      label="name"
                      :editMode="isEditMode"
                      :items="countryList"
                      filterable
                    />
                  </el-form-item>
                </el-col>
              </el-row>

              <hr />

              <div class="drawer-subtitle">{{ $t("src.components.suppliers.supplierdetails.kontaktdaten") }}</div>
              <el-row :gutter="10" type="flex" style="flex-wrap: wrap">
                <el-col :span="12">
                  <el-form-item prop="contactPerson.name">
                    <profile-input
                      v-model="localSupplier.contactPerson.name"
                      :label="$t('src.components.suppliers.supplierdetails.ansprechpartner')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contactPerson.phone">
                    <profile-input
                      v-model="localSupplier.contactPerson.phone"
                      :label="$t('src.components.suppliers.supplierdetails.telefon')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contactPerson.email">
                    <profile-input
                      rules="email"
                      :name="$t('src.components.suppliers.supplierdetails.eMail')"
                      v-model="localSupplier.contactPerson.email"
                      :label="$t('src.components.suppliers.supplierdetails.eMail')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contactPerson.mobile">
                    <profile-input
                      v-model="localSupplier.contactPerson.mobile"
                      :label="$t('src.components.suppliers.supplierdetails.handy')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form-item prop="contactPerson.fax">
                    <profile-input
                      v-model="localSupplier.contactPerson.fax"
                      :label="$t('src.components.suppliers.supplierdetails.fax')"
                      :editMode="localEditMode"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </perfect-scrollbar>
        <div class="drawer-footer" v-if="localEditMode">
          <el-button @click="closeDrawer">{{ $t("src.components.suppliers.supplierdetails.abbrechen") }}</el-button>
          <el-button type="primary" @click="submit">{{
            $t("src.components.suppliers.supplierdetails.speichern")
          }}</el-button>
        </div>
      </div>
    </ValidationObserver>
  </el-drawer>
</template>

<script>
import { mapActions, mapState } from "vuex";
import { isEmpty } from "lodash";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { Drawer, Input, Button, Form, FormItem, MessageBox, Message } from "element-ui";
import Pencil from "vue-material-design-icons/Pencil";
import ChevronLeft from "vue-material-design-icons/ChevronLeft";
import ProfileInput from "../UIComponents/Inputs/ProfileInput.vue";
import ProfileSelect from "../UIComponents/Inputs/ProfileSelect.vue";
import EditProfileButton from "../UIComponents/Buttons/EditProfileButton.vue";
import { ValidationObserver } from "vee-validate";
import countryList from "src/country-list.json";

const INITIAL_SUPPLIER = () => Object.assign({}, { active: true, hrSupplier: {}, contactPerson: {} });
export default {
  name: "supplier-details",
  components: {
    ValidationObserver,
    PerfectScrollbar,
    ProfileInput,
    ProfileSelect,
    EditProfileButton,
    [Pencil.name]: Pencil,
    [ChevronLeft.name]: ChevronLeft,
    [Drawer.name]: Drawer,
    [Input.name]: Input,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Message.name]: Message,
    [MessageBox.name]: MessageBox,
  },
  props: {
    supplierData: Object,
    isEditMode: Boolean,
    isNewSupplier: Boolean,
  },
  data() {
    return {
      formDataRules: {
        resourceTypes: { message: "Bitte Ressourcen-Typ(en) auswählen", required: true },
      },
      localEditMode: false,
      visible: false,
      localSupplier: INITIAL_SUPPLIER(),
      loading: false,
      resourceTypesOptions: [
        { label: "Maschine", value: "machine" },
        { label: "KFZ", value: "vehicle" },
        { label: "RHB", value: "rhb" },
        { label: "Verbrauchsmaterial", value: "supply" },
      ],
      countryList: countryList,
    };
  },
  methods: {
    ...mapActions("dirtyFlag", {
      setPreservedData: "setPreservedData",
      checkDirtyFlag: "checkDirty",
      discardDirty: "discard",
    }),
    closeDrawer() {
      this.$refs.drawer.closeDrawer();
    },
    handleClose(done) {
      if (this.isDirty) {
        MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonClass: "el-button--success",
        })
          .then((action) => {
            this.submit();
            done();
          })
          .catch((action) => {
            if (action === "close") {
              return;
            } else {
              done();
            }
          });

        // MessageBox.confirm("Sie haben noch nicht gespeicherte Daten, fahren Sie fort?", {
        //   type: "warning",
        // })
        //   .then((_) => {
        //     done();
        //     return _;
        //   })
        //   .catch((_) => {});
      } else {
        Object.assign(this, {
          localEditMode: false,
          visible: false,
          localSupplier: INITIAL_SUPPLIER(),
        });
      }
    },
    discard() {
      Object.assign(this, {
        localEditMode: false,
        visible: false,
        localSupplier: INITIAL_SUPPLIER(),
      });
      this.$emit("closed");
    },
    async submit() {
      const isValid = await this.$refs.validateObserver.validate();
      if (!isValid) {
        Message({
          message: "Bitte füllen Sie alle erforderlichen Felder aus",
          type: "error",
        });
        return;
      }

      this.$refs.form.validate(async (valid) => {
        if (!valid) {
          return false;
        }
        try {
          this.loading = true;
          const formData = {
            ...this.localSupplier,
          };
          let response;
          if (this.isNewSupplier) {
            response = await this.axios.post(`/api/suppliers`, formData);
          } else {
            response = await this.axios.put(`/api/suppliers/${formData._id}`, formData);
          }
          Message.success("Lieferanten Daten gespeichert");
          this.$emit("onSupplierSave", response.data);
          this.discard();
        } catch (error) {
          throw error;
        } finally {
          this.loading = false;
        }
      });
    },
  },
  computed: {
    ...mapState("dirtyFlag", { isDirty: "isDirty" }),
    supplierTitle() {
      if (!isEmpty(this.supplierData)) {
        return this.supplierData.name || "N/A";
      } else {
        return "Neuer Lieferant";
      }
    },
  },
  watch: {
    supplierData(newVal, oldVal) {
      if (newVal) {
        this.visible = true;
        if (isEmpty(newVal)) {
          this.localSupplier = INITIAL_SUPPLIER();
        } else {
          this.localSupplier = { ...newVal };
          if (!this.localSupplier.contactPerson) {
            this.localSupplier.contactPerson = {};
          }
          if (!this.localSupplier.hrSupplier) {
            this.localSupplier.hrSupplier = {};
          }
        }
        setTimeout(() => {
          this.setPreservedData(this.localSupplier);
          this.checkDirtyFlag(this.localSupplier);
        }, 200);
      } else {
        this.visible = false;
        this.localSupplier = INITIAL_SUPPLIER();
      }
    },
    localSupplier: {
      handler: function (newVal, oldVal) {
        this.checkDirtyFlag(newVal);
      },
      deep: true,
    },
    isEditMode(newVal) {
      this.localEditMode = newVal;
    },
  },
};
</script>

<style></style>
