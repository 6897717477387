<template>
  <div style="height: 100%" class="d-flex flex-column" v-loading="loading">
    <div class="d-flex justify-content-between pb-3" style="border-bottom: 1px solid #dee3ed">
      <span class="n-profile-title mx-0">{{ $t("Notes") }}</span>
      <div class="d-flex">
        <el-button class="mr-1" @click="reFetch" :loading="loading">
          <sync-icon />
          {{ $t("Refresh") }}
        </el-button>
        <el-button class="mr-3" @click="makeNewNote">
          <plus-icon />
          {{ $t("Add new note") }}
        </el-button>
      </div>
    </div>
    <div class="tab-btn-block">
      <div :class="['tab-btn', { active: activeTab }]" @click="setActiveTab(true)">
        {{ $t("Active") }} <span :hidden="!activeAmount">{{ activeAmount }}</span>
      </div>
      <div :class="['tab-btn', { active: !activeTab }]" @click="setActiveTab(false)">
        {{ $t("Inactive") }} <span :hidden="!inactiveAmount">{{ inactiveAmount }}</span>
      </div>
    </div>
    <div class="d-flex justify-content-between px-3 py-2 align-items-center" style="border-bottom: 1px solid #dee3ed">
      <div class="n-profile-subtitle ml-0">
        {{ activeTitle }}
      </div>
      <div class="d-flex">
        <search-mode-select v-model="searchMode" />
        <el-input
          :placeholder="$t(searchMode === 'text' ? 'Search in text' : 'Search by author')"
          style="max-width: 300px"
          size="small"
          clearable
          class="mr-2"
          v-model="searchQuery"
        >
          <template v-slot:suffix>
            <span style="font-size: 20px; margin-top: 2px; float: right">
              <magnify-icon />
            </span>
          </template>
          <!-- <i v-slot:suffix class="el-input__icon el-icon-search"></i> -->
        </el-input>
        <!-- Sorting -->
        <div class="d-flex align-items-center ml-2" style="width: 300px">
          <span class="n-profile-label-title" style="padding-right: 5px">{{ $t("Sort by") }}:</span>
          <!-- DROPDOWN -->
          <el-dropdown trigger="click" @command="changeMultiSelect">
            <span class="n-profile-dropdown-value">
              {{ $t(currentSortByLabel) }}<i class="el-icon-arrow-down el-icon--right"></i>
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item v-for="item in sortingOptions" :key="item.value" :command="item.value">
                {{ $t(item.label) }}
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div class="pr-3 flex-grow-1" style="overflow: hidden">
      <div class="row g-0" style="height: 100%">
        <div class="col-lg-6 col-xl-4 p-0" style="height: 100%; z-index: 5">
          <div class="row" style="height: 100%">
            <perfect-scrollbar :options="{ suppressScrollX: true }" style="height: 100%; width: 100%">
              <div v-if="visibleNotes.length" style="height: 100%">
                <div v-for="(note, idx) in visibleNotes" :key="idx" class="pl-3">
                  <notes-item
                    :data="note"
                    :is-workshop="isWorkshop"
                    @select="handleSelectNote"
                    @delete="handleDeleteNoteItem"
                    @setActive="handleSetActiveNote"
                    @exportPdf="handleExportPdf"
                    @unread="handleMarkAsUnread"
                    :isFocused="isNoteFocused(note)"
                    :isPrivilegedUser="isPrivilegedUser"
                  />
                </div>
              </div>
              <div v-else class="empty-data">Keine Notizen gefunden</div>
            </perfect-scrollbar>
          </div>
        </div>
        <div class="col-lg-6 col-xl-8 d-flex flex-column" style="height: 100%">
          <perfect-scrollbar ref="editorScrollbar" :options="{ suppressScrollX: true }" style="height: 100%">
            <note-editor
              ref="noteEditor"
              :is-workshop="isWorkshop"
              :data="selectedNote"
              :project-id="projectId"
              @cancel="discardWithPrompt"
              @onNoteSubmit="handleNoteSubmit"
              @updateChildNotes="updateChildNotes"
              @removeNote="handleRemoveCurrentNote"
              :projectsList="projectsList"
              :loading="noteLoading"
              :isPrivilegedUser="isPrivilegedUser"
            />
          </perfect-scrollbar>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Message, Dropdown, DropdownMenu, Input, MessageBox } from "element-ui";
import Magnify from "vue-material-design-icons/Magnify.vue";
import SyncIcon from "vue-material-design-icons/Sync.vue";
import NotesItem from "./NotesItem.vue";
import NoteEditor from "./NoteEditor.vue";
import { unlink } from "src/utils/unlink";
import { debounce, first, get } from "lodash";
import SearchModeSelect from "./SearchModeSelect.vue";
import { PerfectScrollbar } from "vue2-perfect-scrollbar";
import { mapState } from "vuex";
import printJs from "print-js";

export default {
  name: "notes-list",
  props: {
    projectId: { type: String },
    isWorkshop: { type: Boolean },
    projectsMeta: { type: Array, default: () => [] },
  },
  components: {
    PerfectScrollbar,
    Magnify,
    SyncIcon,
    NoteEditor,
    NotesItem,
    SearchModeSelect,
    [MessageBox.name]: MessageBox,
    [Magnify.name]: Magnify,
    [Input.name]: Input,
    [Message.name]: Message,
    [Dropdown.name]: Dropdown,
    [DropdownMenu.name]: DropdownMenu,
  },
  data() {
    return {
      loading: false,
      noteLoading: false,
      activeTab: true,
      searchMode: "text",
      searchQuery: "",
      sortingOptions: [
        { value: "updatedAt", label: "Last created" },
        { value: "authorName", label: "Author" },
      ],
      currentSortBy: "updatedAt",
      allNotes: [],
      visibleNotes: [],
      selectedNote: null,
    };
  },
  created() {
    this.debounceFetchNotes = debounce(this.fetchNotes, 300);
  },
  async mounted() {
    await this.fetchNotes();
    this.handleFocusNote();
    this.$root.$on("refetchNotes", this.debounceFetchNotes);
  },
  beforeDestroy() {
    this.$root.$off("refetchNotes");
  },
  methods: {
    async fetchNotes() {
      try {
        this.loading = true;
        const params = { sortBy: this.currentSortBy, sortOrder: this.currentSortBy === "updatedAt" ? "desc" : "asc" };
        if (this.searchQuery && this.searchMode === "text") {
          params.search = this.searchQuery;
        }
        const response = await this.axios.get(`/api/notes/project/${this.projectId}`, { params });
        this.allNotes = response.data;
        this.filterData();
      } catch (error) {
        Message.error(error.message);
        throw error;
      } finally {
        this.loading = false;
      }
    },
    async handleFocusNote() {
      const noteId = this.$route.params.noteId;
      if (noteId) {
        const note = this.allNotes.find((item) => item._id === noteId);
        this.$router.push({ path: this.$route.path, params: { tab_pane: "notes" } });
        if (note) {
          await this.handleSelectNote(note);
        }
      } else if (this.visibleNotes.length) {
        // this.selectedNote = { ...first(this.visibleNotes) };
        await this.handleSelectNote(first(this.visibleNotes));
      }
    },
    setActiveTab(value) {
      this.activeTab = value;
      this.$nextTick(this.filterData);
    },
    filterData() {
      const allNotes = this.allNotes.filter((item) => item.active === this.activeTab);
      if (this.searchMode === "author" && this.searchQuery) {
        const searchQuery = this.searchQuery.toLowerCase();
        this.visibleNotes = allNotes.filter(
          (item) =>
            item.authorName.toLowerCase().indexOf(searchQuery) !== -1 ||
            (item.childNotes &&
              item.childNotes.some((child) => child.authorName.toLowerCase().indexOf(searchQuery) !== -1))
        );
      } else {
        this.visibleNotes = allNotes;
      }
      this.$nextTick(() => {
        // clear selected note if it is not in the resulting list
        if (
          this.selectedNote &&
          !this.selectedNote.isNew &&
          (!this.visibleNotes.length || !this.visibleNotes.some((item) => item._id === this.selectedNote._id))
        ) {
          this.selectedNote = null;
        }
      });
    },
    changeMultiSelect(newValue) {
      this.currentSortBy = newValue;
      this.$nextTick(() => {
        this.fetchNotes();
      });
    },
    async makeNewNote() {
      if (this.selectedNote && this.selectedNote.isOwner && !this.selectedNote.isNew) {
        const response = await this.axios.put(`/api/notes/${this.selectedNote._id}`, this.selectedNote);
        await this.handleNoteSubmit(response.data);
      }
      this.$nextTick(() => {
        this.selectedNote = {
          isNew: true,
          isOwner: true,
          projectId: this.projectId,
          title: "",
          body: "",
          pictures: [],
        };
      });
    },
    discardEdit() {
      this.handleFocusNote();
    },
    discardWithPrompt() {
      let shouldPrompt = false;
      if (this.selectedNote && this.selectedNote.isNew) {
        if (
          this.selectedNote.title.length ||
          this.selectedNote.body.length ||
          (this.selectedNote.pictures && this.selectedNote.pictures.length)
        ) {
          shouldPrompt = true;
        }
      } else {
        const note = this.allNotes.find((item) => item._id === this.selectedNote._id);
        if (
          this.selectedNote.title !== note.title ||
          this.selectedNote.body !== note.body ||
          (this.selectedNote.pictures && note.pictures && this.selectedNote.pictures.length !== note.pictures.length)
        ) {
          shouldPrompt = true;
        }
      }
      if (shouldPrompt) {
        MessageBox.confirm("Wollen Sie geänderte Daten vor verlassen speichern?", "ungesicherte Daten", {
          confirmButtonText: "Ja",
          cancelButtonText: "Nein",
          type: "warning",
          distinguishCancelAndClose: true,
          confirmButtonClass: "el-button--success",
        })
          .then(() => {
            this.$refs.noteEditor.submit();
          })
          .catch(() => {
            this.discardEdit();
          });
      } else {
        this.discardEdit();
      }
    },
    handleRemoveCurrentNote() {
      this.removeNote(this.selectedNote._id);
    },
    handleDeleteNoteItem(noteId) {
      this.removeNote(noteId);
    },
    async handleSetActiveNote(payload) {
      const response = await this.axios.put(`/api/notes/${payload.id}`, { active: payload.active });
      const noteIdx = this.allNotes.findIndex((item) => item._id === payload.id);
      console.log("noteIdx", noteIdx);
      console.log("response.data", response.data);
      if (noteIdx !== -1) {
        this.allNotes.splice(noteIdx, 1, response.data);
      }
      this.filterData();
    },
    removeNote(noteId) {
      this.$confirmDelete().then(async () => {
        try {
          await this.axios.delete(`/api/notes/${noteId}`);
          this.allNotes = this.allNotes.filter((item) => item._id !== noteId);
          this.filterData();
          if (this.selectedNote && noteId === this.selectedNote._id) {
            this.discardEdit();
          }
        } catch (error) {
          Message.error(error.message);
          throw error;
        }
      });
    },
    async handleNoteSubmit(newNote) {
      if (this.selectedNote.isNew) {
        this.allNotes.push(newNote);
      } else {
        const idx = this.allNotes.findIndex((item) => item._id === newNote._id);
        if (idx !== -1) {
          this.$set(this.allNotes, idx, newNote);
        }
      }
      this.filterData();
      if (this.selectedNote.isNew) {
        await this.handleFocusNote();
      }
    },
    updateChildNotes(newChildNotes) {
      const noteIdx = this.allNotes.findIndex((item) => item._id === this.selectedNote._id);
      this.$set(this.allNotes[noteIdx], "childNotes", newChildNotes);
      this.selectedNote.childNotes = [].concat(newChildNotes);
    },
    async handleSelectNote(note) {
      try {
        // scroll top when note is selected. can break with library updates
        this.$refs.editorScrollbar.ps.element.scrollTop = 0;
        this.noteLoading = true;
        const allNotesIdx = this.allNotes.findIndex((item) => item._id === note._id);
        const response = await this.axios.get(`/api/notes/project/${this.projectId}/${note._id}`);
        this.selectedNote = { ...response.data, projectId: this.projectId };
        this.allNotes[allNotesIdx] = unlink(response.data);
        this.filterData();
      } catch (error) {
        throw error;
      } finally {
        this.noteLoading = false;
      }
    },
    isNoteFocused(note) {
      if (this.selectedNote && this.selectedNote._id === note._id) {
        return true;
      } else {
        return false;
      }
    },
    async handleExportPdf(noteId) {
      const response = await this.axios.request({
        method: "GET",
        url: `/api/notes/project/${this.projectId}/pdf/${noteId}`,
        responseType: "blob",
      });
      const blob = response.data;
      let filename;
      const contentDisposition = response.headers["Content-Disposition"];
      if (contentDisposition) {
        filename = contentDisposition.match('filename="([^"]*)"')[1];
      } else {
        filename = `note.pdf`;
      }
      const file = new File([blob], filename, { type: "application/octet-stream" });
      Message({
        message: "PDF erstellt",
        type: "success",
      });
      const objectUrl = window.URL.createObjectURL(file);
      printJs({
        printable: objectUrl,
        type: "pdf",
        showModal: true,
        modalMessage: "Vorbereitung zum Drucken",
        onError: (err) => {
          throw err;
        },
        onPrintDialogClose: () => {
          URL.revokeObjectURL(objectUrl);
        },
      });
    },
    async handleMarkAsUnread(noteId) {
      await this.axios.put(`/api/notifications/unread/${noteId}`);
      const noteIdx = this.allNotes.findIndex((i) => i._id === noteId);
      this.$set(this.allNotes, noteIdx, { ...this.allNotes[noteIdx], unread: true });
      this.filterData();
    },
    async reFetch() {
      try {
        await this.fetchNotes();
        if (this.selectedNote) {
          await this.handleSelectNote(this.selectedNote);
        }
      } catch (error) {
        throw error;
      }
    },
  },
  computed: {
    ...mapState("account", { accessRights: "accessRights" }),
    projectsList() {
      let list;
      if (this.isWorkshop) {
        list = this.projectsMeta.filter((item) => item.id !== this.projectId);
      } else {
        list = this.projectsMeta.filter((item) => item.isWorkshop);
      }
      return list.map((item) => ({
        label: item.bvName,
        value: item.id,
      }));
    },
    activeTitle() {
      return this.activeTab ? this.$t("Active") : this.$t("Inactive");
    },
    currentSortByLabel() {
      return this.sortingOptions.find((item) => item.value === this.currentSortBy).label;
    },
    activeAmount() {
      return this.allNotes.reduce((num, note) => {
        if (note.active) {
          num++;
        }
        return num;
      }, 0);
    },
    inactiveAmount() {
      return this.allNotes.reduce((num, note) => {
        if (!note.active) {
          num++;
        }
        return num;
      }, 0);
    },
    isPrivilegedUser() {
      return get(this.accessRights, "project_notes.isPrivileged", false);
    },
  },
  watch: {
    projectId(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.fetchNotes();
      }
    },
    searchMode(newVal) {
      if (this.searchQuery) {
        this.debounceFetchNotes();
      }
    },
    searchQuery(newVal) {
      this.debounceFetchNotes();
    },
  },
};
</script>

<style></style>
