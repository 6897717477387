<template>
  <div>
    <el-upload
      v-bind="$attrs"
      :action="'foo'"
      class="drawer-upload"
      drag
      multiple
      :disabled="disabled"
      :file-list="fileList"
      :show-file-list="false"
      :http-request="handleHttpRequest"
      :on-success="handleSuccess"
      :on-error="handleUploadError"
      :class="[{ 'upload-disabled': disabled }, { 'picture-list': pictureList }]"
    >
      <template v-if="pictureList" v-slot:file="{ file }">
        <div class="upload-file-item picture-item">
          <pending-icon
            v-if="progressStore[file.name] || file.tempId"
            :status="file.status"
            :progress="progressStore[file.name] && progressStore[file.name].progress"
          />
          <template v-else>
            <lazy-image v-if="isImage(file.name)" :src="getImageSrc(file)" :alt="file.name" />
            <div class="upload-item-placeholder" v-else>
              <div class="upload-file-item__icon">
                <file-type-icon :filename="file.name" />
              </div>
            </div>
          </template>
          <div class="upload-file-item__name mt-1" :title="file.name">
            {{ file.name }}
          </div>
          <div class="upload-file-actions" style="flex-shrink: 0; margin-left: 10px">
            <div class="upload-file-item__action" v-if="file.url" @click="viewDocument(file)">
              <i class="el-icon-view"></i>
            </div>
            <div class="upload-file-item__action" v-if="file.url && allowDownload" @click="downloadFile(file)">
              <i class="el-icon-download"></i>
            </div>
            <div class="upload-file-item__action" v-if="!disabled" @click="removeFile(file)">
              <i class="el-icon-delete"></i>
            </div>
          </div>
        </div>
      </template>
      <template v-else v-slot:file="{ file }">
        <div class="upload-file-item">
          <lazy-image v-if="isImage(file.name)" :src="getImageSrc(file)" :alt="file.name" />
          <span v-else>
            <div class="upload-file-item__icon"><i class="el-icon-paperclip"></i></div>
            <div class="upload-file-item__name">
              {{ file.name }}
            </div>
            <span>{{ progressStore[file.uid] | formatProgress }}</span>
          </span>
          <span style="flex-shrink: 0; margin-left: 10px">
            <div class="upload-file-item__action" v-if="file.url" @click="viewDocument(file)">
              <i class="el-icon-view"></i>
            </div>
            <div class="upload-file-item__action" v-if="allowDownload" @click="downloadFile(file)">
              <i class="el-icon-download"></i>
            </div>
            <div class="upload-file-item__action" v-if="!disabled" @click="removeFile(file)">
              <i class="el-icon-delete"></i>
            </div>
          </span>
        </div>
      </template>
      <div class="upload-icon"><file-upload-outline-icon /></div>
      <div class="el-upload__text" v-show="!disabled">
        <b>{{ $t("src.components.project.invoices.drawerupload.whlenSieEineDatei") }}</b
        >&nbsp;
        <em style="color: #46a19c">{{ $t("src.components.project.invoices.drawerupload.vonIhremComputerAus") }}</em>
      </div>
    </el-upload>
    <div class="drawer-upload picture-list">
      <div class="upload-file-item picture-item" v-for="file in fileList" :key="file.uid">
        <pending-icon v-if="progressStore[file.uid] || file.tempId" :status="file.status" :progress="file.progress" />
        <template v-else>
          <lazy-image v-if="isImage(file.name)" :src="getImageSrc(file)" :alt="file.name" />
          <div class="upload-item-placeholder" v-else>
            <div class="upload-file-item__icon">
              <file-type-icon :filename="file.name" />
            </div>
          </div>
        </template>
        <div class="upload-file-item__name mt-1" :title="file.name">
          {{ file.name }}
        </div>
        <div class="upload-file-actions" style="flex-shrink: 0; margin-left: 10px">
          <div class="upload-file-item__action" v-if="file.url" @click="viewDocument(file)">
            <i class="el-icon-view"></i>
          </div>
          <div class="upload-file-item__action" v-if="file.url && allowDownload" @click="downloadFile(file)">
            <i class="el-icon-download"></i>
          </div>
          <div class="upload-file-item__action" v-if="!disabled" @click="removeFile(file)">
            <i class="el-icon-delete"></i>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Upload, MessageBox } from "element-ui";
import { mapActions } from "vuex";
import FileUploadOutlineIcon from "vue-material-design-icons/FileUploadOutline";
import LazyImage from "../../UIComponents/Image/LazyImage.vue";
import { last } from "lodash";
import FileTypeIcon from "./FileTypeIcon.vue";
import PendingIcon from "./PendingIcon.vue";

export default {
  name: "drawer-upload",
  components: {
    MessageBox,
    LazyImage,
    FileTypeIcon,
    PendingIcon,
    [FileUploadOutlineIcon.name]: FileUploadOutlineIcon,
    [Upload.name]: Upload,
  },
  props: {
    disabled: { type: Boolean },
    onSuccess: { type: Function },
    actionURI: { type: String },
    location: { type: String },
    fileList: { type: Array, default: () => [] },
    allowDownload: { type: Boolean, default: true },
    pictureList: { type: Boolean },
  },
  data() {
    return {
      progressStore: {},
      errorStore: {},
    };
  },
  methods: {
    ...mapActions("pdfViewer", { openPdf: "open" }),
    handleUploadError(error) {
      if (error.status === 413) {
        this.$message.error(
          "Die Datei, die sie hochladen wollten, ist größer als 30 MB. Bitte reduzieren Sie die Dateigröße oder Teilen sie den Inhalt in mehrere Dateien auf. Upload abgebrochen."
        );
      }
      throw error;
    },
    async beforeRemoveFile(file) {
      return await MessageBox.confirm(file.name + " löschen?", "Achtung", {
        confirmButtonText: "Ja",
        cancelButtonText: "Nein",
        type: "warning",
      }).catch((_) => {
        throw "ABORTED";
      });
    },
    async removeFile(file) {
      try {
        if (this.errorStore[file.uid]) {
          this.errorStore[file.uid] = undefined;
        } else {
          await this.beforeRemoveFile(file);
          await this.$attrs["on-remove"](file);
        }
      } catch (error) {
        console.log("error", error);
        if (error !== "ABORTED") {
          throw error;
        }
      }
    },
    viewDocument(file) {
      if (file.url.toLowerCase().endsWith(".pdf")) {
        const completeUrl = this.axios.defaults.baseURL + file.url;
        this.openPdf({ url: completeUrl, fileName: file.name });
      } else {
        window.open(`${file.url}?view=true`, "_blank");
      }
    },
    downloadFile(file) {
      if (file.response && file.response[0]) {
        window.open(file.response[0].url, "_blank");
      } else if (file.url) {
        window.open(file.url, "_blank");
      } else {
        console.warn("Could not parse file. File object below.");
        console.log(JSON.stringify(file, null, 2));
      }
    },
    handleProgress(evt, file) {
      const record = this.fileList.find((item) => item.name === file.name);
      if (record) {
        record.progress = Math.ceil((evt.loaded / evt.total) * 100);
      }
      this.progressStore[file.name] = Math.ceil((evt.loaded / evt.total) * 100);
    },
    async handleHttpRequest({ data, file, headers, action, onError, onProgress, onSuccess }) {
      try {
        const tempResponse = await this.axios.post("/api/fileupload/start", {
          metadata: data.metadata,
          filename: file.name,
          location: this.location,
        });
        const formData = new FormData();
        formData.append("file", file);
        formData.append("metadata", data.metadata);
        formData.append("tempId", tempResponse.data.tempId);
        file.progress = 0;
        this.progressStore[file.name] = file;
        const record = { ...tempResponse.data, progress: 0, uid: new Date().getTime() };
        this.fileList.push(record);
        // onSuccess(tempResponse.data, file);
        const uploadResponse = await this.axios.post("/api/fileupload/complete", formData, {
          onUploadProgress: (e) => this.handleProgress(e, file),
        });
        record.status = uploadResponse.data.status;
        onSuccess(uploadResponse.data, file);
      } catch (error) {
        onError(error);
        throw error;
      } finally {
        this.progressStore[file.name] = undefined;
      }
    },
    handleSuccess(response, file, fileList) {
      console.log("file, fileList", file, fileList);
      this.onSuccess(response, file, fileList);
    },
    handleError(err, file) {
      this.errorStore[file.uid] = err.message;
    },
    isImage(filename) {
      const extension = filename && last(filename.split(".")).toLowerCase();
      return ["png", "jpeg", "jpg", "gif"].includes(extension);
    },
    getImageSrc(file) {
      return file.url;
    },
  },
  computed: {
    localFileList() {
      return Object.values(this.progressStore).filter(Boolean).concat(this.fileList);
    },
  },
  filters: {
    formatProgress(percent) {
      if (file) {
        return `(...${Math.floor(percent)}%)`;
      } else {
        return "";
      }
    },
  },
};
</script>

<style lang="scss">
.drawer-upload .upload-icon {
  font-size: 24px;
  margin-right: 20px;
  display: inline-block;
  color: #797979;
}
.el-upload-dragger .el-icon-upload2 {
  font-size: 40px;
  color: #c0c4cc;
  margin: 40px 0 16px;
  line-height: 50px;
}
.drawer-upload {
  margin-top: 15px;
  display: flex;
  flex-direction: column-reverse;
  &.picture-list {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
.drawer-upload .el-upload,
.drawer-upload .drawer-upload .el-upload-dragger {
  width: 100%;
}
.drawer-upload .el-upload-dragger {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100px;
  width: 100%;
}
.drawer-upload .el-upload-list {
  margin-bottom: 8px;
}
.drawer-upload.picture-list .el-upload-list {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
}
.upload-file-item {
  margin-bottom: 10px;
  padding: 5px 10px;
  background: #f8f8f8;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 1px solid #a6aebb; */
}
.upload-file-item__name {
  display: inline-block;
  margin-left: 10px;
  position: relative;
  top: -2px;
}
.upload-file-item__icon,
.upload-file-item__action {
  display: inline-block;
  color: #9b9b9b;
  font-size: 18px;
}
.upload-file-item__icon:hover .upload-file-item__action:hover .upload-file-item__name {
  color: #797979;
}
.upload-file-item__action {
  cursor: pointer;
}
.upload-file-item__action:not(:last-child) {
  margin-right: 5px;
}
.upload-disabled .el-upload {
  display: none;
}

.picture-list .el-upload-list__item {
  margin-right: 10px;
  margin-bottom: 10px;
  margin-top: 10px;
  &:hover {
    background: none;
  }
}

.upload-file-item.picture-item {
  width: 150px;
  height: 150px;
  flex-wrap: wrap;
  margin-right: 10px;
  .el-image {
    height: 90px;
  }
  .upload-file-item__name {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
}
.upload-item-placeholder {
  width: 100%;
  text-align: center;
  .upload-file-item__icon {
    font-size: 45px;
  }
}
.upload-file-actions {
  text-align: right;
  width: 100%;
  padding-right: 10px;
}
</style>
